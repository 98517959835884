import React from 'react';
import { Form, Button } from 'semantic-ui-react'
import { Header, Segment } from 'semantic-ui-react'
import ReportsDropdown from './ReportsDropdown';
import ReportsDatepicker from './ReportsDatepicker';
import { createReport } from '../../actions/reports';

class FormBuilder extends React.Component {
    
    state = {
        fields: {},
        count: 0,
        loading: false,
        error: null
    }

    handleChange = (name, value) => {
        this.setState({ fields: {...this.state.fields,...{ [name]: value } } }, () => {
           
            let count = 0;

            for (const val of Object.entries(this.state.fields)) {
                if (String(val).length > 0 && val !== null) {
                    count++;
                }
            }

            this.setState({count});
            this.props.onChange(this.props.report, this.prepareParams())
        });
    }

    prepareParams = () => {
        return this.props.report.data.map((item) => {
            return this.state.fields[item.text] ? this.state.fields[item.text] : '#';
        });
    }

    componentDidUpdate = (nextProps) => {
        if (this.props.report.id !== nextProps.report.id) {
            this.setDefaultValues();
        }
    }

    componentDidMount = () => {
        this.setDefaultValues();
    }

    setDefaultValues = () => {
        const fields = {}
            
        this.props.report.data.forEach((item, index) => {
            switch (item.type) {
                case 1:
                    fields[item.text] = item.defaultList ? item.defaultList.id : null;
                    break;
                default:
                    fields[item.text] = item.defaultDate !== "0001-01-01T00:00:00" ? item.defaultDate : null;
                    break;
            }
        });

        this.setState({fields, count: 0})
    }

    handleProcessReport = () => {
        this.props.onError(null, () => {
            this.setState({ loading: true }, async () => {
                await createReport(this.props.report.name, this.props.report.id, this.state.fields)
                    .then(() => {
                        this.setState({loading: false})
                    })
                    .catch((error) => {
                        let decoder = new TextDecoder("utf-8")
                        this.props.onError(decoder.decode(error.response.data));
                        this.setState({loading: false })
                    })
            });
        });
    }

    render() {
        return (
            <Segment.Group>
                <Segment>
                    <Header>{this.props.report.name}</Header>
                </Segment>
                <Segment>
                    <Form className="formbuilder">
                        { this.props.report.data.map((item, index) => {
                            switch (item.type) {
                                case 1:
                                    return <ReportsDropdown index={index} text={item.text} value={this.state.fields[item.text]} options={item.data} onChange={this.handleChange} />
                                
                                default:
                                    return <ReportsDatepicker index={index} text={item.text} value={this.state.fields[item.text]} onChange={this.handleChange} />

                            }
                        }) }
                        
                        <Button loading={this.state.loading} color='blue' className="btn process" disabled={ this.state.count < this.props.report.data.length || this.state.loading} onClick={this.handleProcessReport}>Process Report</Button>
                    </Form>
                </Segment>
            </Segment.Group>
        )
    }
}

export default FormBuilder