import jsonPlaceholder from '../api/jsonPlaceholder';

export const fetchSearchTerms = async (operatorId) => {
	const manufacturers = await jsonPlaceholder.get(`Organisation/manufacturers/${operatorId}`);
	const machinetypes = await jsonPlaceholder.get(`Organisation/categories/${operatorId}`);
	const gametitles = await jsonPlaceholder.get(`Organisation/gametitles/${operatorId}`);
	const regions = await jsonPlaceholder.get(`Security/operatorregions/${operatorId}`);

	const filters = {
		manufacturer: manufacturers.data,
		machinetypes: machinetypes.data,
		game: gametitles.data,
		regions: regions.data
	}
	return filters;
};

export const fetchOperators = () => async dispatch => {

	const operators = await jsonPlaceholder.get(`Security/operators`);
    
	const data = operators.data.map(operator => {
		return { key: operator.id, value: operator.id, text: operator.name}
	})

	await dispatch({type: 'FETCH_OPERATORS', payload: data});

}

export const fetchOperatorOptions = async (operatorId) => {
	const response = await jsonPlaceholder.get(`Organisation/operator/options/${operatorId}`);
    return response.data
}

export const prepareFilter = (selected, allOptions) => {

    if (selected.length < 1) {
      return '#';
    }

	const filterSelected = selected.filter(x => x.id !== -1)
	const filterAll = allOptions.filter(x => x.id !== -1)

    if (filterSelected.length < filterAll.length) {
      const items = filterSelected.map(x => x.id)
      return items.join(';');
    }

    return "-1";
  }


export const fetchOrganisations = async (filters, FromAdmin = false) => {
 
	const { filterSelected, filterData } = filters;

    const params = {
        OperatorId: filterSelected.operator,
        OperatorRegions: prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        Manufacturers: prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        GameNames: prepareFilter(filterSelected.game, filterData.game),
        Categories: prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
        Search: filterSelected.searchText || "#",
        FromAdmin,
    }

	const response = await jsonPlaceholder.put(`Organisation/tree2`, params)
	// format
	var organisations = [];
	var subCompanies = [];
	var sites = [];
	response.data.forEach(item => {
		switch(item.type) {
			case "CO":
				organisations.push(item);
				break;
			case "SUB":
				subCompanies.push(item);
				break;
			case "SI":
				sites.push(item);
				break;
			default:
				break;
			}
	});

	subCompanies.forEach(item => {
		item.sites = sites.filter(site => site.parent === item.child);
	});

	organisations.forEach(item => {
		item.subCompanies = subCompanies.filter(company => company.parent === item.child);
	});

	const activeOrgs = organisations.map(x => x.id);
	let activeSubOrgs = []

	organisations.forEach((company) => {
	  company.subCompanies.forEach((subCompany) => {
		activeSubOrgs.push(subCompany.id) 
	  })
	})

	return {activeOrgs, activeSubOrgs, organisations}
};

export const fetchOrganisationByPosition = async (posId) => {
	const response = await jsonPlaceholder.get(`Organisation/Position2/${posId}`);
	return response.data
};

export const mergeParams = (filterSelected, searchParams) => {
	const selected = {
		companyId: filterSelected.company.id,
		subCompanyId: filterSelected.subcompany.id,
		siteId: filterSelected.site.id,
		operator: filterSelected.operator,
		manufacturer: filterSelected.manufacturer,
		machinetypes: filterSelected.machinetypes,
		gamenames: filterSelected.game,
	}
	
	return { ...searchParams, ...selected }
}

export const checkForUpdates = (prevProps, newProps) => {

	if (!newProps.hasSelection) {
		return false;
	}

	let updated = false;

    ['company', 'subcompany', 'site', 'operator'].forEach(item => {
      if (prevProps.filterSelected[item] !== newProps.filterSelected[item]) {
        updated = true;
      }
	})
	
	return updated;
}