import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Grid, Icon } from 'semantic-ui-react';
import { clearInstallTransactions, fetchInstallTransactionsExport, fetchInstallTransactionsWithSummary, fetchTransaction, fetchTransactionsFilters, fetchTransBlankSummary } from '../../actions';
import { defaultFromDate, UIDateFormat, UITimeFormat } from '../../config/dates';
import TransactionDetailsModal from '../modals/TransactionDetailsModal';
import DataGridRequest from '../shared/DataGridRequest';
import DownloadButton from '../shared/DownloadButton';
import Option from '../systemAdmin/Option';

class Transactions extends React.Component {
  state = {
    fromDate: moment(defaultFromDate),
    toDate: moment(),
    currentDate: true,
    includeEvents: false,
    transaction: [],
    transactions: [],
    modalOpen: false,
    modalTitle: "",
    loading: false,
    accordionOpen: false,
    summaryOptions: {
      grid: {
        headerDef: [],
        rowDef: [],
        data: []
      }
    },
    showSummary: false
  };

  handleChange = (name, value) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  handleDateChange = (event, {name, value}) => {
    const date = moment(value, UIDateFormat);
    date.set({
      hour: this.state[name].get('hour'),
      minute: this.state[name].get('minute'),
      second: this.state[name].get('second'),
    })
    
    this.setState({ [name]: date })
  }

  handleTimeChange = (event, {name, value}) => {
    const date = this.state[name]
    const time = moment(`${date.format(UIDateFormat)} ${value}`, `${UIDateFormat} HH:mm:ss`);
    this.setState({ [name]: time })
  }

  handleCheckboxChange = (event, {name}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState(state => ({ [name]: !state[name] }));
    }
    if (name === 'includeEvents'){
      this.updateTransactions();
    }
  }

  updateTransactions = () => {
    const toDate = this.state.currentDate ? moment() : this.state.toDate;
    this.setState({ toDate }, () => this.handleRefresh());
  }

  handleRefresh = () => {
    if (!this.props.installation) {
      this.props.clearInstallTransactions();
    } else {
      this.setState({loading: true }, () => {
        this.props.fetchInstallTransactionsWithSummary(
          this.props.installation,
          this.state.fromDate, 
          this.state.toDate,
          this.state.includeEvents,
          this.state.details
        ).then(() => {
          this.setState({loading: false, summaryOptions: { grid: this.props.transactions.summary } });
        })
        .catch(() => {
          this.props.clearInstallTransactions().then(() => this.setState({loading: false}));
        });
      });
    }
  }


  handleClose = (refresh) => {
    this.setState({ modalOpen: false },()=>{
      if(refresh===true){
        this.updateTransactions();
      }
    })
  }

  handleTransactionSelection = term => {
    if(term.optionShowDetails){
      this.setState({loading: true }, () => {
        this.props.fetchTransaction(term.transactionId).then(() => {
          this.setState({
            loading: false,
            modalOpen: true,
            modalTitle: `Transaction Details: ${this.props.transaction.transactionRef}`
          });
        });
      });
    }
  }

  loadExport = () => {
    if (this.state.currentDate) {
      this.setState({toDate: moment() }, () => {
        this.getExport()
      })
    } else {
      this.getExport()
    }
  }

  getExport = () => {
    fetchInstallTransactionsExport(
      this.props.installation,
      this.state.fromDate, 
      this.state.toDate,
      this.state.includeEvents,
      this.state.details
    )
  }

  searchSegment = () => {
    return (
      
      <form onSubmit={(e)=>{e.preventDefault()}} className="ui form">

          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                  <Button color='blue' icon size='mini' disabled={!this.props.filters.filterSelected.operator} onClick={() => this.setState( {showSummary: !this.state.showSummary })}>
                    <Icon name="pin" rotated={ !this.state.showSummary ? "clockwise" : null } />
                  </Button>

                    <span className="mr-1"><DownloadButton disabled={!this.props.installation} onClick={this.loadExport} /></span>

                    From:
                    <DateInput
                      className="ui input ml-1"
                      style={{width: '9em'}}
                      name="fromDate"
                      placeholder="Date"
                      value={this.state.fromDate.format(UIDateFormat)}
                      iconPosition="left"
                      onChange={this.handleDateChange}
                      animation="fade"
                      popupPosition="bottom center"
                      closable
                      autoComplete="off"
                    />
                    <TimeInput
                      className="ui input"
                      style={{width: '6em'}}
                      name="fromDate"
                      placeholder="Time"
                      value={this.state.fromDate.format(UITimeFormat)}
                      iconPosition="left"
                      onChange={this.handleTimeChange}
                      animation="fade"
                      popupPosition="bottom center"
                      closable
                      autoComplete="off"
                    />
                  
                  { !this.state.currentDate && <span name="toDateTime">
                    <label className="ml-1 ui">To:</label>
                    <DateInput
                      className="ui input ml-1"
                      style={{width: '9em'}}
                      name="toDate"
                      placeholder="Date"
                      value={this.state.toDate.format(UIDateFormat)}
                      iconPosition="left"
                      onChange={this.handleDateChange}
                      animation="fade"
                      popupPosition="bottom center"
                      closable
                      autoComplete="off"
                    />
                    <TimeInput
                      className="ui input"
                      style={{width: '6em'}}
                      name="toDate"
                      placeholder="Time"
                      value={this.state.toDate.format(UITimeFormat)}
                      iconPosition="left"
                      onChange={this.handleTimeChange}
                      animation="fade"
                      popupPosition="bottom center"
                      closable
                      autoComplete="off"
                    />
                  </span> }
                    <Checkbox className="ml-1" name="currentDate" label="Current Date/Time" checked={this.state.currentDate} onChange={() => this.setState( { currentDate: !this.state.currentDate } )} />
                    <Checkbox name="includeEvents" checked={this.state.includeEvents} onChange={this.handleCheckboxChange} label="Show Events" className="ml-1 mr-1" />
                  { this.props.installation && <Button disabled={this.state.loading} onClick={this.updateTransactions} positive>Refresh</Button> }
              </Grid.Column>
            </Grid.Row>
          </Grid>        
        </form>
      
    );
  }

  componentDidUpdate(prevProps){
    // Check for a change in the transaction list
    if (this.props.installation !== prevProps.installation) this.updateTransactions();
    if (this.props.filters.filterSelected.operator !== prevProps.filters.filterSelected.operator) {
      fetchTransBlankSummary(this.props.filters.filterSelected.operator).then(response => {
        this.setState({summaryOptions: { grid: response }}, () => this.setDetails())
      })
    }
    return true
  }

  /**
   * Find all controls in the grid and populate the details object with their codes and values
   */
  setDetails = () => {
    const details = {};
    const data = this.state.summaryOptions.grid.data;

    data.forEach((row, rowIndex) => {
      row.cellData.forEach((cell, cellIndex) => {
        if (cell.type === "control") {
          details[cell.data.code] = Option.getValue(cell.data);
        }
      })
    })

    this.setState({ details })
  }


  handleOptionChange = (option, value) => {
    const data = [...this.state.summaryOptions.grid.data];

    data.forEach((row, rowIndex) => {
      row.cellData.forEach((cell, cellIndex) => {
        if (cell.data.code === option.code) {
          data[rowIndex].cellData[cellIndex].data = option
        }
      })
    })
    
    const grid = {...this.state.summaryOptions.grid, data }
    this.setState({ summaryOptions: {...this.state.summaryOptions, grid} }, () => this.setDetails())
  }

  render() {
    var headings, rowDef, data = [];
    if(this.props.transactions.grid){
      headings = this.props.transactions.grid.headerDef;
      rowDef = this.props.transactions.grid.rowDef;
      data = this.props.transactions.grid.data;
    }



    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row>
          <Grid.Column>
            {this.searchSegment()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch">
        { (this.props.filters.filterSelected.operator && this.state.showSummary) && 
            <Grid.Column computer="6" largeScreen="4" widescreen="3" className="gs-flex-container-vertical">
              <DataGridRequest
                loading={this.state.loading}
                headings={this.state.summaryOptions.grid.headerDef}
                data={this.state.summaryOptions.grid.data}
                rowDef={this.state.summaryOptions.grid.rowDef}
                hideOverflow
                disableSort={true}
                disableActiveRow={true}
                onOptionChange={this.handleOptionChange}
              />
            </Grid.Column>
          }
          <Grid.Column computer={ this.state.showSummary ? "10" : "16" } largeScreen={ this.state.showSummary ? "12" : "16" } widescreen={ this.state.showSummary ? "13" : "16" } className="gs-flex-container-vertical">
            <DataGridRequest
              loading={this.state.loading}
              headings={headings}
              data={data}
              rowDef={rowDef}
              onRowClick={this.handleTransactionSelection}
              fullHeight
            />

            <TransactionDetailsModal modalOpen={this.state.modalOpen} handleClose={this.handleClose} modalTitle={this.state.modalTitle} transaction={this.props.transaction} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
	return {
    transaction: state.transaction,
    transactions: state.transactions,
    installation: state.activeInstallation.installationId,
    filters: state.installationsFilters,
  };
};

export default connect(mapStateToProps, {
  fetchTransaction,
  fetchInstallTransactionsWithSummary,
  fetchTransactionsFilters,
  clearInstallTransactions,
})(Transactions);

