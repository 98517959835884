
export const getBackgroundColour = (item) => {
    return getColour(item, "backColour", 'transparent');
}

export const getTextColour = (item) => {
    return getColour(item, "foreColour", 'inherit');
}

export const getColour = (item, key, defaultValue) => {
    return item[key] && !item[key].isEmpty ? `rgb(${item[key].r},${item[key].g},${item[key].b},${item[key].a})` : defaultValue;
}