const initialState = {  visible: false, header: '', message: '', onConfirm: () => {} }

export default (state = initialState, action) => {
	switch (action.type) {
		case 'PASSWORD_MODAL':
			return action.payload;
		default:
			return state;
	}
};
