export const mergeWithState = (data, state) => {
	let newState = { }

	for (const [key, value] of Object.entries(state)) {
		if (data[key] != null) {
			if (typeof value === "object" && typeof data[key] === "object" && !Array.isArray(data[key])) {
				newState[key] = {...value, ...data[key]}
			 } else {
				newState[key] = data[key];
			 }
		} else {
			newState[key] = value;
		}
	}

	return newState;
}

export const fetchInstallationsFilters = (data) => async (dispatch, getState) => {
	const state = getState().installationsFilters;
	dispatch({type: 'FETCH_INSTALLATIONS_FILTERS', payload: mergeWithState(data, state)});
};

export const fetchTransactionsFilters = (data) => async (dispatch, getState) => {
	const state = getState().transactionsFilters;
	dispatch({type: 'FETCH_TRANSACTIONS_FILTERS', payload: mergeWithState(data, state)});
};

export const fetchCollectionsFilters = (data) => async (dispatch, getState) => {
	const state = getState().collectionsFilters;
	dispatch({type: 'FETCH_COLLECTIONS_FILTERS', payload: mergeWithState(data, state)});
};

export const fetchSessionsFilters = (data) => async (dispatch, getState) => {
	const state = getState().sessionsFilters;
	dispatch({type: 'FETCH_SESSIONS_FILTERS', payload: mergeWithState(data, state)});
};
export const fetchCommsViewerFilters = (data) => async (dispatch, getState) => {
	const state = getState().commsViewerFilters;
	dispatch({type: 'FETCH_COMMS_VIEWER_FILTERS', payload: mergeWithState(data, state)});
};

export const fetchSettingsFilters = (data) => async (dispatch, getState) => {
	const state = getState().settingsFilters;
	dispatch({type: 'FETCH_SETTINGS_FILTERS', payload: mergeWithState(data, state)});
};
