import React from 'react';
import { Button, Form, Grid, Header, Input, Modal } from 'semantic-ui-react';
import { getOpeningHoursNew, updateOpeningHours } from '../../actions/settings';
import DataGridRequest from '../shared/DataGridRequest';

class OpeningHoursModal extends React.Component {

  state = {
    loading: false,
    error: false,
    hasChanges: false,
    selectedProfile: { profileName: "", grid: { data: [], headerDef: [] } },
  }


  componentDidUpdate = (prevProps) => {
    if(this.props.selectedOperator !== prevProps.selectedOperator){
      getOpeningHoursNew(this.props.selectedOperator).then(selectedProfile => {
        this.setState({selectedProfile})
      })
    } 
  }

  handleClose = () => {
      this.props.handleClose()
  }

  handleCellClick = (data) => {
    data.actualValue = data.actualValue === "0" ? "1" : "0";
    data.backColour = data.actualValue === "0" ? this.state.selectedProfile.closeColour : this.state.selectedProfile.openColour; 
    
    this.setState({ hasChanges: true, selectedProfile: {...this.state.selectedProfile}})
  }

  updateProfileName = (event, {value}) => {
    const selectedProfile = {...this.state.selectedProfile, profileName: value}
    this.setState({selectedProfile, hasChanges: true})
  }

  handleSave = () => {
    this.setState( { loading: true }, () => {
      const items = [];
      this.state.selectedProfile.grid.data.forEach(row => {
        row.cellData.forEach((cell, index) => {
          if (index > 0) items.push(cell.actualValue);
        })
      })

      updateOpeningHours(this.props.selectedOperator, this.state.selectedProfile.profileId, this.state.selectedProfile.profileName, items.join(";")).then(x => {
        this.setState({ loading: false, hasChanges: false}, () => this.props.onNewProfile())
      }).catch(() => this.setState({ loading: false }))
    })
  }

  render() {
    return (
        <Modal
          open={this.props.modalOpen}
          onClose={this.handleClose}
          closeIcon
          size="fullscreen"
        >
          <Header>New Profile</Header>
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Field inline>
                      <label>Profile Name:</label>
                      <Input value={this.state.selectedProfile.profileName} onChange={this.updateProfileName} />
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <DataGridRequest
                    activeRow={null}
                    headings={this.state.selectedProfile.grid.headerDef}
                    data={this.state.selectedProfile.grid.data}
                    rowDef={this.state.selectedProfile.grid.rowDef}
                    onCellClick={this.handleCellClick}
                    disableActiveRow={true}
                    disableSort={true}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.handleClose}>Cancel</Button>
            <Button
              onClick={this.handleSave}
              content="Save"
              labelPosition='right'
              disabled={!this.state.hasChanges || this.state.loading}
              loading={this.state.loading}
              icon='save'
              positive
            />
          </Modal.Actions>  
        </Modal>
    )
  }
}

export default OpeningHoursModal;