import React from 'react';
import { Menu } from 'semantic-ui-react';
import Option from './Option';
import _ from 'lodash';

class BaseSettings extends React.Component {

  state = {
    selectedOperator: null,
    activeMenu: { id: null },
    saveCallback: () => {}, // Gets called after saving.
    changed: []
  };

  mapChanged = (options, stateKey = "changed") => {
    return options ? 
        options.map(option => {
          const changed = this.state[stateKey].find(change => change.code === option.code)
          return changed ? changed : option;
        })
      :
        [];
  }


  getChanged = (stateKey = "changed") => {
    let changed = {}

    this.state[stateKey].forEach(x => {
      if (x.code) changed[x.code]=x.value
    })

    return changed;
  }

  getAll = (options, stateKey = "changed") => {
    const changed = {};
    const mapped = this.mapChanged(options, stateKey);

    mapped.forEach(x => {
      if (x.code && Option.getValue(x)) changed[x.code]=Option.getValue(x);
    });

    return changed;

  }

  isAllValid = (options, stateKey = "changed") => {

    if (options.length < 1) return false;

    const all = this.mapChanged(options, stateKey);

    for (const x of all) { 
      if (x.isRequired) {
        if (Option.getValue(x).length < 1) {
          return false;
        }
      }

      if (x.regex) {
        const regex = new RegExp(x.regex)
        if (!regex.test(Option.getValue(x))) return false;
      }
  
    }

    return true;
  }

  isValid = (stateKey = "changed") => {

    if (this.state[stateKey].length < 1) return false
    
    for (const x of this.state[stateKey]) { 
      if (x.isRequired && x.value.length < 1) {
        return false;
      }

      if (x.regex) {
        const regex = new RegExp(x.regex)
        if (!regex.test(x.value)) return false;
      }
  
    }

    return true;
  }

  handleReload = () => { } // adding as default

  handleMenuChange = (item) => {
    this.setState({activeMenu: item })
  }

  handleChangeValue = (option, value, stateKey = "changed") => {
    const changed = [...this.state[stateKey]].filter(x => x.code !== option.code)
    changed.push({...option, value})
    this.setState({[stateKey]: changed}, () => {
      if (option.doRefresh) {
        if (!this.debounce) this.debounce = _.debounce(this.handleReload, 1000);
        this.debounce()
      }
    })  
  }

  getMenuItems = (subTypes) => {
    return subTypes.map(item => {
      return <Menu.Item
      key={item.name}
      name={item.name}
      active={this.state.activeMenu.id === item.id}
      onClick={() => this.handleMenuChange(item)}
      />
    })
  }
}

export default BaseSettings;
