const initialState = { 
	grid: { date:[], headerDef:[], rowDef:[] },
	summary: { date:[], headerDef:[], rowDef:[] }
}


export default (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_SESSIONS':
			return action.payload;
		default:
			return state;
	}
};
