import jsonPlaceholder from '../api/jsonPlaceholder';
import { prepareDetails } from './settings';

class OperatorRegion {

	getRegion = async (regionId) => {
	    const response = await jsonPlaceholder.get(`/organisation/operatorregion/${regionId}`);
    	return response.data
	}
	
	updateRegion = async (Id, OperatorId, details) => {
		const payload = {Id, OperatorId, details: prepareDetails(details)}
		const response = await jsonPlaceholder.put(`/organisation/operatorregion/update`, payload)
		return response.data
	}

	newRegion = async (operatorId) => {
	    const response = await jsonPlaceholder.get(`/organisation/operatorregion/new/${operatorId}`);
	    return response.data
	}

	getRegions = async (operatorId) => {
	    const response = await jsonPlaceholder.get(`/Security/operatorregions/${operatorId}`);
	    return response.data
	}

	getUserRegions = async (OperatorId, UserId, AccessType) => {
	    const response = await jsonPlaceholder.put(`/Security/dataaccess/`, { OperatorId, UserId, AccessTypeDetails: `ACCESSTYPE=${AccessType}` });
	    return response.data
	}

	updateDataAccess = async (UserId, AccessTypeDetails, Details) => {
		const processedDetails = AccessTypeDetails === 0 ? prepareDetails(Details) : this.prepareSelections(Details);
		const payload = {UserId, OperatorId: 0, AccessTypeDetails: `ACCESSTYPE=${AccessTypeDetails}`, Details: processedDetails}
		const response = await jsonPlaceholder.put(`/Security/dataaccess/update`, payload)
		return response.data
	}

	prepareSelections = (selections) => {
		const Details = [];
		const mappings = { "SI": 2, "SUB": 1, "CO": 0, "OP": "-1"}

		selections.forEach(item => {
			Details.push(`${mappings[item.type]}=${item.id}`)
		})

		return Details.join(";");
	}

	reloadList = async (OperatorId, UserId, selections) => {
		const Details = this.prepareSelections(selections)

	    const response = await jsonPlaceholder.put(`/Security/dataaccess/customeroperator`, { OperatorId, UserId, Details: Details });
	   	return response.data.orgGrid;
	}
	
}

export default new OperatorRegion(); 