export const showSuccessAlert = (message, header = 'Success', onConfirm = async () => { }) => async dispatch => {
	dispatch({type: 'SUCCESS_ALERT', payload: {message, onConfirm, visible: true, header} });
};

export const hideSuccessAlert = () => async dispatch => {
	dispatch({type: 'SUCCESS_ALERT', payload: {message: "", visible: false, header: ""} });
};

export const confirmModal = (message, visible, onConfirm) => async dispatch => {
	dispatch({type: 'CONFIRM_MODAL', payload: {message, visible, onConfirm} });
};

export const showPasswordModal = (header, message, onConfirm) => async dispatch => {
	dispatch({type: 'PASSWORD_MODAL', payload: {visible: true, message, header, onConfirm} });
};

export const hidePasswordModal = () => async dispatch => {
	dispatch({type: 'PASSWORD_MODAL', payload: {visible: false, header: '', message: '', onConfirm: () => {} } });
};
