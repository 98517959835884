import React from 'react';
import {connect} from 'react-redux';
import { Grid, Button, Icon, Form } from 'semantic-ui-react';
import { getNewSite, checkForUpdates, fetchOrganisations, fetchSearchTerms, fetchSettingsFilters, getNewCompany, getNewRegion, getSite, getSubCompany, getCompany, updateSite, updateRegion, updateCompany } from '../../actions/index.js'
import OptionsModal from '../modals/OptionsModal';
import OptionsList from './OptionsList.js';
import { showSuccessAlert } from '../../actions/modals';
import BaseSettings from './BaseSettings.js';

class Organisation extends BaseSettings {

  state = {
    options: [],
    companyId: null,
    subcompanyId: null,
    siteId: null,
    changed: [],
    saveFunction: () => {},
    modalOpen: false,
    modalOptions: [],
    modalTitle: "",
    modalSaveFunction: () => {},
    loading: false
  };

  handleRefresh = async () => {

    await this.setState({ loading: true })

    const companyId = this.props.filters.filterSelected.company.id
    const subcompanyId = this.props.filters.filterSelected.subcompany.id    
    const siteId = this.props.filters.filterSelected.site.id
    const operatorId = this.props.filters.filterSelected.operator
    
    let saveFunction = () => updateSite(operatorId, companyId, subcompanyId, siteId, this.getChanged())

    const callback = (options) => {
      this.setState({ options, companyId, subcompanyId, siteId, operatorId, saveFunction, loading: false })
    }

    try {
      if (siteId) {
        await getSite(siteId).then(callback)
      } else {
        if (subcompanyId) {
          saveFunction = () => updateRegion(operatorId, companyId, subcompanyId, this.getChanged())
          await getSubCompany(subcompanyId).then(callback)
        } else {
          saveFunction = () => updateCompany(operatorId, companyId, this.getChanged())
          await getCompany(companyId).then(callback)
        }
      }
    } catch (err) {
      this.setState({ loading: false})
    }

  }

  handleChangeValue = (option, value) => {
    const changed = [...this.state.changed].filter(x => x.code !== option.code)
    changed.push({...option, value})
    const options = [...this.state.options]
    const index = options.findIndex(item => item.code === option.code)
    options[index] = option
    this.setState({options, changed})
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (checkForUpdates(prevProps.filters, this.props.filters)) {
      this.handleRefresh();
    }
  }

  createNewSite = () => {
    const { operatorId, companyId, subcompanyId } = this.state
    getNewSite(operatorId, companyId, subcompanyId).then(modalOptions => {
      this.setState({ modalSaveFunction: this.saveNewSite, modalOptions, modalTitle: "New Site", modalOpen: true })
    });
  }

  createNewRegion = () => {
    const { operatorId, companyId } = this.state
    getNewRegion(operatorId, companyId).then(modalOptions => {
      this.setState({ modalSaveFunction: this.saveNewRegion, modalOptions, modalTitle: "New Region", modalOpen: true })
    });
  }

  createNewCompany = () => {
    const { operator } = this.props.filters.filterSelected
    getNewCompany(operator).then(modalOptions => {
      this.setState({ modalSaveFunction: this.saveNewCompany, modalOptions, modalTitle: "New Company", modalOpen: true })
    });
  }

  handleSave = () => {
    const catchFunction = () => this.setState( { loading: false });

    this.setState( { loading: true }, () => {
      this.state.saveFunction().then(() => {
        fetchOrganisations(this.props.filters,true).then(result => {
          this.props.fetchSettingsFilters({...result}).then(() => this.setState( { loading: false, changed: [] }, () => this.props.showSuccessAlert('Changes saved successfully!')));
        }).catch(catchFunction)
      }).catch(catchFunction)
    })
  }

  saveNewCompany = async (options) => {
    
    const { operator } = this.props.filters.filterSelected
    await updateCompany(operator, 0, options).then(newCompany => {
      fetchOrganisations(this.props.filters, true).then(result => {
        const company = result.organisations.find(x => x.id === newCompany) || { id : null }
        const data = {...result, filterSelected: {...this.props.filters.filterSelected, ...{company} }}
        this.props.fetchSettingsFilters(data).then(() => this.setState({ modalOpen: false }, () => this.props.showSuccessAlert(`New company successfully created.`)))   
      });
    })
  }

  saveNewSite = async (options) => {
    const { operatorId, companyId, subcompanyId } = this.state
    await updateSite(operatorId, companyId, subcompanyId, 0, options).then(newSite => {
      fetchOrganisations(this.props.filters,true).then(result => {
        const organisation = result.organisations.find(x => x.id === companyId) || { id : null };
        const subCompany = organisation.subCompanies.find(x => x.id === subcompanyId) || { id : null };
        const site = subCompany.sites.find(x => x.id === newSite)
        const data = {...result, filterSelected: {...this.props.filters.filterSelected, ...{site} }}
        this.props.fetchSettingsFilters(data).then(() => this.setState({ modalOpen: false }, () => this.props.showSuccessAlert(`New site successfully created.`)))
      })
    })
  }

  saveNewRegion = async (options) => {
    const { operatorId, companyId} = this.state
    await updateRegion(operatorId, companyId, 0, options).then(newRegion => {
      fetchOrganisations(this.props.filters, true).then(result => {
        const organisation = result.organisations.find(x => x.id === companyId) || { id : null }
        const subcompany = organisation.subCompanies.find(x => x.id === newRegion) || { id : null };
        const data = {...result, filterSelected: {...this.props.filters.filterSelected, ...{subcompany} }}
        this.props.fetchSettingsFilters(data).then(() => {
          this.setState({ modalOpen: false }, () => this.props.showSuccessAlert(`New region successfully created.`))
        });
      })
    })
  }

  render() {

    const { operator } = this.props.filters.filterSelected;

    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row className="flex-stretch">
          <Grid.Column width="16" className="gs-flex-container-vertical">
            <OptionsModal open={this.state.modalOpen} options={this.state.modalOptions} title={this.state.modalTitle} onClose={() => this.setState({ modalOpen: false })} onSave={this.state.modalSaveFunction} />
            <div className="table-overflow flex-stretch-vertical p-1">
              <OptionsList options={this.state.options} onChange={this.handleChangeValue} />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <Form>
              { this.props.logIn.optionAllowNewOrgCompany && <Button icon color='blue' labelPosition='right' loading={this.state.loading} disabled={!operator || this.state.siteId || this.state.subcompanyId || this.state.loading } onClick={this.createNewCompany}>
                New Company
                <Icon name='add' />
              </Button> }
              { this.props.logIn.optionAllowNewOrgRegion && <Button icon color='blue' labelPosition='right' loading={this.state.loading} disabled={!this.state.companyId || this.state.subcompanyId || this.state.siteId || this.state.loading} onClick={this.createNewRegion}>
                New Region
                <Icon name='add' />
              </Button> }
              { this.props.logIn.optionAllowNewOrgSite && <Button icon color='blue' labelPosition='right' loading={this.state.loading} disabled={!this.state.subcompanyId || this.state.siteId || this.state.loading} onClick={this.createNewSite}>
                New Site
                <Icon name='add' />
              </Button> }
              { this.props.logIn.optionAllowSaveOrg && <Button icon positive labelPosition='right' loading={this.state.loading} onClick={this.handleSave} disabled={!this.isValid() || this.state.loading}>
              Save Changes
              <Icon name='save' /> 
            </Button> }
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
	return {
    logIn: state.logIn,
    filters: state.settingsFilters
  };
};

export default connect(mapStateToProps, { showSuccessAlert, fetchSearchTerms, fetchOrganisations, fetchSettingsFilters})(Organisation);
