import React from 'react';
import {connect} from 'react-redux';
import { Button, Modal, Header, Input, Form, Message, Loader, Dimmer } from 'semantic-ui-react'
import { hidePasswordModal } from '../../actions/modals';
import md5 from 'md5';

class EnterPasswordModal extends React.Component {

  state = {
    password: '',
    error: false,
    loading: false
  }

  onConfirm = () => {
    if (this.props.login.hash !== md5(this.state.password)) {
      this.setState({ error: true });
    } else {
      this.setState({ loading: true, password: '', error: false }, () => {
        this.props.data.onConfirm()
        .then(() => {
          this.setState({ loading: false }, () => this.props.hidePasswordModal());
        })
        .catch(() => {
          this.setState({ loading: false }, () => this.props.hidePasswordModal());
        })
      })
    }
  }

  render() {
    const { visible } = this.props.data;
    return (
        <Modal
          open={visible}
          onClose={this.props.hidePasswordModal}
          closeIcon
          size="tiny"
        >
          <Header>{this.props.data.header || "Enter your password to confirm"}</Header>
          <Modal.Content>
            { this.state.loading ?
              <Dimmer active inverted>
                <Loader inverted inline='centered'>Processing</Loader>
              </Dimmer>
            : 
              <div>
                { this.state.error && <Message error content='The password you entered is incorrect, please try again.' /> }
                <div className="mb-1">{ this.props.data.message }</div>
                <Form className="mt-1">
                  <Form.Field>
                    <label>Please enter your password to continue:</label>
                    <Input className="mt-1" fluid type="password" onChange={(event, { value }) => this.setState({ password: value })} />
                  </Form.Field>
                </Form>
              </div>           
            }

         </Modal.Content>
          <Modal.Actions>
            <Button
              content="Cancel"
              onClick={this.props.hidePasswordModal}
              negative
            />
            <Button
              content="Confirm"
              onClick={this.onConfirm}
              disabled={this.state.password.length < 1 || this.state.loading}
              positive
            />
          </Modal.Actions>  
        </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    data: state.passwordModal,
    login: state.logIn
  };
};

export default connect(mapStateToProps, { hidePasswordModal })(EnterPasswordModal);
