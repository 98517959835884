
const defaultReducer =  (key, initialState) => {
	return (state = initialState, action) => {
		switch (action.type) {
			case key:
				return typeof action.payload === "string" ? action.payload : {...initialState, ...action.payload} ;
			default:
				return state;
		}
	}
}

const initialState = { 
	grid: { date:[], headerDef:[], rowDef:[] },
	summary: { date:[], headerDef:[], rowDef:[] }
}

export const transactionsReducer = defaultReducer('FETCH_TRANSACTIONS', initialState);
export const transactionAdjustReducer = defaultReducer('SET_TRANSACTION_ADJUST', "");
export const transactionAuthoriseReducer = defaultReducer('SET_TRANSACTION_AUTHORISE', "");
export const transactionVoidReducer = defaultReducer('SET_TRANSACTION_VOID', "");

	