import React from 'react';
import { Checkbox, Button, Header, Modal } from 'semantic-ui-react';
import OptionsList from '../systemAdmin/OptionsList';
import Option from '../systemAdmin/Option';

class OptionsModal extends React.Component {

  state = {
    loading: false,
    canSubmit: false,
    options: [],
    changed: {},
    another: false
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.options !== prevProps.options){
      
      const options = this.props.options.map(option => {
        if (!option.isEditable) return option;
        return {...option, value: Option.getValue(option)}
      })

      this.setState({options, canSubmit: this.checkCanSubmit(options)})
    } 
  }
 
  checkCanSubmit = (options) => {

    if (options.length < 1) return false;

    let canSubmit = true

    options.forEach(option => {
      if (option.isRequired && option.value === "") {
        canSubmit = false;
      }
    })
    
    return canSubmit
  }

  handleChangeValue = (option, value) => {
    const changed = {...this.state.changed, ...{[`${option.code}`]: value}}
    const options = [...this.state.options]
    const index = options.findIndex(item => item.code === option.code)
    options[index] = {...option, value}

    const canSubmit = this.checkCanSubmit(options)

    
    this.setState({options, changed, canSubmit}, () => {
      if (option.doRefresh) {
        if (this.props.handleReload) this.props.handleReload(changed, false, false);
      }
    })
  }
 
  handleSave = () => {
    let changed = {}

    this.state.options.forEach(option => {
      if (option.isRequired && Option.getValue(option).length > 0) changed[option.code]=Option.getValue(option); 
    })

    changed = {...changed, ...this.state.changed}

    const stateCallback = () => this.setState({ loading: false });

    this.setState({ changed: [], loading: true }, () => {
      this.props.onSave(changed, this.state.another, true).then(stateCallback, stateCallback);
    })
  }

  render() {
    return (
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
          closeIcon
          size="large"
        >
          <Header>{this.props.title}</Header>
          <Modal.Content scrolling style={{ minHeight: "200px"}}>
            <OptionsList prefix={this.props.prefix} options={this.state.options} onChange={this.handleChangeValue} />     
          </Modal.Content>
          <Modal.Actions>
            { this.props.another && <Checkbox label="Create more devices" style={{ float: "left" }} onChange={() => this.setState({ another: !this.state.another })} /> }
            <Button negative onClick={this.props.onClose}>Cancel</Button>
            <Button
              onClick={this.handleSave}
              content="Save"
              labelPosition='right'
              disabled={!this.state.canSubmit || this.state.loading}
              loading={this.state.loading}
              icon='save'
              positive
            />
          </Modal.Actions>  
        </Modal>
    )
  }
}

export default OptionsModal;