import React from 'react';
import { Button, Grid, Select } from 'semantic-ui-react';
import FilterStatus from './FilterStatus';

class CommsViewFilters extends React.Component {

  onChange = (event, { name, value } ) => {
    this.props.onChange(name, !this.props[name]);
  }

  render() {
    const filters = this.props.filters;


    return (
      <div className="commsViewer">
      
      { this.props.currentCommsStatus ?
        <Grid columns='4'>
          <Grid.Row columns="3">
            <Grid.Column>
              <FilterStatus 
                key="green"
                color="#008000"
                label="Online - Enabled"
                name="onlineEFTEnabled"
                value={filters.onlineEFTEnabledQty}
                checked={this.props.onlineEFTEnabled}
                onChange={this.onChange}
              />
            </Grid.Column>
            <Grid.Column>
            <FilterStatus 
                key="red"
                color="#FF0000"
                label="Offline - Site Open"
                name="offlineSiteOpen"
                value={filters.offlineSiteOpenQty}
                checked={this.props.offlineSiteOpen}
                onChange={this.onChange}
              />
            </Grid.Column>
            <Grid.Column>
            <FilterStatus 
                key="orange"
                color="#FFA500"
                label="Install - Pending"
                name="pending"
                value={filters.pendingQty}
                checked={this.props.pending}
                onChange={this.onChange}
              />
            </Grid.Column>

          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
            <FilterStatus 
                key="purple"
                color="#800080"
                label="Online - Disabled"
                name="onlineEFTDisabled"
                value={filters.onlineEFTDisabledQty}
                checked={this.props.onlineEFTDisabled}
                onChange={this.onChange}
              />
            </Grid.Column>
            <Grid.Column>
            <FilterStatus 
                key="blue"
                color="#0000FF"
                label="Offline - Site Closed"
                name="offlineSiteClosed"
                value={filters.offlineSiteClosedQty}
                checked={this.props.offlineSiteClosed}
                onChange={this.onChange}
              />
            </Grid.Column>
            <Grid.Column>
              <FilterStatus 
                key="yellow"
                color="#FFFF00"
                label="Install - Auth. Req."
                name="authReq"
                value={filters.authReqQty}
                checked={this.props.authReq}
                onChange={this.onChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              { this.props.refreshButton}
              { this.props.authorise && <Button positive loading={this.props.loading || false} size="small" disabled={this.props.loading || false} onClick={this.props.handleAuthorise}>Authorise</Button> }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      :
        <Grid>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Select options={this.props.periods} value={this.props.selectedPeriod} onChange={this.props.handlePeriodChange} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign="middle" width="12">
            <Select options={this.props.reports} value={this.props.selectedReport} onChange={this.props.handleReportChange} fluid />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      </div>
    )
  }
}

export default CommsViewFilters
