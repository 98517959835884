import React from 'react';
import {connect} from 'react-redux';
import { Header, Button, Input, Form, Grid, Message } from 'semantic-ui-react';
import { fetchCollectionsFilters, fetchInstallationsFilters, fetchTransactionsFilters, fetchCommsViewerFilters, fetchSessionsFilters, fetchLogin, setPassword, setLogout } from '../../actions';
import { toggleJira } from '../helpers/jira'
import PasswordStrengthBar from 'react-password-strength-bar';

class Panes extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      error: null,
      newPasswordRepeat: '',
      newPassword: '',
      loading: false,
      score: 0
    };
  }

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  setPassword = () => {
    const { email, hash } = this.props.logIn;
    const { newPassword, newPasswordRepeat } = this.state;

    this.setState({ loading: true }, () => {
      this.props.setPassword(email, hash, newPassword, newPasswordRepeat).then(
        () => {
          this.props.fetchLogin(email, newPassword).then((result) => {
            this.setState({ loading: false }, () => {
              sessionStorage.setItem('login', JSON.stringify(this.props.logIn));
              this.props.history.push('/dashboard');
            })
          })
        },
        error => this.setState({ loading: false, error: error.response.data })
      );
    });

  }

  handleScoreChange = (score) => this.setState({ score });

  handleReturnToLogin = () => this.props.setLogout().then(() => this.props.history.push("/"));

  canSubmit = () => {
    const { newPassword, newPasswordRepeat, score } = this.state;

    if (newPassword.length < 1) return false;
    if (newPasswordRepeat.length < 1) return false;
    if (score < 2) return false;

    return true;
  }

  render() {
    toggleJira('0')
      return (
        <Grid centered>
          <Grid.Row>
            <Grid.Column widescreen="4" computer="6" largeScreen="5" tablet="7" textAlign='center'>
              <img src='logo.png' alt="Rimini Logo" id="logo" />
              <Header as='h2'>Set New Password</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column widescreen="3" computer="5" largeScreen="4" tablet="6">
                <Form id="login">
                  { this.state.error && <Message negative className="mb-1">{ this.state.error }</Message> }

                  <Form.Field>
                    <Input icon="lock" placeholder="New Password" iconPosition="left" name="newPassword" onChange={this.handleChange} value={this.state.newPassword} type='password'/>
                    <PasswordStrengthBar password={this.state.newPassword} scoreWords={['very weak', 'weak', 'moderate', 'strong', 'very strong']} onChangeScore={this.handleScoreChange}/>
                  </Form.Field>

                  <Form.Field>
                    <Input icon="lock" placeholder="Confirm New Password" iconPosition="left" name="newPasswordRepeat" onChange={this.handleChange} value={this.state.newPasswordRepeat} type='password'/>
                  </Form.Field>

                  <div>
                    <Button primary disabled={this.state.loading || !this.canSubmit()} onClick={this.setPassword} size='large'>Set Password</Button><Button onClick={this.handleReturnToLogin} size='large'>Cancel</Button>
                  </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    logIn: state.logIn,
    logOut: state.logOut,
    logInSet: state.logInSet,
    sessionsFilters: state.sessionsFilters,
    collectionsFilters: state.collectionsFilters,
    installationsFilters: state.installationsFilters,
    transactionsFilters: state.transactionsFilters,
    commsViewerFilters: state.commsViewerFilters,
  };
};

export default connect(mapStateToProps, {
  fetchLogin,
  setPassword,
  setLogout,
  fetchSessionsFilters,
  fetchCollectionsFilters,
  fetchInstallationsFilters,
  fetchTransactionsFilters,
  fetchCommsViewerFilters,
})(Panes);
