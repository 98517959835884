import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react'

const ReportsDropdown = (props) => {

    const options = props.options.length ? props.options.map((option, index) => { return { ...option, value: option.id, key: `${option.text} ${index}` } }) : [] 

    return (

        

            
            <Form.Field inline> 
            <label>{props.text}</label>
            <Dropdown
                selection
                clearable
                options={options}
                placeholder={`Choose an option..`}
                name={props.text}
                value={props.value}
                onChange={(event, { name, value }) => props.onChange(name, value)}
                disabled={options.length < 1}
                />
            </Form.Field>

    )
    
}

export default ReportsDropdown