const defaultReducer =  (key, initialState) => {
	return (state = initialState, action) => {
		switch (action.type) {
			case key:
				return action.payload;
			default:
				return state;
		}
	}
}

export const setActiveInstallationReducer = defaultReducer('SET_ACTIVE_INSTALLATION', {});
export const installationReducer = defaultReducer('FETCH_INSTALLATIONS', { grid: { date:[], headerDef:[], rowDef:[] } });
export const addPositionsReducer = defaultReducer('ADD_POSITIONS', "");
