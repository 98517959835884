import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

class DownloadButton extends React.Component {
  render() {
    return (
      <Button color='teal' icon size='mini' loading={this.props.loading || false} disabled={this.props.disabled || false} onClick={this.props.onClick}>
        <Icon name="download" />
      </Button>
    )
  }
};

export default DownloadButton;
