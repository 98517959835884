import React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Dropdown, Grid, Input, Select } from 'semantic-ui-react';
import { getCategories, getGameTitles, getGroupings, getManufacturers, getStatuses, getVariants } from '../../actions/index';
import SelectOperator from '../shared/SelectOperator';

class EGMFilters extends React.Component {

  state = {
    manufacturers: [],
    categories: [],
    games: [],
    statuses: [],
    selectedStatuses: [],
    variants: [],
    groupings: [],
    statusOpen: false
  };

  componentDidUpdate = (prevProps) => {
    const { selectedOperator } = this.props.searchParams;

    if (selectedOperator && prevProps.searchParams.selectedOperator !== selectedOperator) {
      getManufacturers(selectedOperator).then(manufacturers => {
        this.setState({ manufacturers })
      })

      getCategories(selectedOperator).then(categories => {
        this.setState({ categories })
      })

      getGameTitles(selectedOperator).then(games => {
        this.setState({ games });
      })

      if (this.state.statuses.length < 1) getStatuses().then(statuses => {
        this.setState({ statuses, selectedStatuses: statuses.map(x => x.value) }, () => {
          this.props.onChange("selectedStatus", this.state.selectedStatuses.join(";"))
        })
      })
      if (this.state.variants.length < 1) getVariants().then(variants => this.setState({ variants }))
      if (this.state.groupings.length < 1) getGroupings().then(groupings => this.setState({ groupings }))
    }
  }

  handleSelection = (event, { name, value }) => this.props.onChange(name, value)

  handleStatusClick = (value) => {
    let selectedStatuses = [...this.state.selectedStatuses];
    
    if (selectedStatuses.includes(value)) {
      selectedStatuses = selectedStatuses.filter(x => x !== value)
    } else {
      selectedStatuses.push(value)
    }

    this.setState( { selectedStatuses }, () => {
      this.props.onChange("selectedStatus", this.state.selectedStatuses.join(";"))
    })
  }

  getSelect = (selectedKey, arrayKey) => {
    const disabled = !this.props.searchParams.selectedOperator;
    return <Select disabled={disabled} options={this.state[arrayKey]} value={this.props.searchParams[selectedKey]} name={selectedKey} onChange={this.handleSelection} fluid />
  }

  render() {

    const disabled = !this.props.searchParams.selectedOperator || this.props.loading || false;

    return (
      <Grid columns="4" id="EGMFilters">
        <Grid.Row>
          <Grid.Column width="4" verticalAlign="middle">Operator</Grid.Column>
          <Grid.Column width="5" verticalAlign="middle">
            <SelectOperator fluid operators={this.props.operators} value={this.props.searchParams.selectedOperator} name="selectedOperator" onChange={this.handleSelection} />
          </Grid.Column>
          <Grid.Column width="3" verticalAlign="middle">Group By</Grid.Column>
          <Grid.Column width="4" verticalAlign="middle">{ this.getSelect("selectedGrouping", "groupings") }</Grid.Column>              
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="4" verticalAlign="middle">Category</Grid.Column>
          <Grid.Column width="5" verticalAlign="middle">{ this.getSelect("selectedCategory", "categories") }</Grid.Column> 
          <Grid.Column width="3" verticalAlign="middle">Search</Grid.Column>
          <Grid.Column width="4" verticalAlign="middle"><Input fluid name="searchText" onChange={this.handleSelection} /></Grid.Column>  
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="4" verticalAlign="middle">Manufacturer</Grid.Column>
          <Grid.Column width="5" verticalAlign="middle">{ this.getSelect("selectedManufacturer", "manufacturers") }</Grid.Column>
          <Grid.Column width="3" verticalAlign="middle"></Grid.Column>               
          <Grid.Column width="4" verticalAlign="middle" style={{ padding: "0em 0.5em"}}><Button disabled={disabled} loading={this.props.loading || false} style={{ padding: "0.43em 1em"}} onClick={this.props.onRefresh} positive size="small">Refresh</Button></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="4" verticalAlign="middle">EGM Cabinet Name</Grid.Column>
          <Grid.Column width="5" verticalAlign="middle">{ this.getSelect("selectedGameName", "games") }</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="4" verticalAlign="middle">EGM Asset - Status</Grid.Column>
          <Grid.Column width="5" verticalAlign="middle">
            <Dropdown closeOnChange={false} item selection fluid id="status" text={ disabled ? "" : `${this.state.selectedStatuses.length} selected`} disabled={disabled}>
              <Dropdown.Menu>
                {this.state.statuses.map(({ value, text }) => (
                  <Dropdown.Item key={value} onClick={() => this.handleStatusClick(value)}>
                    <Checkbox checked={this.state.selectedStatuses.includes(value)} disabled className="clickable mr-1" /> {text}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="4" verticalAlign="middle">EGM Asset - Variant</Grid.Column>
          <Grid.Column width="5" verticalAlign="middle">{ this.getSelect("selectedVariant", "variants") }</Grid.Column>
        </Grid.Row>           
      </Grid>
    );
  }
};


const mapStateToProps = state => {
	return {
    operators: state.operators.filter(x => x.value !== -1),
  };
};

export default connect(mapStateToProps, {})(EGMFilters);
