import moment from 'moment';
import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { APIDateFormat, UIDateFormat } from '../../config/dates';

class CollectionsFiltersOther extends React.Component {

  handleChange = (event, {name, value}) => {
    this.props.onChange(name, value)
  }

  handleDateChange = (event, {name, value}) => {
    this.props.onChange(name, moment(value, UIDateFormat).format(APIDateFormat))
  }

  render() {
    return (
          <Form id="collectionsFiltersOther">
          <Form.Group inline>
            <Form.Field inline>
              <label>Asset</label>
              <Input style={{width: '6em'}} name='assetNo' onChange={this.handleChange} value={this.props.assetNo} />
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
              <Form.Field>
              <label>Collection ID</label>
              <Input style={{width: '6em'}} name='collectionId' onChange={this.handleChange} value={this.props.collectionId} />
            </Form.Field>

          </Form.Group>


        </Form>
    );
  }
}


export default CollectionsFiltersOther;
