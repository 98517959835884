import React from 'react';
import { connect } from 'react-redux';
import { Accordion, Button, Grid, Header, Icon, List, Tab, Table } from 'semantic-ui-react';
import { buildTree } from '../../actions/egm';
import { fetchSecurityUser, fetchSecurityUsers, fetchUserAccessProfile, fetchUserAccessProfiles, getSecurityUser, resetUserPassword, updateSecurityUser } from '../../actions/index';
import { showSuccessAlert } from '../../actions/modals';
import OperatorRegion from '../../actions/operatorRegion';
import OptionsModal from '../modals/OptionsModal';
import DataGridRequest from '../shared/DataGridRequest';
import BaseSettings from '../systemAdmin/BaseSettings';
import Filters from '../systemAdmin/Filters';
import OptionsList from '../systemAdmin/OptionsList';
import SelectProfile from '../systemAdmin/SelectProfile';

class UserAccounts extends BaseSettings {
  
  defaultUser = { profileData: []}
  defaultProfile = { profileId: null, profileData: [] }

  state = {
    activeIndex: 0,
    loading: false,
    dataAccess: 1,
    selectedOperator: null,
    profiles: [],
    activeUser: this.defaultUser,
    search: "",
    selectedProfile: this.defaultProfile,
    userAccessProfiles: [],
    modalOpen: false,
    modalTitle: "",
    modalOptions: [],
    changed: [],
    profileChanged: false,
    operatorRegions: [],
    orgGrid: { data: [], headerDef: [], rowDef: [] }, 
    orgTree: [],
    orgTreeSelection: null,
    dataAccessChanged: [],
    orgGridSelections: [],
    operatorCustomerChanged: false,
    selectedRow: null,
    showDataAccess: false,
    expanded: [],
    allExpanded: false,
  }

  componentDidMount() {
    const selectedOperator = this.props.operators.length === 1 ? this.props.operators[0].key : null;

    if (selectedOperator) {
      this.fetchAccessProfiles(selectedOperator)
    }

    this.setState({selectedOperator});
  }

  handleDataAccessChangeValue = (option, value) => this.handleChangeValue(option, value, "dataAccessChanged")

  fetchAccessProfiles = (operator) => {
    fetchUserAccessProfiles(operator, this.state.search).then(userAccessProfiles => this.setState({userAccessProfiles}));
  }

  handleOperatorSelect = (event, data) => {
    this.setState({selectedOperator: data.value, profiles: [], activeUser: {...this.defaultUser}, selectedProfile: {...this.defaultProfile} }, () => this.fetchAccessProfiles(data.value))
  }

  handleClick = (user) => {
    this.setState({ expanded: [], allExpanded: false }, () => this.fetchUser(user.id, user.name))
  }

  fetchUser = async (id) =>{
    
    const callback = () => this.setState({ loading: false })
    await this.setState({ loading: true });

    fetchSecurityUser(id).then(
      user => {
        const activeUser = {...user, id}
        fetchUserAccessProfile(activeUser.userAccessProfile, false).then(
          selectedProfile => {
            const showDataAccess = user.optionDisplayDataAccess;
            this.setState({activeUser, showDataAccess, orgTreeSelection: null, dataAccessChanged: [], orgGridSelections: [], operatorCustomerChanged: false, selectedProfile, loading: false }, () => this.fetchDataAccess(id));
          },
          callback
        );
      },
      callback
    );
  }

  fetchDataAccess = (id) => {
    OperatorRegion.getUserRegions(this.state.selectedOperator, id, this.state.dataAccess).then(result => {
      const operatorRegions = result.operatorRegions || this.state.operatorRegions;
      const orgGrid = result.orgGrid || this.state.orgGrid;
      const orgTree = result.orgTree ? buildTree(result.orgTree) : this.state.orgTree;
      this.setState({ operatorRegions, orgGrid, orgTree, orgTreeSelection: null, orgGridSelections: result.selections, dataAccessChanged: [], operatorCustomerChanged: false })
    });
  }

  handleSearch = async () => {
    await this.setState({ loading: true})
    const { selectedOperator, search } = this.state
    await fetchSecurityUsers(selectedOperator, search).then(
      profiles => this.setState({profiles, selectedProfile: this.defaultProfile, activeUser: this.defaultUser, loading: false}),
      () => this.setState({ loading: false })
    )
    return true;
  }

  handleSearchChange = (event, { value }) => {
    this.setState({ search: value })
  }

  handleProfileSelect = (event, { value }) => {
    fetchUserAccessProfile(value, false).then(selectedProfile => {
      this.setState({selectedProfile, profileChanged: true})
    });
  }

  handleOptionChange = (option, value) => {
    const changed = [...this.state.changed].filter(x => x.code !== option.code)
    changed.push({...option, value})
    const profileData = [...this.state.activeUser.profileData]
    const index = profileData.findIndex(item => item.code === option.code)
    profileData[index] = option
    this.setState({activeUser: {...this.state.activeUser, profileData}, changed})
  }

  handleSave = () => {
    const activeUser = {...this.state.activeUser}
    updateSecurityUser(activeUser.id, this.state.selectedOperator, this.state.selectedProfile.profileId, this.getChanged()).then(response => {
      this.setState({changed: [], profileChanged: false }, () => {
        this.handleSearch().then(() => {
          this.fetchUser(activeUser.id, activeUser.name)
          this.props.showSuccessAlert(`Your changes were saved successfully.`) 
        })
      })
    })
  }

  handleNewUser = () => {
    getSecurityUser(this.state.selectedOperator, this.state.selectedProfile.profileId).then(response => {
      this.setState({ modalOptions: response.profileData, modalTitle: "New User", modalOpen: true })
    });
  }

  saveNewUser = async (details) => {
    await updateSecurityUser(0, this.state.selectedOperator, this.state.selectedProfile.profileId, details).then(userId => {
      this.handleSearch().then(() => {
        this.setState({ modalOpen: false }, () => {
          this.fetchUser(userId)
          this.props.showSuccessAlert(`Your new user was successfully created`)
        })
      })
    })
  }

  handleResetPassword = () => {
    resetUserPassword(this.state.activeUser.userEmail).then(() => this.props.showSuccessAlert(`A reset password email has been sent to ${this.state.activeUser.userEmail}!`))
  }

  handleProfileClick = (profile) => {
    fetchUserAccessProfile(profile, false).then(selectedProfile => {
      this.setState({selectedProfile, activeUser: { profileData: []}, profileChanged: false })
    });
  }

  toggleCollapsed = (id) => {
    const expanded = [...this.state.expanded];
    const index = expanded.findIndex(x => x === id)
    
    if (index >= 0) {
      expanded.splice(index, 1);
    } else {
      expanded.push(id);
    }
    
    this.setState({ expanded })
  }

  renderTree = (item) => {
    let css = "clickable text-normal"

    if (this.state.orgTreeSelection === item) {
      css += " treeActive"
    }

    const isExpanded = this.state.expanded.includes(item.id);
    const iconName = isExpanded ? "minus square outline" : "plus square outline"

    return (
      <div>
        <Accordion.Title index={item.id} className={css}>
          {item.children.length > 0 && <Icon name={iconName} onClick={() => this.toggleCollapsed(item.id)} /> }
          <span onClick={() => this.handleNodeClick(item)} className={css}>{item.name}</span>
        </Accordion.Title>   
        { (item.children.length > 0 && isExpanded) && 
        <Accordion.Content>
            <Accordion.Accordion className="ml-3" exclusive={false} styled={false}>
              {item.children.map(x => this.renderTree(x))}
            </Accordion.Accordion>
        </Accordion.Content>
        }
      </div>
    )
  }

  handleNodeClick = (orgTreeSelection) => this.setState({ orgTreeSelection })

  addNode = () => {
    let orgGridSelections = [...this.state.orgGridSelections];
    orgGridSelections.push(this.state.orgTreeSelection);
    
    this.setState({ orgGridSelections, operatorCustomerChanged: true }, () => {
      OperatorRegion.reloadList(this.state.selectedOperator, this.state.activeUser.id, this.state.orgGridSelections).then(orgGrid => this.setState({ orgGrid }))
    })
  }

  removeRow = () => {
    let orgGridSelections = [...this.state.orgGridSelections];
    orgGridSelections = orgGridSelections.filter(x => x.id !== this.state.selectedRow.id);

     this.setState({ orgGridSelections, operatorCustomerChanged: true }, () => {
      OperatorRegion.reloadList(this.state.selectedOperator, this.state.activeUser.id, this.state.orgGridSelections).then(orgGrid => this.setState({ orgGrid, selectedRow: null }))
    })
  }

  updateDataAccess = () => {
    const changed = this.state.dataAccess === 0 ? this.getChanged("dataAccessChanged") : this.state.orgGridSelections;
    OperatorRegion.updateDataAccess(this.state.activeUser.id, this.state.dataAccess, changed).then(() => {
      this.fetchDataAccess(this.state.activeUser.id)
      this.props.showSuccessAlert("Data Access updated successfully!")
    })
  }

  render() {
    const optionsPrefix = (
      <Table.Row key="access_profile">
        <Table.Cell width="5">Access Profile</Table.Cell>
        <Table.Cell width="11"><SelectProfile value={this.state.selectedProfile.profileId} profiles={this.state.userAccessProfiles} onChange={this.handleProfileSelect} disabled={!this.state.selectedOperator} /></Table.Cell>
      </Table.Row>
    );

    const panes = []

    panes.push(
      { menuItem: 'General Options', render: () => {
        return (
          <Tab.Pane className="gs-flex-container-vertical height-100">
            <Grid className="gs-flex-container-vertical height-100">
              <Grid.Row className="flex-stretch">
                <Grid.Column width="16" className="gs-flex-container-vertical">
                  <div className="table-overflow flex-stretch-vertical p-1">
                    <OptionsList labelWidth="5" options={this.state.activeUser.profileData} prefix={optionsPrefix} onChange={this.handleOptionChange} />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="8">
                  { this.props.logIn.optionallowUserPasswordReset && 
                    <Button icon color='blue' disabled={!this.state.activeUser.id || this.state.loading} loading={this.state.loading} onClick={this.handleResetPassword} size="mini" labelPosition='right'>
                      Reset Password
                      <Icon name='mail' />
                    </Button>
                  }
                </Grid.Column>
                <Grid.Column width="8" textAlign="right">
                  { this.props.logIn.optionAllowSaveUser &&
                    <Button icon positive disabled={(!this.isValid() && !this.state.profileChanged) || this.state.loading} loading={this.state.loading} onClick={this.handleSave} size="mini" labelPosition='right'>
                      Save Changes
                      <Icon name='save' />
                    </Button>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
         </Tab.Pane>
          )
        } 
      }
    )

    if (this.state.showDataAccess) {
      
      const css = (this.state.orgTreeSelection && this.state.orgTreeSelection.type === "OP") ? "clickable treeActive" : "clickable";

      panes.push(
        { menuItem: 'Data Access', render: () => {
          return (
            <Tab.Pane className="gs-flex-container-vertical height-100">
              <Grid className="gs-flex-container-vertical height-100" columns="2">
                <Grid.Row className="flex-stretch">
                  <Grid.Column width="6" className="gs-flex-container-vertical">
                    <div className="table-overflow flex-stretch-vertical">
                      <Accordion.Accordion className="m-1" exclusive={false} styled={false}>
                        <Accordion.Title index="all" className={css}>
                          <Icon name={ this.state.allExpanded ? "minus square outline" : "plus square outline" } className="clickable" onClick={() => this.setState({ allExpanded: !this.state.allExpanded }) } />
                          <span onClick={() => { this.setState({ orgTreeSelection: { id: 0, name: "All", type: "OP" }}) } } className="clickable">All</span>
                        </Accordion.Title>   
                        { this.state.allExpanded &&
                        <Accordion.Content>
                          <Accordion.Accordion className="ml-3" exclusive={false} styled={false}>
                            { this.state.orgTree.map(x => this.renderTree(x))}
                          </Accordion.Accordion>
                        </Accordion.Content>
                        }
                      </Accordion.Accordion>
                    </div>
                  </Grid.Column>
                  <Grid.Column width="10" className="gs-flex-container-vertical">
                    <DataGridRequest
                      headings={this.state.orgGrid.headerDef}
                      data={this.state.orgGrid.data}
                      rowDef={this.state.orgGrid.rowDef}
                      onRowClick={(selectedRow) => this.setState({ selectedRow })}
                      fullHeight
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="flex-shrink">
                  <Grid.Column width="6">
                    <Button icon positive loading={this.state.loading} disabled={this.state.orgTreeSelection === null || this.state.orgGridSelections.map(x => x.id).includes(this.state.orgTreeSelection.id) || this.state.loading} onClick={this.addNode} size="mini" labelPosition='right'>
                      Add
                      <Icon name='plus' />
                    </Button>
                  </Grid.Column>
                  <Grid.Column width="10" textAlign="right">
                    <Button icon negative loading={this.state.loading} disabled={!this.state.selectedRow || this.state.loading} onClick={this.removeRow} size="mini" labelPosition='right'>
                      Remove
                      <Icon name='minus' />
                    </Button>
                    <Button icon positive loading={this.state.loading} disabled={!this.state.operatorCustomerChanged || this.state.loading} onClick={this.updateDataAccess} size="mini" labelPosition='right'>
                      Save Changes
                      <Icon name='save' />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
          )
          } 
        }
      )
    }

    panes.push(
      { menuItem: 'System Access', render: () => {
        return (
          <Tab.Pane className="gs-flex-container-vertical height-100">
             <div className="table-overflow p-1 flex-stretch-vertical">
              { this.state.selectedProfile.profileData.length > 0 && 
              <div>
                <Header>User System Access Profile - {this.state.selectedProfile.profileName}</Header>
                <OptionsList labelWidth="13" options={this.state.selectedProfile.profileData} />  
              </div>
              }
            </div>   
          </Tab.Pane>
        )
      } }
    )

    const append = <Button icon positive loading={this.state.loading} disabled={!this.state.selectedProfile.profileId || this.state.loading} onClick={this.handleNewUser} size="mini" labelPosition='right'>New User<Icon name='add' /></Button>

    return (
      <Grid className="height-100 gs-flex-container-vertical">
        { this.props.operators &&
        <Grid.Row>
          <Grid.Column width="16">
            <Filters
              onOperatorSelect={this.handleOperatorSelect}
              operators={this.props.operators}
              profiles={[]}
              loading={this.state.loading}
              onSearchChange={this.handleSearchChange}
              onSearchClick={this.handleSearch}
              searchDisabled={!this.state.selectedOperator}
              append={this.props.logIn.optionAllowNewUser && append}
            />
          </Grid.Column>
        </Grid.Row>
        }
        <Grid.Row className="flex-stretch">
          <Grid.Column width="3" className="gs-flex-container-vertical">
            <div className="table-overflow flex-stretch-vertical" id="usersList">
              <List className="m-1">
                { this.state.profiles.map(profile => {
                  const css = this.state.selectedProfile.profileId === profile.id && !this.state.activeUser.profileData.length ? "clickable active" : "clickable";
                  return (<List.Item>
                    <List.Header className={css} onClick={() => this.handleProfileClick(profile.id)}>{profile.name}</List.Header>
                    <List.List>
                        {profile.children.map(child => {
                          return <List.Item key={`profile_children_${child.id}`} className="clickable m-1" active={this.state.activeUser.id === child.id} onClick={() => this.handleClick(child)}><Icon name="user" /> {child.name}</List.Item>
                        })}
                    </List.List>
                </List.Item>)
                })}
              </List>
            </div>
          </Grid.Column>
         <OptionsModal open={this.state.modalOpen} options={this.state.modalOptions} title={this.state.modalTitle} onClose={() => this.setState({ modalOpen: false })} onSave={this.saveNewUser} />
         { this.state.activeUser.profileData.length > 0 &&
          <Grid.Column width="13" className="gs-flex-container-vertical">
            <Header as='h2'>
              <Icon name='user' />
              <Header.Content>
                {this.state.activeUser.displayName}
                <Header.Subheader>{this.state.selectedProfile.profileName}</Header.Subheader>
              </Header.Content>
            </Header>
            <Tab
              panes={panes}
              activeIndex={this.state.activeIndex}
              onTabChange={(e, { activeIndex }) => this.setState({ activeIndex }) }
              className="height-100 gs-flex-container-vertical"
            />
          </Grid.Column>
          }
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
	return {
    operators: state.operators,
    logIn: state.logIn
  }
}

export default connect(mapStateToProps, { showSuccessAlert })(UserAccounts);