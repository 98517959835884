import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

class SystemTray extends React.Component {

  render() {
    return (
      <div id="system-tray">
        <Icon name="user" color="grey" /> { this.props.logIn.name }
        <span className="float-right">
        <Icon name="building" color="grey" /> { this.props.logIn.companyName}
          <span className="version">Version { this.props.logIn.version }</span>
        </span>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    logIn: state.logIn,
  };
};

export default connect(mapStateToProps, null)(SystemTray);
