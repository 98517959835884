import React from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import SelectOperator from '../shared/SelectOperator';
import SelectProfile from './SelectProfile';



class Filters extends React.Component {

  render() {
    return (
        <Form>
            <Form.Group inline>
                { this.props.operators.length > 0 && 
                <Form.Field inline style={{ marginRight: "2em"}}>
                    <label>Select Operator:</label>
                    <SelectOperator className="min-width" operators={this.props.operators} value={this.props.selectedOperator} onChange={this.props.onOperatorSelect} />
                </Form.Field>
                }

                { (this.props.profiles && this.props.profiles.length > 0) &&
                <Form.Field>
                    <label>Select Profile:</label>
                    <SelectProfile className="min-width" profiles={this.props.profiles} value={this.props.selectedProfile} onChange={this.props.onProfileSelect} />
                </Form.Field>
                }

                { this.props.onSearchClick &&
                <Form.Field inline>
                    <Input className="min-width" onChange={this.props.onSearchChange} /> 
                </Form.Field>
                }

                { this.props.onSearchClick &&
                <Button color="blue" onClick={this.props.onSearchClick} size="mini" disabled={this.props.searchDisabled || this.props.loading} loading={this.props.loading || false}>Search</Button>
                }

                { this.props.append }
            </Form.Group>
        </Form>
    );
  }
};

export default Filters;
