import React from 'react';
import { connect } from 'react-redux';
import { Grid, Select } from 'semantic-ui-react';
import { checkForUpdates, emptyCommsViewerSection, fetchCommsViewer, fetchOperationalCommsViewer, fetchCommsViewerFilters, getFilterReports, getPeriodFilterOptions, setAutorise, updateCommsViewer } from '../../actions';
import { prepareFilter } from '../../actions/organisationDirectory';
import CommsViewFilters from '../commsViewer/CommsViewFilters';
import CommsViewerEventsModal from '../modals/CommsViewerEventsModal';
import DataGridRequest from '../shared/DataGridRequest';
import RefreshSlider from '../shared/RefreshSlider';

class CommsViewerView extends React.Component {
  state = {
    companyInfo: {
      company: {},
      subCompany: {},
      site: {}
    },
    searchParams: {
      companyId: -1,
      subCompanyId: -1,
      siteId: -1,
      onlineEFTEnabled: false,
      offlineSiteOpen: true,   
      pending: false,
      authReq: true,
      offlineSiteClosed: false,
      onlineEFTDisabled: false,
      onlineNoFunds: false
    },
    selectedReport: 1,
    selectedPeriod: 1,
    authorise: false,
    loading: false,
    timerId: 0,
    currentInstallation: { installationId: null },
    modalOpen: false,
    reports: [],
    periods: [],
    currentCommsStatus: true
  };

  handleCommsViewSelection = term => {
    const authorise = term.optionAllowAuthorise;
    const modalOpen = term.optionShowPopup;
    this.setState({
      currentInstallation: term,
      authorise,
      modalOpen
    });
  }

  handleAuthorise = async () => {
    await this.setState({loading: true })
    await this.props.setAutorise(this.state.currentInstallation.installationId).then(
      async () => {
        await this.handleRefresh();
        this.setState({ loading: false })
      },
      () => this.setState({ loading: false })
    )
  }

  handleChange = (key, value) => {
    let params = {...this.state.searchParams};
    params[key]=value;
    this.setState({searchParams: params});
  }

  handleRefresh = () => {
    const callback = () => this.setState({ loading: false });

    this.setState({loading: true }, () => {
      const searchParams = this.state.searchParams
      const { operator, company, subcompany, site } = this.props.filters.filterSelected
      const { filterSelected, filterData } = this.props.filters

      if (this.state.currentCommsStatus) {
        this.props.fetchCommsViewer(
          searchParams.startDate,
          searchParams.endDate,
          operator,
          company.id,
          subcompany.id,
          site.id,
          prepareFilter(filterSelected.regions, filterData.regions),
          prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
          prepareFilter(filterSelected.game, filterData.game),
          prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
          searchParams.onlineEFTEnabled,
          searchParams.offlineSiteOpen,
          searchParams.pending,
          searchParams.authReq,
          searchParams.offlineSiteClosed,
          searchParams.onlineEFTDisabled,
          searchParams.onlineNoFunds
        )
        .then(callback, callback)
      } else {
        this.props.fetchOperationalCommsViewer(
          operator,
          company.id,
          subcompany.id,
          site.id,
          prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
          prepareFilter(filterSelected.game, filterData.game),
          prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
          this.state.selectedPeriod,
          this.state.selectedReport
        )
        .then(callback, callback)
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (prevProps.filters.operatorOptions !== this.props.filters.operatorOptions) {
      this.setState({ currentCommsStatus: true });
    }

    const { operator } = this.props.filters.filterSelected;
   
    if (operator && operator !== prevProps.filters.filterSelected.operator) {
      getPeriodFilterOptions().then(periods => {
        getFilterReports(operator).then(reports => {
          this.setState({ periods, reports })
        })
      })
    }
  
    if (checkForUpdates(prevProps.filters, this.props.filters)) {    
      this.handleRefresh();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  componentDidMount() {

  }

  handlePeriodChange = (event, { value }) => {
    this.setState({ selectedPeriod: value })
  }

  handleReportChange = (event, { value }) => {
    this.setState({ selectedReport: value })
  }

  handleViewChange = (event, { value }) => {
    this.props.emptyCommsViewerSection(false).then(() => this.setState({ currentCommsStatus: value }))
  }

  render() {

    const viewOptions = [
      { key: "Current Comms Status", value: true, text: "Current Comms Status" },
      { key: "Operational Filters", value: false, text: "Operational Filters" }
    ]

    return (
      <Grid relaxed className="height-100 gs-flex-container-vertical">
        <Grid.Row>
          <Grid.Column width="11">
            {this.props.filters.operatorOptions.useOperationalFilters && 
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Select options={viewOptions} value={this.state.currentCommsStatus} className="mb-1" onChange={this.handleViewChange} /> <RefreshSlider refresh={this.handleRefresh} disabled={!this.props.filters.hasSelection} loading={this.state.loading} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            }
            <CommsViewFilters
              filters={this.props.commsViewer} {...this.state.searchParams}
              authorise={this.state.authorise}
              onChange={this.handleChange}
              handleAuthorise={this.handleAuthorise}
              loading={this.state.loading}
              currentCommsStatus={this.state.currentCommsStatus}
              periods={this.state.periods}
              reports={this.state.reports}
              selectedPeriod={this.state.selectedPeriod}
              selectedReport={this.state.selectedReport}
              handlePeriodChange={this.handlePeriodChange}
              handleReportChange={this.handleReportChange}
              refreshButton={!this.props.filters.operatorOptions.useOperationalFilters && <span className="mr-1"><RefreshSlider refresh={this.handleRefresh} disabled={!this.props.filters.hasSelection} loading={this.state.loading} /></span> }
            />
            
            

          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch">
          <Grid.Column className="gs-flex-container-vertical">
            <DataGridRequest
              loading={this.state.loading}
              headings={this.props.commsViewer.grid.headerDef}
              onSort={this.props.updateCommsViewer}
              rowDef={this.props.commsViewer.grid.rowDef}
              data={this.props.commsViewer.grid.data}
              onRowClick={this.handleCommsViewSelection}
              fullHeight
            />
            <CommsViewerEventsModal 
              periods={this.state.periods}
              selectedPeriod={this.state.selectedPeriod}              
              installation={this.state.currentInstallation}
              handleClose={() => this.setState({ modalOpen: false})}
              modalOpen={this.state.modalOpen} modalTitle="Live Floor View - Details"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = state => {
	return {
    filters: state.commsViewerFilters,
    commsViewer: state.commsViewer,
    authorise: state.authorise,
    orgTreeSettings: state.orgTreeSettings
  };
};

export default connect(mapStateToProps, {
  fetchCommsViewer,
  fetchOperationalCommsViewer,
  setAutorise,
  fetchCommsViewerFilters,
  emptyCommsViewerSection,
  updateCommsViewer
})(CommsViewerView);
