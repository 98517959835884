import React from 'react';
import { Checkbox } from 'semantic-ui-react'
import InfoLabel from '../shared/InfoLabel';

class FilterStatus extends React.Component {

  render() {
    return (
      <div>
          <Checkbox name={this.props.name} checked={this.props.checked} onChange={this.props.onChange} style={{ position: "relative", top: "2px", marginRight: "0.3em"}}/>
          <InfoLabel right collapsing style={{ marginRight: "0.5em"}}>{this.props.value || 0 }</InfoLabel>
          <span key={this.props.color} style={{ minWidth: "1.4em", borderRadius: "0.2em", display: "inline-block", background: this.props.color }}>&nbsp;</span>
          <label style={{ position: "relative", "top": "1px", marginLeft: "0.2em"}}>{this.props.label}</label>
      </div>
    )
  }
}

export default FilterStatus
