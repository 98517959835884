import React from 'react';
import {connect} from 'react-redux';
import { Header, Button, Input, Form, Grid, Message } from 'semantic-ui-react';
import { resetPassword } from '../../actions';
import { toggleJira } from '../helpers/jira'

class Forgotten extends React.Component {

  state = {
    userEmail: '',
    result: false,
    showMessage: false,
    loading: false
  }

  resetPassword = () => {
    this.setState({ loading: true }, () => {
      this.props.resetPassword(this.state.userEmail).then(result => {
        this.setState({ showMessage: true, result, loading: false})
      },
      () => this.setState({ loading: false})
      );
    })
  }

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }
    
  handleReturnToLogin = () => {
    this.props.history.push("/");
  }

  componentDidMount() {
    toggleJira('0')
  }

  render() {
    //Check for login
    return (
        <Grid centered>
          <Grid.Row>
            <Grid.Column widescreen="4" computer="6" largeScreen="5" tablet="7" textAlign='center'>
              <img src='logo.png' alt="Rimini Logo" id="logo" />
              <Header as='h2'>Request Password Reset</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column widescreen="3" computer="5" largeScreen="4" tablet="6">
                <Form id="login">
                  
                  { this.state.showMessage && 
                    <div className="mb-1">
                    { !this.state.result ?
                      <Message negative><Message.Header>Reset Error</Message.Header>Unable to reset your password, please try again.</Message>
                    :
                      <Message positive><Message.Header>Reset Successful</Message.Header>Password reset successful, please check your email address for further instructions.</Message>
                    }
                    </div> 
                  }

                  <Form.Field>
                    <Input icon="mail" iconPosition="left" placeholder="Email address" name="userEmail" onChange={this.handleChange} value={this.state.userEmail} />
                  </Form.Field>
                <div><Button primary onClick={this.resetPassword} disabled={this.state.loading} size='large'>Request Reset</Button> <Button onClick={this.handleReturnToLogin} size='large'>Back</Button></div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
  }
}


export default connect(null, {
  resetPassword
})(Forgotten);
