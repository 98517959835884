import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Panes from './views/Panes';
import Login from './auth/Login';
import Forgotten from './auth/Forgotten';
import Reset from './auth/Reset';

const App = () => {
	return (
		<BrowserRouter>
				<Switch>
					<Route path="/forgotten" component={Forgotten} />
					<Route path="/reset" component={Reset} />
					<Route path="/dashboard" component={Panes} /> 
					<Route exact path="/" component={Login} />
				</Switch>
		</BrowserRouter>
	);
}

export default App;
