import React from 'react';
import {connect} from 'react-redux';
import { Button, Modal, Header } from 'semantic-ui-react'
import { confirmModal } from '../../actions/modals';

class ConfirmModal extends React.Component {

  hide = () => {
    this.props.confirmModal("", false, () => {} )
  }

  render() {
    const { visible, message, onConfirm } = this.props.data;
    return (
        <Modal
          open={visible}
          onClose={this.hide}
          closeIcon
          size="tiny"
        >
          <Header>{this.props.header || "Confirmation"}</Header>
          <Modal.Content>
            {message}
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.hide}
              negative
            >
              Cancel
            </Button>

            <Button
              onClick={() => { this.hide(); onConfirm() } }
              positive
            >
              Continue
            </Button>
          </Modal.Actions>  
        </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    data: state.confirmModal
  };
};

export default connect(mapStateToProps, { confirmModal })(ConfirmModal);

