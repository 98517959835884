import React from 'react';
import { TextArea, Dropdown, Checkbox, Label, Input, Form } from 'semantic-ui-react';
import { DateTimeInput, DateInput } from 'semantic-ui-calendar-react';
import { getBackgroundColour, getTextColour } from '../helpers/colours';

class Option extends React.Component {

  state = {
    invalid: false
  }

  handleListChange = (value) => {
    const selection = this.props.option.data.find(x => x.id === value)
    const option = {...this.props.option, defaultList: {...selection} };
    this.props.onChange(option, value)
  }

  handleTextChange = (value) => {

    let update = true;

    if (this.props.option.entryPattern && value.length > 0) {
      const { allowedCharacterRegex, appends } = this.props.option.entryPattern
      const regex = new RegExp(allowedCharacterRegex)
      const lastCharacter = value.slice(-1)

      if (Option.getValue(this.props.option).length < value.length) {
        update = regex.test(lastCharacter) || lastCharacter === "-"
        const appendedCharacter = appends.find(x => x.position === value.length)
        if (appendedCharacter) {
          value += appendedCharacter.text;
        }
      }
    }

    if (update) {
      if (this.props.option.regex && value.length) {
        const regex = new RegExp(this.props.option.regex)
        this.setState({ invalid: !regex.test(value) })
      } else {
        this.setState({ invalid: false })
      }
      
      this.props.onChange({...this.props.option, defaultText: value }, value);
    } 
  }

  handleCheckboxChange = (option) => {
    const value = option.defaultText.toLowerCase() === "true" ? "false" : "true"
    this.props.onChange({...option, defaultText: value}, value)
  }

  static getValue = (option) => {
    switch (option.type) {

      case 1: // List (tested)
        return option.defaultList ? option.defaultList.id : ""
   
      case 3:
      case 5:
        return option.defaultDate || "";

      default:
        return option.defaultText || ""
    }
  }

  renderError = () => {
    return (
     this.state.invalid && <small class="optionError">Invalid {this.props.option.text}</small> 
    )
  }
  render() {

    const { option } = this.props;
    const error = this.state.invalid;

    switch (option.type) {

      case 1: // List (tested)
        const options = option.data.length ? option.data.map((option, index) => { return { ...option, value: option.id, key: `${option.text} ${index}` } }) : []
        return <Dropdown
          selection
          options={options}
          value={Option.getValue(option)}
          onChange={(event, {value}) => this.handleListChange(value)}
          onClick={() => this.props.setFocus(option.code)}
          disabled={!option.isEditable}
          fluid
        />

      case 2: // Text (test)
      case 6: // Money
      case 7: // Int
        return (
          <Form.Field>
            <Input 
              fluid
              ref={input => (input && this.props.focus && Option.getValue(option).length < 1) && input.focus() }
              value={Option.getValue(option)}
              onClick={() => this.props.setFocus(option.code)}
              onChange={(event, {value}) => this.handleTextChange(value)}
              disabled={!option.isEditable}
              error={error}
              maxLength={option.maxLength || null }
            />
            { this.renderError() }
          </Form.Field>
        )
      
      case 3: // Date
      
        return (
          <Form.Field>
            <DateInput
              className="ui input"
              name="startDate"
              fluid
              value={Option.getValue(option)}
              iconPosition="left"
              onChange={(event, {value}) => this.handleTextChange(value)}
              disabled={!option.isEditable}
              animation="fade"
              popupPosition="bottom center"
              closable
              autoComplete="off"
              error={error}
            />
            { this.renderError() }
          </Form.Field>
        )

      case 4: // Multi
        return <h1>Multi</h1>

      case 5: // Datetime
        return (
          <Form.Field>
            <DateTimeInput
              className="ui input"
              name="startDate"
              fluid
              value={Option.getValue(option)}
              iconPosition="left"
              onChange={(event, {value}) => this.handleTextChange(value)}
              disabled={!option.isEditable}
              animation="fade"
              popupPosition="bottom center"
              closable
              autoComplete="off"
              error={error}
            />
            { this.renderError() }
         </Form.Field>
        )
        
      case 8: // switch
        return <Checkbox toggle checked={Option.getValue(option).toLowerCase() === "true"} onChange={() => this.handleCheckboxChange(option)} disabled={!option.isEditable} />

      case 9:
        const background = getBackgroundColour(option)
        const color = getTextColour(option);
        return background !== "transparent" ? <Label style={{background, color, float: "right", "min-width": "90px", "text-align": "center" }}>{option.defaultText}</Label> : <span>{option.defaultText}</span>

      case 10: // checkbox
        return <Checkbox checked={Option.getValue(option).toLowerCase() === "true"} onChange={() => this.handleCheckboxChange(option)} disabled={!option.isEditable} />

      case 11:
        return <Form.Field><TextArea onChange={(event, {value}) => this.handleTextChange(value)} className="w-100" disabled={!option.isEditable} fluid rows={4} value={option.defaultText} /></Form.Field>

      default:
        return <span>{option.defaultText}</span>

    }
   
  }
};

export default Option;
