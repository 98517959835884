import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Header, Icon, Menu } from 'semantic-ui-react';
import { fetchOperatorProfiles, fetchProfile, newProfile, updateSettingsProfile } from '../../actions/index';
import { showSuccessAlert } from '../../actions/modals';
import BaseSettings from '../systemAdmin/BaseSettings';
import Filters from './Filters';
import OptionsList from './OptionsList';

class SystemSettings extends BaseSettings {

  constructor() {
    super()
    this.state = {
      ...this.state, // spread in parent's state
      selectedProfile: {profileId : null, subTypes: [], profileData: [], baseProfileId: 0 },
      profileModalOpen: false,
      profiles: [],
      defaultProfile: false,
      loading: false,
      selectedOperator: null,
    };
  }

  getSelectedOptions = () => {
    const subType = this.state.selectedProfile.subTypes.find(x => x.id === this.state.activeMenu.id)
    return this.mapChanged(subType.profileData);
  }

  componentDidMount() {
    const { operators } = this.props;
    if (operators.length === 1) {
      this.fetchProfiles(operators[0].value);
    }
  }

  handleOperatorSelect = (event, data) => {
    this.fetchProfiles(data.value)
  }

  handleProfileSelect = async (event, data) => {
    const selected = this.state.profiles.find(profile => profile.value === data.value);
    const callback = () => this.setState({ loading: false })
    await this.setState({ loading: true });
    await this.updateProfile(this.state.selectedOperator, selected.value).then(
      callback,
      callback
    )

  }

  fetchProfiles = (selectedOperator) => {
    fetchOperatorProfiles(selectedOperator).then(profiles => {
      fetchProfile(selectedOperator, -1).then(selectedProfile => {
          const item = selectedProfile.subTypes[0];
          this.setState({selectedOperator, profiles, selectedProfile, defaultProfile: selectedProfile.isDefault, activeMenu: item });
      });
    });
  }


  updateProfile = async (operator, profile, defaultTab = true) => {
    await fetchProfile(operator, profile).then(async selectedProfile => {
      const activeMenu = defaultTab ? selectedProfile.subTypes[0] : this.state.activeMenu;
      await this.setState({selectedProfile, defaultProfile: selectedProfile.isDefault, changed: [], activeMenu})
    });
  }

  handleNewProfile = async (profileName) => {
    const { selectedOperator } = this.state
    newProfile(this.state.selectedProfile.profileId, profileName).then(selectedProfile => {
      fetchOperatorProfiles(selectedOperator).then(profiles => {
        this.setState(
          {profileModalOpen: false, profiles }, 
          () => {
            this.updateProfile(selectedOperator, selectedProfile.profileId);
            this.props.showSuccessAlert(`New profile successfully created.`)
          });
      })
    })
  }

  handleDefaultChange = () => {
    this.setState({ defaultProfile: !this.state.defaultProfile })
  }


  handleProfileNameChange = (event, { value }) => {
    this.setState({ selectedProfile: {...this.state.selectedProfile, ...{profileName: value }} })
  }
  
  handleSave = () => {

    const changed = this.getChanged();

    updateSettingsProfile(this.state.selectedOperator, this.state.selectedProfile.profileId, this.state.selectedProfile.baseProfileId, changed).then(id => {
      this.setState({changed: [] }, () => {
        const profileId = this.state.selectedProfile.profileId === 0 ? id : this.state.selectedProfile.profileId;
        fetchOperatorProfiles(this.state.selectedOperator).then(profiles => {
          this.setState({profiles}, () => {
            this.updateProfile(this.state.selectedOperator, profileId, false).then(() => this.props.showSuccessAlert(`Your changes were saved successfully.`))
          })
        })
      })
    })
  }

  onNewProfileClick = () => {
    newProfile(this.state.selectedOperator, this.state.selectedProfile.profileId).then(result => {
      const selectedProfile = {...result, baseProfileId: this.state.selectedProfile.profileId }
      const activeMenu = selectedProfile.subTypes[0];
      this.setState({selectedProfile, defaultProfile: selectedProfile.isDefault, changed: [], activeMenu})
    });
  }

  render() {

    const menuItems = this.getMenuItems(this.state.selectedProfile.subTypes);

    return (
      <div className="settings height-100 gs-flex-container-vertical">
        <Grid className="height-100 gs-flex-container-vertical">
          <Grid.Row>
            <Grid.Column width="16">
              <Filters
                onOperatorSelect={this.handleOperatorSelect}
                onProfileSelect={this.handleProfileSelect}
                selectedProfile={this.state.selectedProfile.profileId}
                profiles={this.state.profiles}
                operators={this.props.operators}
              />
            </Grid.Column>
          </Grid.Row>
          { this.state.selectedProfile.profileId !== null &&
          <React.Fragment>
            <Grid.Row className="flex-stretch">
              <Grid.Column largeScreen="3" widescreen="2" computer="4" className="gs-flex-container-vertical">
                <div className="table-overflow flex-stretch-vertical p-1">
                  <Menu secondary vertical fluid>
                    {menuItems}
                  </Menu>
                </div>
              </Grid.Column>
              <Grid.Column largeScreen="13" widescreen="14" computer="12" className="gs-flex-container-vertical">
                <div className="table-overflow flex-stretch-vertical p-1">
                  <Header>{ this.state.selectedProfile.profileId === 0 && "New Profile - " }{this.state.activeMenu.name}</Header>
                  <OptionsList options={this.getSelectedOptions()} onChange={this.handleChangeValue} />     
                </div>
              </Grid.Column>         
            </Grid.Row>
            <Grid.Row>
              <Grid.Column largeScreen="3" widescreen="2" computer="4">
                { this.props.logIn.optionAllowNewSystemSettingProfile && <Button icon positive labelPosition='right' disabled={this.state.selectedProfile.profileId === 0 || this.state.loading} loading={this.state.loading} onClick={this.onNewProfileClick}>
                  New Profile
                  <Icon name='add' />
                </Button> }
              </Grid.Column>
              <Grid.Column largeScreen="13" widescreen="14" computer="12">
                { this.props.logIn.optionAllowSaveSystemSettingProfile && <Button icon positive labelPosition='right' disabled={!this.isValid() || this.state.loading} loading={this.state.loading} onClick={this.handleSave}>
                  Save Changes
                  <Icon name='save' />
                </Button> }
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
          }
        </Grid>
      </div>
    );
  }
};


const mapStateToProps = (state, ownProps) => {
	return {
    operators: state.operators.filter(x => x.value !== -1),
    logIn: state.logIn
  }
}

export default connect(mapStateToProps, { showSuccessAlert })(SystemSettings);
