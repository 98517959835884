import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Icon, Input } from 'semantic-ui-react';
import { fetchOpeningHoursProfile, fetchOpeningHoursProfiles, updateOpeningHours } from '../../actions/index';
import { showSuccessAlert } from '../../actions/modals';
import OpeningHoursModal from '../modals/OpeningHoursModal';
import DataGridRequest from '../shared/DataGridRequest';
import Filters from './Filters';

class OpeningHours extends React.Component {

  state = {
    profiles: [],
    loading: false,
    selectedOperator: null,
    selectedProfile: { profileName: "", grid: { data: [], headerDef: [] } },
    defaultProfile: null,
    modalOpen: false,
    hasChanges: false
  };

  componentDidMount() {
    const { operators } = this.props;
    if (operators.length === 1) {
      this.fetchProfiles(operators[0].value);
    } 
  }

  handleOperatorSelect = (event, data) => {
    this.fetchProfiles(data.value)
  }

  handleProfileSelect = (event, data) => {
    const { selectedOperator } = this.state;
    this.setState({ loading: true }, () => {
      fetchOpeningHoursProfile(selectedOperator, data.value).then(
        selectedProfile => {
          this.setState({ selectedProfile, loading: false });
        },
        () => this.setState({ loading: false })
      );
    })

  }

  fetchProfiles = async (selectedOperator) => {
    const callback = () => this.setState({ loading: false})
    await this.setState({ loading: true })
    await fetchOpeningHoursProfiles(selectedOperator).then(
      async profiles => {
        await fetchOpeningHoursProfile(selectedOperator, -1).then(
          selectedProfile => {
            this.setState({selectedOperator, profiles, loading: false, selectedProfile });
          },
          callback
        );
      },
      callback
    );
  }

  handleCellClick = (data) => {
    data.actualValue = data.actualValue === "0" ? "1" : "0";
    data.backColour = data.actualValue === "0" ? this.state.selectedProfile.closeColour : this.state.selectedProfile.openColour; 
    
    this.setState({ hasChanges: true, selectedProfile: {...this.state.selectedProfile}})
  }

  handleSave = () => {
    const items = [];
    this.state.selectedProfile.grid.data.forEach(row => {
      row.cellData.forEach((cell, index) => {
        if (index > 0) items.push(cell.data.actualValue);
      })
    })

    updateOpeningHours(this.state.selectedOperator, this.state.selectedProfile.profileId, this.state.selectedProfile.profileName, items.join(";")).then(x => {
      this.setState({ hasChanges: false}, () => this.props.showSuccessAlert(`Opening hours saved successfully!`))
    })
  }

  updateProfileName = (event, {value}) => {
    const selectedProfile = {...this.state.selectedProfile, profileName: value}
    this.setState({selectedProfile, hasChanges: true})
  }

  openNewModal = () => {
      this.setState({ modalOpen: true })  
    
  }    

  onNewProfile = () => {
    this.fetchProfiles(this.state.selectedOperator).then(() => this.setState({ modalOpen: false}, () => this.props.showSuccessAlert(`New profile successfully created!`)))
  }

  render() {
    return (
      <div className="settings">
        <OpeningHoursModal selectedOperator={this.state.selectedOperator} onNewProfile={this.onNewProfile} modalOpen={this.state.modalOpen} handleClose={() => this.setState({ modalOpen: false })} />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Filters
                onOperatorSelect={this.handleOperatorSelect}
                onProfileSelect={this.handleProfileSelect}
                selectedProfile={this.state.selectedProfile.profileId}
                profiles={this.state.profiles}
                operators={this.props.operators}
              />
            </Grid.Column>
          </Grid.Row>
          { this.state.selectedProfile.grid.data.length > 0 && 
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Field inline>
                  <label>Profile Name:</label>
                  <Input style={{ "min-width": "250px"}} value={this.state.selectedProfile.profileName} onChange={this.updateProfileName} />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
          }
          { this.state.selectedProfile.grid.data.length > 0 && 
          <Grid.Row>
            <Grid.Column>
              
              { this.state.selectedProfile.grid.data.length > 0 && 
                <DataGridRequest
                  activeRow={null}
                  headings={this.state.selectedProfile.grid.headerDef}
                  data={this.state.selectedProfile.grid.data}
                  rowDef={this.state.selectedProfile.grid.rowDef}
                  onCellClick={this.handleCellClick}
                  loading={this.state.loading}
                  disableActiveRow={true}
                  disableSort={true}
                />
              }
            </Grid.Column>
          </Grid.Row>
          }
          <Grid.Row>
            <Grid.Column width="3">
              { this.props.logIn.optionAllowNewOpeningHoursProfile && <Button icon positive labelPosition='right' loading={this.state.loading} disabled={!this.state.selectedOperator || this.state.loading} onClick={this.openNewModal}>
                  New Profile
                  <Icon name='add' />
                </Button> }
            </Grid.Column>
            <Grid.Column width="13" textAlign="right">
              { this.props.logIn.optionAllowSaveOpeningHoursProfile && <Button icon positive loading={this.state.loading} disabled={!this.state.hasChanges || this.state.loading} onClick={this.handleSave} labelPosition='right'>
                Save Changes
                <Icon name='save' />
              </Button> }
            </Grid.Column>
          </Grid.Row>          
        </Grid>
      </div>
    );
  }
};



const mapStateToProps = (state, ownProps) => {
	return {
    operators: state.operators.filter(x => x.value !== -1),
    logIn: state.logIn
  }
}

export default connect(mapStateToProps, { showSuccessAlert })(OpeningHours);
