import React from 'react';
import { Button, Checkbox, Grid, Header, Label, Modal, Select } from 'semantic-ui-react';
import { getCommsViewerEvents, getCommsViewerSession, getCommsViewerSessions } from '../../actions';
import { getColour } from '../helpers/colours';
import DataGridRequest from '../shared/DataGridRequest';

class CommsViewerEventsModal extends React.Component {
  
  initialState = {
    eventsLoading: false,
    sessionsLoading: false,
    selectedSessionLoading: false,
    showCashSessions: false,
    events: {
      grid: {
        headerDef: [],
        rowDef: [],
        data: []
      }
    },
    sessions: {
      grid: {
        headerDef: [],
        rowDef: [],
        data: []
      }
    },
    selectedSession: {
      grid: {
        headerDef: [],
        rowDef: [],
        data: []
      }
    },
    selectedPeriod: 1,
    error: false
  }

  state = {...this.initialState};
  
  resetState = async () => {
    await this.setState({...this.initialState})
  }

  

  getEvents = async () => {
    await this.setState( { eventsLoading: true })
    await getCommsViewerEvents(this.props.installation.installationId, this.state.selectedPeriod)
    .then(events => {
      this.setState({ events, eventsLoading: false })
    })
    .catch(() => this.setState({ eventsLoading: false })) 
  }

  getSessions = async () => {
    await this.setState( { sessionsLoading: true })
    await getCommsViewerSessions(this.props.installation.installationId, this.state.selectedPeriod, this.state.showCashSessions)
    .then((sessions) => {
      this.setState({ sessions,  sessionsLoading: false})
    })
    .catch(() => this.setState({ sessionsLoading: false }))
  }


  handleRefresh = async () => {
    this.setState({ selectedSession: {...this.initialState.selectedSession} }, () => {
      this.getSessions();
      this.getEvents();
    })
  }

  handlePeriodChange = (event, { value }) => {
    this.setState({ selectedPeriod: value })
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (nextProps.modalOpen && !this.props.modalOpen) {
      this.resetState().then(() => {
        this.handleRefresh()
      })
    }
  }

  handleSessionSelect = session => {
    this.setState( { selectedSessionLoading: true }, () => {
      getCommsViewerSession(session.sessionId)
      .then(result => {
        const selectedSession = { grid: result }
        this.setState({ selectedSession, selectedSessionLoading: false })
      })
      .catch(() => this.setState( { selectedSessionLoading: false }))
    })
  }

  render() {
    const stateColour = getColour(this.props.installation, "commsStateColour", "#CCC");
    return (
        <Modal
            open={this.props.modalOpen}
            onClose={this.props.handleClose}
            size='fullscreen'
            closeIcon
            className="gs-flex-container-vertical h-80"
        >
            <Header>
              <span className="mr-2">{this.props.modalTitle}</span>
              <div style={{ display: "inline", fontSize: "0.9em"}}>
                <span className="ml-2 mr-2">{this.props.installation.siteName}</span>
                <span className="ml-2 mr-2">{this.props.installation.gameName}, {this.props.installation.manufacturer}</span>
                <span className="ml-2">EGM Asset No: </span> {this.props.installation.assetNo}
              </div>
            </Header>

            <Modal.Content className="height-100 gs-flex-container-vertical">
              <Grid columns={2} className="height-100 gs-flex-container-vertical">
                <Grid.Row>
                  <Grid.Column width="12">
                    <Select options={this.props.periods} value={this.state.selectedPeriod} onChange={this.handlePeriodChange} className="mr-1" />
                    <Checkbox checked={this.state.showCashSessions} onClick={() => this.setState({ showCashSessions: !this.state.showCashSessions})} label="Show Cash Sessions" />
                    <Button size='small' onClick={this.handleRefresh} disabled={this.state.eventsLoading || this.state.sessionsLoading} positive className="ml-1">Refresh</Button>        
                  </Grid.Column>
                  <Grid.Column width="4" textAlign="right">
                    Current Status: <Label style={{ backgroundColor: stateColour, color: "#FFF", marginLeft: "4px"}}>{this.props.installation.commsStateText}</Label>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="flex-stretch">
                  <Grid.Column width="6" className="gs-flex-container-vertical">
                    <DataGridRequest
                      loading={this.state.eventsLoading}
                      fullHeight
                      headings={this.state.events.grid.headerDef}
                      rowDef={this.state.events.grid.rowDef}
                      data={this.state.events.grid.data}
                      onRowClick={() => {}}
                    />                  
                  </Grid.Column>
                  <Grid.Column width="10" className="gs-flex-container-vertical">
                    <div className="mb-1">
                      <DataGridRequest
                        loading={this.state.sessionsLoading}
                        fullHeight
                        headings={this.state.sessions.grid.headerDef}
                        rowDef={this.state.sessions.grid.rowDef}
                        data={this.state.sessions.grid.data}
                        onRowClick={this.handleSessionSelect}
                        
                      />
                    </div>

                      <DataGridRequest
                        loading={this.state.selectedSessionLoading}
                        fullHeight
                        headings={this.state.selectedSession.grid.headerDef}
                        rowDef={this.state.selectedSession.grid.rowDef}
                        data={this.state.selectedSession.grid.data}
                        onRowClick={() => {}}
                      />            
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
        </Modal>
    )
  }
}

export default CommsViewerEventsModal;
