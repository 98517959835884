import React from 'react';
import { Accordion, Button, Checkbox, Form, Icon, Input, List, Loader, Menu, Modal } from 'semantic-ui-react';
import { fetchOperatorOptions, fetchOperators, fetchOrganisations, fetchSearchTerms } from '../../actions';
import SelectOperator from './SelectOperator';
import { connect } from 'react-redux';

class OrganisationDirectory extends React.Component {

  state = {
    loading: false,
    buttonEnabled: false,
    allOrgsSelected: false,
  }

  showOrganisations = async () => {
    const organisations = await fetchOrganisations(this.props.data, this.props.showEmpty)
    await this.props.onChange(organisations)
  }

  handleSearchTextChange = (event, {name, value}) => {
    this.props.onChange({filterSelected: { searchText: value } })
  }


  componentDidMount(prevProps) {
    this.assignOperator();
  }


  componentDidUpdate(prevProps) {
    if (this.props.section !== prevProps.section && !this.props.data.filterSelected.operator) {
      this.assignOperator();
    }
  }

  assignOperator = () => {
    if (this.props.operators.length === 1) {
      this.handleOperatorChange(null, { value: this.props.operators[0].value })
    }
  }


  handleOperatorChange  = (event, data) => {
    this.setState( { buttonEnabled: false, operator: data.value }, async () => {
      
      const searchTerms = await fetchSearchTerms(data.value)

      const filterSelected = {
        ...this.props.data.filterSelected,
        company: {id : null },
        subcompany: {id : null },
        site: {id: null },
        searchText:'',
        operator: data.value,
        ...searchTerms
      }
      
      this.props.empty(false).then(() => {
        fetchOperatorOptions(data.value).then(operatorOptions => {
          this.setState( { buttonEnabled: true })
          this.props.onChange({ filterData: searchTerms, operatorOptions, organisations: [], hasSelection: false, filterSelected })
        });
      });
    })
  }

  handleCheckboxChange = (key, item) => {

    const selected = {...this.props.data.filterSelected}

    if (item.id === -1) {
      if (this.props.data.filterSelected[key].length < this.props.data.filterData[key].length - 1) {
        selected[key] = [...this.props.data.filterData[key]]
      } else {
        selected[key] = [];
      }
    } else {
      if (selected[key].includes(item)) {
        selected[key] = selected[key].filter(current => current !== item && current.id !== -1)
      } else {
        selected[key].push(item);

        if (selected[key].length === this.props.data.filterData[key].length - 1) {
          selected[key] = [...this.props.data.filterData[key]]
        }
      }
    }
    
    this.handleRefresh({filterSelected: selected});
  }

  renderLevel3(company, subcompany){
    const listItems = subcompany.sites.map(item => {
      return (
        <List.Item
        style={{"padding": '3px 20px'}}
        key={item.id}
        className={ this.isSelected(company, subcompany, item) ? "selected clickable" : "clickable"}
        onClick={() => { this.setSelection(company, subcompany, item) }}
        >
          {item.name}</List.Item>
      );
    });
    return (
      <List style={{ margin: 1, padding: 0}}>
        {listItems}
      </List>
    );
  }

  handleClick(id, key) {
    const orgs = this.props.data[key];
    var index = orgs.indexOf(id);

    if (index === -1) {
      orgs.push(id);
    } else {
      orgs.splice(index, 1);
    }

    this.props.onChange({[key]: orgs, activeLink: id})
  }

  renderLevel2(company) {

    const items = company.subCompanies;

    const panels = items.map(item => {
      const isActive = this.props.data.activeSubOrgs.includes(item.id);
      const hasItems = item.sites != null
      const iconName = isActive || !hasItems ? "minus square outline" : "plus square outline"
      return (
        <div key={item.id}>
          <Accordion.Title className={ this.isSelected(company, item, null) && "selected" } index={item.id}>
            <Icon name={iconName} onClick={() => this.handleClick(item.id, 'activeSubOrgs')} />
            <span onClick={() => { this.setSelection(company, item, null) } }>{item.name}</span>
          </Accordion.Title>
          <Accordion.Content active={isActive}>{this.renderLevel3(company, item)}</Accordion.Content>
        </div>
      )
    });
    return (
      <React.Fragment>
      <Accordion.Accordion exclusive={false} styled={false}>
        {panels}
      </Accordion.Accordion>
      </React.Fragment>
    )
  }

  panelsList = () => {
    return this.props.data.organisations.map(installation => {
      const isActive = this.props.data.activeOrgs.includes(installation.id);
      const hasItems = installation.subCompanys != null
      const iconName = isActive || !hasItems ? "minus square outline" : "plus square outline"
      return (
        <React.Fragment key={installation.id}>
          <Accordion.Title active={isActive} className={ this.isSelected(installation, null, null) && "selected" } index={installation.id} >
            <Icon name={iconName} onClick={() => this.handleClick(installation.id, 'activeOrgs')} />
            <span onClick={() => { this.setSelection(installation, null, null) } }>{installation.name}</span>
          </Accordion.Title>
          <Accordion.Content active={isActive}>
            {this.renderLevel2(installation)}
          </Accordion.Content>
      </React.Fragment>
      );
    });
  }

  toggleActiveFilter = (key) => {
    this.props.onChange({ filterActive: { [key]: !this.props.data.filterActive[key] } })
  }

  searchOptions = (title, key) => {
    if (this.props.data.filterSelected.operator) {
      return (
        <span>
          <Accordion.Title onClick={() => this.toggleActiveFilter(key)} active={this.props.data.filterActive[key]}>
            <Icon name='dropdown' />
            {title}
          </Accordion.Title>
          <Accordion.Content active={this.props.data.filterActive[key]} className="overflow">
          { this.props.data.filterData[key].map((item) => {
              return (<div><Checkbox label={item.name} inverted checked={this.props.data.filterSelected[key].includes(item)} onClick={() => { this.handleCheckboxChange(key, item) }} key={item.id} /></div>)
          }) }
          </Accordion.Content>
        </span>
      )
    }
  }

  handleRefresh = (data) => {
    this.setState({ loading: true }, () => {
      this.props.onChange(data).then(() => {
        this.showOrganisations().then(() => this.setState({ loading: false }))
      })
    })
  }

  toggleAllOrgs = () => {
    this.props.onChange({allOrgsActive: !this.props.data.allOrgsActive })
  }

  setSelection = (company, subCompany, site) => {

    if (site || this.props.extraFilters) {

      this.setState({allOrgsSelected: company === null && subCompany === null && site === null})

      let updatedCompany = company === null ? { id: null, name: "ALL", displayText: "ALL" } : company
      let updatedSubCompany = subCompany === null ? { id: null, name: "ALL", displayText: "ALL" } : subCompany
      let updatedSite = site === null ? { id: null, name: "ALL", displayText: "ALL" } : site

      const data = {company: updatedCompany, subcompany: updatedSubCompany, site: updatedSite}

      this.props.onChange({ hasSelection: true, filterSelected : {...data} })
    }
  }

  isSelected = (company, subCompany, site) => {
    
    const defaultCompany = company === null ?  { id: null } : company;
    const defaultSubCompany = subCompany === null ?  { id: null } : subCompany;
    const defaultSite = site === null ?  { id: null } : site;

    const companyMatches = this.props.data.filterSelected.company.id === defaultCompany.id
    const subCompanyMatches = this.props.data.filterSelected.subcompany.id === defaultSubCompany.id
    const siteMatches = this.props.data.filterSelected.site.id === defaultSite.id;

    return companyMatches && subCompanyMatches && siteMatches;
  }

  handleSearch = () => {
    this.setState( { loading: true }, () => {
      if (this.props.data.filterSelected.operator) {
          this.showOrganisations().then(() => this.setState({ loading: false }))
      } else {
        this.setState({modalOpen: true, loading: false})
      }
    });

  }

  render = () => {
    
    const { operators } = this.props;

    return (
    
    <span className="directory">
    { this.props.data.showOrgTree &&
            <Accordion as={Menu} fixed='left' inverted className="flex-container" style={{ width: "270px", marginLeft: `${this.props.marginLeft}px` }}>
              
            { this.props.hideable &&
              <Accordion.Title onClick={ () => this.props.onChange({showOrgTree: false }) }>
                <Icon name='chevron left' className="float-right" />
              </Accordion.Title> }


              <Accordion.Content active>

                  <Modal
                    open={this.state.modalOpen}
                    size="mini"
                  >
                  <Modal.Content>
                    <p>
                      You must select an operator before searching.
                    </p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color='green' onClick={() => this.setState( { modalOpen: false } ) }>Continue</Button>
                  </Modal.Actions>
                </Modal>

                <Form>
                  <Form.Field>
                    <SelectOperator 
                      value={this.props.data.filterSelected.operator}
                      onChange={this.handleOperatorChange}
                      operators={operators}
                    />
                  </Form.Field>
                  <Form.Group inline>
                  <Input name='searchText' inverted value={this.props.data.filterSelected.searchText} icon='search' placeholder='Search...' onChange={this.handleSearchTextChange} />
                  <Button primary onClick={this.handleSearch} disabled={this.state.loading || !this.state.buttonEnabled}>Search</Button>
                  </Form.Group>
                </Form>
              </Accordion.Content>
              
               <Accordion.Content className="flex-stretch overflow-companies" active>
                { this.state.loading ?
                      <Loader active inverted>Loading</Loader>
                :
                  this.props.allowAll ?
                    
                    this.props.data.organisations.length && 
                    <Accordion styled={false}>
                      <Accordion.Title className={ this.isSelected(null, null, null) && this.props.data.hasSelection && "selected" }  active={true} key="0">
                        <Icon name={ this.props.data.allOrgsActive ? "minus square outline" : "plus square outline" } onClick={this.toggleAllOrgs} />
                        <span onClick={() => { this.setSelection(null, null, null) } }>All</span>
                      </Accordion.Title>
                      <Accordion.Content active={this.props.data.allOrgsActive}>
                        <Accordion defaultActiveIndex={[0]} exclusive={false} panels={this.panelsList()} />
                      </Accordion.Content>
                    </Accordion>
                    :
                        <Accordion defaultActiveIndex={[0]} exclusive={false} panels={this.panelsList()} />
                }
              </Accordion.Content>

              { (this.props.includeSearchTerms) &&
                <div>
                  { this.props.data.filterData.regions.length > 1 && this.searchOptions("Operator Regions", "regions") }
                  { this.searchOptions("Manufacturer", "manufacturer") }
                  { this.searchOptions("EGM Cabinet Name", "game") }
                  { this.searchOptions("Category", "machinetypes") }
                </div>
              }

            </Accordion>
      }
      </span>
      
    );
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    operators: state.operators.filter(x => x.value !== -1)
  }
}

export default connect(mapStateToProps, { fetchOperators })(OrganisationDirectory);
