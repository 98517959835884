import { combineReducers } from 'redux';
import authoriseReducer from './authoriseReducer';
import collectionReducer from './collectionReducer';
import collectionsInstallationsReducer from './collectionsInstallationsReducer';
import collectionsReducer from './collectionsReducer';
import commsViewerReducer from './commsViewerReducer';
import confirmModalReducer from './confirmModalReducer';
import eftReducer from './eftReducer';
import {
	addPositionsReducer, installationReducer,
	setActiveInstallationReducer
} from './installationReducer';
import { logInReducer } from './logInOutReducer';
import machineReducer from './machineReducer';
import machineSaveReducer from './machineSaveReducer';
import operatorsReducer from './operatorsReducer';
import {
	collectionsFiltersReducer, commsViewerFiltersReducer,

	installationsFiltersReducer,

	sessionsFiltersReducer, settingsFiltersReducer, transactionsFiltersReducer
} from './organisationDirectoryReducer';
import organisationReducer from './organisationReducer';
import organisationsReducer from './organisationsReducer';
import passwordModalReducer from './passwordModalReducer';
import removeAssetReducer from './removeAssetReducer';
import searchTermReducer from './searchTermReducer';
import sessionReducer from './sessionReducer';
import sessionsByCompanyReducer from './sessionsByCompanyReducer';
import sessionsReducer from './sessionsReducer';
import successAlertReducer from './successAlertReducer';
import transactionReducer from './transactionReducer';
import transactionsByCompanyReducer from './transactionsByCompanyReducer';
import {
	transactionAdjustReducer,
	transactionAuthoriseReducer, transactionsReducer,


	transactionVoidReducer
} from './transactionsReducer';


 const appReducer = combineReducers({
	successAlert: successAlertReducer,
	passwordModal: passwordModalReducer,
	confirmModal: confirmModalReducer,
	collections: collectionsReducer,
	collectionsInstallations: collectionsInstallationsReducer,
	collection: collectionReducer,
	collectionsFilters: collectionsFiltersReducer,
	installationsFilters: installationsFiltersReducer,
	transactionsFilters: transactionsFiltersReducer,
	sessionsFilters: sessionsFiltersReducer,
	settingsFilters: settingsFiltersReducer,	
	commsViewerFilters: commsViewerFiltersReducer,
	organisations: organisationsReducer,
	organisation: organisationReducer,
	installations: installationReducer,
	positionsAdd: addPositionsReducer,
	transactions: transactionsReducer,
	operators: operatorsReducer,
	transactionAdjust: transactionAdjustReducer,
	transactionAuthorise: transactionAuthoriseReducer,
	transactionVoid: transactionVoidReducer,
	transactionsByCompany: transactionsByCompanyReducer,
	transaction: transactionReducer,
	sessions: sessionsReducer,
	sessionsByCompany: sessionsByCompanyReducer,
	session: sessionReducer,
	searchTerms: searchTermReducer,
	machine: machineReducer,
	machineSave: machineSaveReducer,
	eftUpdate: eftReducer,
	authorise: authoriseReducer,
	assetRemove: removeAssetReducer,
	logIn: logInReducer,
	commsViewer: commsViewerReducer,
	activeInstallation: setActiveInstallationReducer
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
	if (action.type === 'CLEAR_REDUX') {
		state = initialState;
	}

	return appReducer(state, action)
}

export default rootReducer;