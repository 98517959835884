import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Grid, Header, Icon } from 'semantic-ui-react';
import { fetchOperators, getAssets, loadAsset, loadCategory, loadGame, loadManufacturer, newAsset, newCategory, newGame, newManufacturer, showSuccessAlert, updateAsset, updateCategory, updateGame, updateManufacturer } from '../../actions/index';
import EGMFilters from '../egm/EGMFilters';
import EGMTree from '../egm/EGMTree';
import DataGridRequest from '../shared/DataGridRequest';
import BaseSettings from '../systemAdmin/BaseSettings';
import OptionsList from '../systemAdmin/OptionsList';

class EGMManagementView extends BaseSettings {

  constructor() {
    super()
    this.state = {
      ...this.state, // spread in parent's state
      searchParams: {
        selectedOperator: null,
        selectedGrouping: 0,
        selectedCategory: -1,
        searchText: "",
        selectedManufacturer: -1,
        selectedGameName: -1,
        selectedStatus: -1,
        selectedVariant: -1
      },
      selectedNode: { id: null, name: null, type: null },
      grid: { data: [], rowDef: [], headerDef: []},
      selectedOptions: [],
      assets: [],
      loading: false
    }
  }


  handleSelection = (name, value) => {
    this.setState({ searchParams: {...this.state.searchParams, [name]: value } })
  }

  componentDidMount = () => {
    if (this.props.operators.length === 1) {
      this.setState( { searchParams: {...this.state.searchParams, selectedOperator: this.props.operators[0].value }})
    }
  }

  handleRefresh = async () => {
    const { 
      selectedOperator,
      selectedGrouping,
      selectedCategory,
      searchText,
      selectedManufacturer,
      selectedGameName,
      selectedStatus,
      selectedVariant 
    } = this.state.searchParams;
    
    await this.setState({ loading: true })
    await getAssets(
      selectedOperator,
      selectedGameName,
      selectedManufacturer,
      selectedCategory,
      selectedStatus,
      selectedVariant,
      selectedGrouping,
      searchText
    ).then(
      assets => this.setState({ assets, loading: false }),
      () => this.setState({ loading: false })
    );
  }

  handleNew = (selectedOptions, selectedNode, grid = null) => {
    this.setState({ selectedOptions, changed: [], selectedNode, grid })
  }

  onNewManufacturerClick = () => {
    newManufacturer(this.state.searchParams.selectedOperator).then(selectedOptions => this.handleNew(selectedOptions, { id: 0, name: "New Manufacturer", type: "MU"} ))
  }

  onNewCategoryClick = () => {
    newCategory(this.state.searchParams.selectedOperator).then(selectedOptions => this.handleNew(selectedOptions, { id: 0, name: "New Category", type: "MT"} ))
  }

  onNewAssetClick = () => {
    newAsset(this.state.searchParams.selectedOperator).then(selectedOptions => this.handleNew(selectedOptions, { id: 0, name: "New Machine Asset", type: "MA" } ))
  }

  onNewGameClick = () => {
    newGame(this.state.searchParams.selectedOperator).then(selectedOptions => this.handleNew(selectedOptions, { id: 0, name: "New Game", type: "GT" } ))
  }

  loadNode = async (item) => {
    switch (item.type) {
      case "MU":
        return await loadManufacturer(item.id)

      case "GT":
        return await loadGame(item.id)

      case "MA":
        return await loadAsset(item.id)

      case "MT":
        return loadCategory(item.id)

      default: 
        return { options: [], grid: null }
    }
  }

  handleNodeClick = (item) => {
    this.loadNode(item).then(response => this.handleNew(response.options, item, response.grid))
  }

  handleReload = () => {
    const options = this.getAll(this.state.selectedOptions)
    const { selectedNode } = this.state;
    updateAsset(selectedNode.id, this.state.searchParams.selectedOperator, false, options).then(selectedOptions => this.setState({ selectedOptions }))
  }

  findById = (array, id) => {
    for (const node of array) {
      if (node.id === id) return node;
      if (node.children) {
        const child = this.findById(node.children, id);
        if (child) return child;
      }
    }
  }

  handleSave = async () => {

    const changed = this.getAll(this.state.selectedOptions);
    const { selectedNode } = this.state;
    await this.setState({ loading: true })


    await this.updateRequest(selectedNode, changed).then(
      async id => {
        await this.setState({ changed: [], loading: false});
          this.props.showSuccessAlert(selectedNode.id > 0 ? "Your changes were saved successfully!" : "Item successfully created!")
          this.handleRefresh().then(() => {
            const newNode = this.findById(this.state.assets, id);
            if (newNode) this.handleNodeClick(newNode)
          })
      },
      () => this.setState({ loading: false })
    )
  }

  updateRequest = async (selectedNode, changed) => {
    switch (selectedNode.type) {
      case "MU":
        return await updateManufacturer(selectedNode.id, this.state.searchParams.selectedOperator, changed)

      case "GT":
        return await updateGame(selectedNode.id, this.state.searchParams.selectedOperator, changed)

      case "MA":
        return await updateAsset(selectedNode.id, this.state.searchParams.selectedOperator, true, changed)

      case "MT":
        return await updateCategory(selectedNode.id, this.state.searchParams.selectedOperator, changed)     

      default:
        return 0;
        
    }
  }

  render() {
    return (
      <Grid columns="2" className="height-100 gs-flex-container-vertical">
        <Grid.Row className="flex-stretch">
          <Grid.Column computer="8" largeScreen="7" widescreen="6" className="gs-flex-container-vertical">
            <div className="p-1">
              <EGMFilters 
                onChange={this.handleSelection}
                onRefresh={this.handleRefresh}
                loading={this.state.loading}
                searchParams={this.state.searchParams}
              />
            </div>

            <div className="table-overflow flex-stretch-vertical mt-1">
              <EGMTree selectedNode={this.state.selectedNode} assets={this.state.assets} onClick={this.handleNodeClick} />
            </div>

          </Grid.Column>
          <Grid.Column computer="8" largeScreen="9" widescreen="10" className="gs-flex-container-vertical">
            <div className="table-overflow flex-stretch-vertical p-1 mb-1">
              { this.state.selectedNode.id !== null && <Header>{this.state.selectedNode.name}</Header>}
              { this.state.selectedNode.id !== null && <OptionsList options={this.mapChanged(this.state.selectedOptions)} onChange={this.handleChangeValue} /> }          
            </div>
           { this.state.grid !== null && 
              <DataGridRequest
                loading={this.state.loading}
                headings={this.state.grid.headerDef}
                data={this.state.grid.data}
                rowDef={this.state.grid.rowDef}
                onRowClick={() => {}}
                fullHeight
                className="mt-1"
              />
            }
            <div className="mt-1">

            <Dropdown
              text='New'
              icon='add'
              floating
              positive
              as={Button}
              labeled
              button
              color='green'
              className='icon'
              labelPosition='right'
              disabled={!this.state.searchParams.selectedOperator || this.state.loading}
              loading={this.state.loading}
            >
              <Dropdown.Menu>
                <Dropdown.Header icon='add' content='Select Item to Create' />
                <Dropdown.Item onClick={this.onNewManufacturerClick}>Manufacturer</Dropdown.Item>
                <Dropdown.Item onClick={this.onNewCategoryClick}>Category</Dropdown.Item>
                <Dropdown.Item onClick={this.onNewAssetClick}>Machine Asset</Dropdown.Item>
                <Dropdown.Item onClick={this.onNewGameClick}>Game</Dropdown.Item>                
              </Dropdown.Menu>
            </Dropdown>

            <Button icon positive labelPosition='right' loading={this.state.loading} disabled={this.state.selectedNode.id === 0 ? !this.isAllValid(this.state.selectedOptions) || this.state.loading : !this.isValid() || this.state.loading} onClick={this.handleSave}>
              Save Changes
              <Icon name='save' />
            </Button>
            </div>


          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};


const mapStateToProps = state => {
	return {
    operators: state.operators.filter(x => x.value !== -1),
    logIn: state.logIn
  };
};

export default connect(mapStateToProps, { fetchOperators, showSuccessAlert })(EGMManagementView);

