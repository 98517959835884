import jsonPlaceholder from '../api/jsonPlaceholder';
import { prepareDetails } from '../actions/settings';

export const mapToSelect = (data) => {
	return data.map(row => {
		return { key: row.id, value: row.id, text: row.name}
	})
}


export const getManufacturers = async (operatorId) => {
	const response = await jsonPlaceholder.get(`Organisation/manufacturers/${operatorId}`);
	return mapToSelect(response.data)
}

export const getCategories = async (operatorId) => {
	const response = await jsonPlaceholder.get(`Organisation/categories/${operatorId}`);
	return mapToSelect(response.data)
}

export const getGameTitles = async (operatorId) => {
	const response = await jsonPlaceholder.get(`Organisation/gametitles/${operatorId}`);
	return mapToSelect(response.data)
}

export const getStatuses = async () => {
	const response = await jsonPlaceholder.get(`Organisation/assetstatus`);
	return mapToSelect(response.data)
}

export const getVariants = async () => {
	const response = await jsonPlaceholder.get(`Organisation/variants`);
	return mapToSelect(response.data)
}

export const getGroupings = async () => {
	const response = await jsonPlaceholder.get(`Organisation/assetgroupby`);
	return mapToSelect(response.data)
}

/**
 * Take 1 dimensional array from endpoint response and build a hierarchical tree
 * @param {Array} array 
 */
export const buildTree = (array) => {
    // Add a child array to every object in the base array
    const modified = array.map(x => {
        return {...x, children: [] }
    })

    // push the child ids onto their parents
    modified.forEach(item => {
        if (item.parent !== item.child && item.parent !== "0") {
            const parentIndex = modified.findIndex(x => x.child === item.parent)
            if (modified[parentIndex]) { 
              modified[parentIndex].children.push(item); // the child prop is the unique id of the current item
            } else {
              console.error(item) // we found an orphan :(
            }
        }
    })

    // filter out the duplicates.
    return modified.filter(y => y.child === y.parent || y.parent === "0")
}

export const getAssets = async (operatorId, machineTypeId, manufacturerId, gameId, statusCode, variantId, groupBy, search) => {

	const searchText = search === "" ? encodeURIComponent("#") : search;
	const response = await jsonPlaceholder.get(`Organisation/assets/${operatorId},${machineTypeId},${manufacturerId},${gameId},${statusCode},${variantId},${groupBy},${searchText}`);
	
	return buildTree(response.data)

}

export const newManufacturer = async (operatorId) => {
	const response = await jsonPlaceholder.get(`Organisation/newmanufacturer/${operatorId}`);
	return response.data
}

export const newGame = async (operatorId) => {
	const response = await jsonPlaceholder.get(`Organisation/newgametitle/${operatorId}`);
	return response.data
}

export const newAsset = async (operatorId) => {
	const response = await jsonPlaceholder.get(`Organisation/newasset/${operatorId}`);
	return response.data
}

export const newCategory = async (operatorId) => {
	const response = await jsonPlaceholder.get(`Organisation/newcategory/${operatorId}`);
	return response.data
}

export const loadManufacturer = async (id) => {
	const response = await jsonPlaceholder.get(`Organisation/manufacturer/${id}`);
	return response.data
}

export const loadCategory = async (id) => {
	const response = await jsonPlaceholder.get(`Organisation/category/${id}`);
	return response.data
}

export const loadGame = async (id) => {
	const response = await jsonPlaceholder.get(`Organisation/gametitle/${id}`);
	return response.data
}

export const loadAsset = async (id) => {
	const response = await jsonPlaceholder.get(`Organisation/asset/${id}`);
	return response.data
}

export const updateManufacturer = async (id, OperatorId, details) => {
	const payload = {id, OperatorId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/organisation/updatemanufacturer`, payload)
	return response.data
}

export const updateAsset = async (Id, OperatorId, Complete, details) => {
	const payload = {Id, OperatorId, Complete, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/organisation/updateasset`, payload)
	return response.data
}

export const updateCategory = async (id, OperatorId, details) => {
	const payload = {id, OperatorId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/organisation/updatecategory`, payload)
	return response.data
}

export const updateGame = async (id, OperatorId, details) => {
	const payload = {id, OperatorId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/organisation/updategametitle`, payload)
	return response.data
}