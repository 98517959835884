import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Header, Icon, Menu } from 'semantic-ui-react';
import { fetchOperators, getNewOperator, getOperatorOptions, updateOperator } from '../../actions/index.js';
import { showSuccessAlert } from '../../actions/modals';
import BaseSettings from './BaseSettings';
import OptionsList from './OptionsList';

class OperatorSettings extends BaseSettings {
  
  constructor(props) {
    super(props)
    this.state = {
      ...this.state, // spread in parent's state
      selectedOptions: [],
      selectedOperator: { id: null, subTypes: []},
      loading: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOperator !== this.props.selectedOperator && this.props.selectedOperator !== null) {
      this.handleOperatorSelect(this.props.selectedOperator)
    }
  }

  handleOperatorSelect = async (id) => {
    await this.setState({ loading: true })
    await getOperatorOptions(id).then(
      subTypes => {
        const selectedOperator = { id, subTypes }
        const activeMenu = subTypes[0];
        this.setState({ selectedOperator, activeMenu, loading: false });
      },
      () => this.setState({ loading: false })
    )
  }

  handleSave = async () => {
    const changed = this.getChanged();
    await this.setState({ loading: true })
    const callback = () => this.setState({ loading: false });
    updateOperator(this.state.selectedOperator.id, changed).then(
      id => { 
        this.props.fetchOperators().then(() => {
          this.setState({ changed: [] }, () => {
            this.props.showSuccessAlert(`Your changes were saved successfully.`);
            this.handleOperatorSelect(this.state.selectedOperator.id).then(
              () => {
                this.props.onOperatorChange(null, { value: id });
                this.setState({ loading: false });
              },
              callback
            )
          });
        })
      },
      callback
    );
  }

  onNewOperatorClick = () => {
    getNewOperator().then(subTypes => {
      this.setState({ selectedOperator: { id: 0, subTypes } });
    })
  }

  getSelectedOptions = () => {
    const subType = this.state.selectedOperator.subTypes.find(x => x.id === this.state.activeMenu.id)
    return subType ? this.mapChanged(subType.profileData) : []
  }

  render() {
    return (
      <div className="settings height-100 gs-flex-container-vertical">
        <Grid className="height-100 gs-flex-container-vertical">
            <Grid.Row className="flex-stretch">
              <Grid.Column largeScreen="3" widescreen="2" computer="4" className="gs-flex-container-vertical">
                <div className="table-overflow flex-stretch-vertical p-1">
                  <Menu secondary vertical fluid>
                    {this.getMenuItems(this.state.selectedOperator.subTypes)}
                  </Menu>
                </div>
              </Grid.Column>
              <Grid.Column largeScreen="13" widescreen="14" computer="12" className="gs-flex-container-vertical">
                <div className="table-overflow flex-stretch-vertical p-1">
                  <Header>{ this.state.selectedOperator.id === 0 && "New Profile - " }{this.state.activeMenu.name}</Header>
                  <OptionsList options={this.getSelectedOptions()} onChange={this.handleChangeValue} />     
                </div>
              </Grid.Column>         
            </Grid.Row>
            { (this.props.logIn.optionAllowNewOperator || this.props.logIn.optionAllowEditOperator ) &&
            <Grid.Row>
              <Grid.Column largeScreen="3" widescreen="2" computer="4">
              { this.props.logIn.optionAllowNewOperator && <Button icon loading={this.state.loading} positive labelPosition='right' disabled={this.state.selectedOperator === 0 || this.state.loading} onClick={this.onNewOperatorClick}>
                  New Operator
                  <Icon name='add' />
                </Button>
              }
              </Grid.Column>
              <Grid.Column largeScreen="13" widescreen="14" computer="12">
                { this.props.logIn.optionAllowEditOperator && <Button icon positive loading={this.state.loading} labelPosition='right' disabled={!this.isValid() || this.state.loading} onClick={this.handleSave}>
                  Save Changes
                  <Icon name='save' />
                </Button> }
              </Grid.Column>
            </Grid.Row>
            }
        </Grid>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
	return {
    operators: state.operators.filter(x => x.value !== -1),
    logIn: state.logIn
  }
}

export default connect(mapStateToProps, { fetchOperators, showSuccessAlert })(OperatorSettings);
