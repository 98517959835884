
const initialState = { 
	grid: { date:[], headerDef:[], rowDef:[] },
	summary: { data:[], headerDef:[], rowDef:[] }
}


export default (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_SESSIONS_BY_COMPANY':
			return {...state, ...action.payload};
		default:
			return state;
	}
};
