import React from 'react';
import { Table } from 'semantic-ui-react';
import Option from './Option';

class OptionsList extends React.Component {

  state = {
    focus: null
  }

  componentDidUpdate = (prevProps) => {
    /*if (this.props.options.length > 0 && !this.state.focus) {
      const focus = this.props.options.findIndex(x => x.setFocus === true)
      this.setState({ focus })
    }*/
  }

  onChange = (option, value) => {
    this.props.onChange(option, value);
  }
  
  setFocus = (code) => {
    const focus = this.props.options.findIndex(x => x.code === code);
    this.setState({ focus })
  }

  render() {
    return (
      <Table basic='very' width="16">
        <Table.Body>
        {this.props.prefix}
        { this.props.options.map((option, index) => {

          //const focus = this.state.focus === index;
          const style = option.isHidden ? { display: "none "} : {};
          const expanded = this.props.labelWidth || 7;

          return (
            <Table.Row key={`${option.code}_${index}`} style={style}>
              <Table.Cell width={expanded}>{option.text} { option.isRequired && <span style={{ color: "#db2828"}}>*</span>}</Table.Cell>
              <Table.Cell width={16 - expanded}>
                  <Option option={option} setFocus={this.setFocus} /*focus={focus}*/ onChange={this.onChange} />
              </Table.Cell>
            </Table.Row>
          )
        }) }
        </Table.Body>
      </Table>
    )
  }
};

export default OptionsList;
