import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Message, Tab } from 'semantic-ui-react';
import {
  setTransactionAdjust,
  setTransactionAuthorise,
  setTransactionVoid,
  setTransactionReverseVoid
} from '../../actions';
import { hidePasswordModal, showPasswordModal, showSuccessAlert } from '../../actions/modals';
import TransactionTransferAdjustModal from '../modals/TransactionTransferAdjustModal';
import DataGridRequest from './DataGridRequest';

class TransactionDetails extends React.Component {
  state = {
    adjustModalOpen: false,
    closeTransactionModal: false,
    modalContent: '',
    loading: false,
  }
  panes = [
    {
      menuItem: 'Transaction Meters',
      render: () => (
        <Tab.Pane className="height-100 flex-container">
          <DataGridRequest
          headings={this.props.transactionDetails.transactionMetersGrid.headerDef}
          rowDef={this.props.transactionDetails.transactionMetersGrid.rowDef}
          data={this.props.transactionDetails.transactionMetersGrid.data} onRowClick={function(){}}
          fullHeight/>
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Session To-Date Meters',
      render: () => (
        <Tab.Pane className="height-100 flex-container">
          <DataGridRequest
          headings={this.props.transactionDetails.sessionToDateMetersGrid.headerDef}
          rowDef={this.props.transactionDetails.sessionToDateMetersGrid.rowDef}
          data={this.props.transactionDetails.sessionToDateMetersGrid.data} onRowClick={function(){}}
          fullHeight/>
        </Tab.Pane>
      )
    },
  ];

  handleAdjustClick = () => {
    this.setState({
      adjustModalOpen: true,
      modalTitle: `Adjust transaction ${this.props.transactionDetails.transactionId}`,
    });
  }

  handleAdjust = async (newVal) => {
    this.setState({ loading: true }, () => {
      this.props.showPasswordModal(`Adjust Transaction ID ${this.props.transactionDetails.transactionId}`, "Are you sure you want to adjust this transaction?", async () => {
        await this.props.setTransactionAdjust(this.props.transactionDetails.transactionId, newVal)
        await this.setState({ adjustModalOpen: false, loading: false }, () => {
          this.props.closeModal(true);
          this.props.showSuccessAlert('Transaction adjusted successfully!')
        });
      })
    })
  }

  handleAuthorise = () => {
    this.setState({ loading: true }, () => {
      this.props.showPasswordModal(`Authorise Transaction ID ${this.props.transactionDetails.transactionId}`, "Are you sure you want to authorise this transaction?", async () => {
        await this.props.setTransactionAuthorise(this.props.transactionDetails.transactionId)
        this.setState({ loading: false })
        this.props.closeModal(true);
        this.props.showSuccessAlert('Transaction authorised successfully!')
      });
    });
  }

  handleVoid = () => {
    this.setState({ loading: true }, () => {
      this.props.showPasswordModal(`Void Transaction ID ${this.props.transactionDetails.transactionId}`, "Are you sure you want to void this transaction?", async () => {
        await this.props.setTransactionVoid(this.props.transactionDetails.transactionId)
        this.setState({ loading: false})
        this.props.closeModal(true);
        this.props.showSuccessAlert('Transaction voided successfully!')
      });
    });
  }

  handleReverseVoid = () => {
    this.setState({ loading: true }, () => {
      this.props.showPasswordModal(`Authorise Transaction ID ${this.props.transactionDetails.transactionId}`, "Are you sure you want to reverse this voided transaction?", async () => {
        await this.props.setTransactionReverseVoid(this.props.transactionDetails.transactionId)
        this.setState({ loading: false })
        this.props.closeModal(true);
        this.props.showSuccessAlert('Transaction reverse voided successfully!')
      });
    });
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
      adjustModalOpen: false,
      loading: false,
    },()=>{
      if(this.state.closeTransactionModal===true){
        this.props.closeModal(true);
      }
    });
  }
  
  render() {
    if(this.props.transactionDetails.assetNo){
      return(
        <React.Fragment>
        <TransactionTransferAdjustModal
          modalOpen={this.state.adjustModalOpen}
          handleClose={this.handleClose}
          modalTitle={this.state.modalTitle}
          updateValue={this.handleAdjust}
          transactionDetails={this.props.transactionDetails}
        />

        <Grid className="height-100 flex-container">
        { this.props.transactionDetails.isSuspicious &&
          <Grid.Row>
          <Grid.Column width={16}>
          <Message negative>{this.props.transactionDetails.suspiciousReason}</Message>
          </Grid.Column>
          </Grid.Row>
        }
          <Grid.Row>
            <Grid.Column width="16">
              <b>{this.props.transactionDetails.site}</b> - {this.props.transactionDetails.gameName} - {this.props.transactionDetails.assetNo}
              { this.props.logIn.optionShowPlayerID && <span className="ml-2"><b>CUSTOMER ID:</b> {this.props.transactionDetails.playerId || "N/A" }</span> }
              {this.props.logIn.optionShowSessions && <span className="ml-2"><b>SESSION ID:</b> {this.props.transactionDetails.sessionId}</span> }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="16">
              <b>{this.props.transactionDetails.transactionType}</b> - {this.props.transactionDetails.origEFTCash}
              <span className="ml-2">{this.props.transactionDetails.date}</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="16">
              {this.props.transactionDetails.optionAllowAuthorise && <Button className="mr-1" positive disabled={this.state.loading} loading={this.state.loading} onClick={this.handleAuthorise}>Authorise</Button> }
              {this.props.transactionDetails.optionAllowAdjust && <Button className="mr-1" disabled={this.state.loading} loading={this.state.loading} onClick={this.handleAdjustClick}>Adjust</Button> }
              {this.props.transactionDetails.optionAllowVoid && <Button className="mr-1" negative disabled={this.state.loading} loading={this.state.loading} onClick={this.handleVoid}>Void</Button> }
              {this.props.transactionDetails.optionAllowReverseVoid && <Button className="mr-1" disabled={this.state.loading} loading={this.state.loading} onClick={this.handleReverseVoid}>Reverse Void</Button> }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="flex-stretch-vertical">
            <Grid.Column width={16} className="flex-container"><Tab panes={this.panes}  className="height-100 flex-container" /></Grid.Column>
          </Grid.Row>
         </Grid>
       </React.Fragment>
      );
    }else{
      return (
        <Message negative>There was an error loading the session details.</Message>
      )
    }
  }
}
const mapStateToProps = state => {
	return {
    transactionAdjust: state.transactionAdjust,
    transactionAuthorise: state.transactionAuthorise,
    transactionVoid: state.transactionVoid,
    logIn: state.logIn
  };
};

export default connect(mapStateToProps, {
  setTransactionAdjust,
  setTransactionAuthorise,
  setTransactionVoid,
  setTransactionReverseVoid,
  showPasswordModal,
  hidePasswordModal,
  showSuccessAlert
})(TransactionDetails);
