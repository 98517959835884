import React from 'react';
import {connect} from 'react-redux';
import { 
  emptyInstallationsSection,
  emptyCommsViewerSection,
  emptyCollectionsSection,
  emptySessionsSection,
  emptyTransactionsSection,
  emptySettingsSection,
  fetchCollectionsFilters,
  fetchInstallationsFilters,
  fetchTransactionsFilters,
  fetchCommsViewerFilters,
  fetchSessionsFilters,
  fetchSettingsFilters
} from '../../actions';
import OrganisationDirectory from '../shared/OrganisationDirectory';

class OrganisationDirectoryMenu extends React.Component {

  mapReduxToOrgTree() {

    let props = {
      extraFilters: true,
      section: this.props.section,
      data: null,
      onChange: null,
      empty: null,
      hideable: false,
      allowAll: true,
      showEmpty: false,
      includeSearchTerms: true
    }

    switch (this.props.section) {
        case "GENERAL_INSTALL":
          props.onChange = this.props.fetchInstallationsFilters
          props.data = this.props.installationsFilters
          props.extraFilters = false;
          props.empty = this.props.emptyInstallationsSection
          props.allowAll = false;
          break;

        case "GENERAL_COMMS":
          props.data=this.props.commsViewerFilters
          props.onChange=this.props.fetchCommsViewerFilters
          props.empty = this.props.emptyCommsViewerSection
          break;
        
        case "GENERAL_TRANS":
          props.data=this.props.transactionsFilters
          props.onChange=this.props.fetchTransactionsFilters
          props.empty = this.props.emptyTransactionsSection
          break;

        case "GENERAL_COLLECTIONS":
          props.data=this.props.collectionsFilters
          props.onChange=this.props.fetchCollectionsFilters
          props.empty = this.props.emptyCollectionsSection
          break;

        case "GENERAL_SYSADMIN":
          props.data = this.props.settingsFilters
          props.onChange = this.props.fetchSettingsFilters
          props.empty = this.props.emptySettingsSection
          props.extraFilters = true;
          props.allowAll = false;
          props.showEmpty = true;
          props.includeSearchTerms = false;
          break;

        default:
          props.data = this.props.sessionsFilters
          props.onChange = this.props.fetchSessionsFilters
          props.empty = this.props.emptySessionsSection
          break;
    }
   
    return props;
  }
 
  render() {
      return (
        <div>
          { (this.props.sectionUsesOrgTree) &&
              <OrganisationDirectory
                  {...this.mapReduxToOrgTree()}
                  marginLeft={this.props.marginLeft}
                  showOrgTree={this.props.showOrgTree}
              />
          }
        </div>
      )
 }
}

const mapStateToProps = (state, ownProps) => {
	return {
    sessionsFilters: state.sessionsFilters,
    collectionsFilters: state.collectionsFilters,
    installationsFilters: state.installationsFilters,
    transactionsFilters: state.transactionsFilters,
    commsViewerFilters: state.commsViewerFilters,
    settingsFilters: state.settingsFilters
  };
};

export default connect(mapStateToProps, {
  emptyInstallationsSection,
  emptyCommsViewerSection,
  emptyCollectionsSection,
  emptySessionsSection,
  emptyTransactionsSection,
  emptySettingsSection,
  fetchSettingsFilters,
  fetchSessionsFilters,
  fetchCollectionsFilters,
  fetchInstallationsFilters,
  fetchTransactionsFilters,
  fetchCommsViewerFilters,
})(OrganisationDirectoryMenu);
