import React from 'react';
import {connect} from 'react-redux';
import { Grid, Tab } from 'semantic-ui-react'

import CurrentInstallations from '../currentInstallations/CurrentInstallations';
import Transactions from '../currentInstallations/Transactions';
import Sessions from '../currentInstallations/Sessions';
import {
  fetchInstallations,
  fetchTransactions,
  fetchSessions,
  fetchInstallationsFilters,
  emptyInstallationsSection
} from '../../actions';

import Collections from '../currentInstallations/Collections';

class CurrentInstallationsView extends React.Component {

  state = {
    currentOrganisationId: 0,
  };

  getPanes = () => {
    const panes = [];

    if (this.props.logIn.optionShowTransactions) {
      panes.push({
        menuItem: 'Transactions',
        pane: <Tab.Pane><Transactions className="flex-stretch" /></Tab.Pane>
      });
    } 
    
    if (this.props.logIn.optionShowSessions) {
      panes.push({
        menuItem: 'Sessions',
        pane: <Tab.Pane><Sessions className="flex-stretch gs-flex-container-vertical" /></Tab.Pane>
      })
    }
      panes.push({
        menuItem: 'Collections',
        pane: <Tab.Pane><Collections className=".flex-stretch"  /></Tab.Pane>
      })
    return panes;
  }

  render() {
    const panes = this.getPanes()
    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row className="height-100">
          <Grid.Column className="height-100 gs-flex-container-vertical">
                <CurrentInstallations
                  showOrgTree={this.props.showOrgTree}
                  organisationId={this.props.filters.filterSelected.site.id}
                  expanded={panes.length < 1}
                />
                { panes.length > 0 && <Tab renderActiveOnly={false} panes={panes} installation={this.state.activeInstallation} className="flex-stretch gs-flex-container-vertical mt-1" /> }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
	return {
    filters: state.installationsFilters,
    logIn: state.logIn
  };
};

export default connect(mapStateToProps, {
  fetchInstallations,
  fetchTransactions,
  fetchSessions,
  fetchInstallationsFilters,
  emptyInstallationsSection
})(CurrentInstallationsView);
