import moment from 'moment';
import React from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Checkbox, Form, Input, Select } from 'semantic-ui-react';
import { UIDateFormat } from '../../config/dates';
import period from '../helpers/collections';

class CollectionsFilters extends React.Component {

  handleChange = (event, {name, value}) => {
    this.props.onChange(name, value)
  }

  handleDateChange = (event, {name, value}) => {
    this.props.onChange(name, moment(value, UIDateFormat))
  }

  render() {
    const periodType = period.find(({value}) => value === this.props.periodType)
    return (
          <Form id="collectionsFilters">
            <Form.Group inline>
              <Form.Field inline>
                <label>Period</label>
                <Select
                  value={this.props.periodType}
                  options={period}
                  onChange={this.handleChange}
                  name='periodType'
                />
              </Form.Field>

          </Form.Group>

          <Form.Group inline>
            { this.props.periodType === 2 ?
                <Form.Field inline>
                  <label>From</label>
                  <DateInput
                    name="startDate"
                    placeholder="Date"
                    value={this.props.startDate.format(UIDateFormat)}
                    iconPosition="left"
                    onChange={this.handleDateChange}
                    animation="fade"
                    popupPosition="bottom center"
                    closable
                    className="ui input"
                    style={{width: '9em'}}
                    autoComplete="off"
                    dateFormat={UIDateFormat}
                  />
                </Form.Field>
              :
                <Form.Field inline>
                <label>Last</label>
                <Input style={{width:'3em'}} value={this.props.lastxPeriod} name='lastxPeriod' type="number" onChange={this.handleChange} />
                {periodType.text}{this.props.lastxPeriod > 1 && "s"}
                </Form.Field>
            }
          </Form.Group>
          <Form.Group inline>
            { this.props.periodType === 2 &&
              <Form.Field inline>
                      <label>To</label>
                      {!this.props.useCurrentDateTime &&
                        <DateInput
                          name="endDate"
                          className="ui input"
                          style={{width: '9em'}}
                          placeholder="Date"
                          value={this.props.endDate.format(UIDateFormat)}
                          iconPosition="left"
                          onChange={this.handleDateChange}
                          animation="fade"
                          popupPosition="bottom center"
                          closable
                          autoComplete="off"
                          dateFormat={UIDateFormat}
                        />
                      }
                      { this.props.periodType === 2 &&

                          <Checkbox checked={this.props.useCurrentDateTime} label='Current Time/Date' onChange={this.props.onChangeUseCurrentDateTime} />
                        
                      }

                    </Form.Field>


              }

          </Form.Group>
        </Form>
    );
  }
}


export default CollectionsFilters;
