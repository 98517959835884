import jsonPlaceholder from '../api/jsonPlaceholder';

export const emptySettingsSection = (clearFilters = true) => async dispatch => {
	if (clearFilters) dispatch({type: 'FETCH_SETTINGS_FILTERS', payload: {}});
}

export const fetchOperatorProfiles = async (operatorId) => {
	const profiles = await jsonPlaceholder.get(`SettingsProfile/profiles/${operatorId}`);
	
    return profiles.data.map(profile => {
		return { key: profile.id, value: profile.id, text: profile.name}
	  })
}

export const fetchProfile = async (operatorId, profileId = -1) => {
	const profile = await jsonPlaceholder.get(`/SettingsProfile/profile/${operatorId},${profileId}`);
	return profile.data;
}

export const newProfile = async (operatorId, profileId) => {
	const response = await jsonPlaceholder.get(`/SettingsProfile/new/${operatorId},${profileId}`);
	return response.data
}

export const getSite = async (siteId) => {
	const response = await jsonPlaceholder.get(`Organisation/site/${siteId}`);
	return response.data
}

export const getSubCompany = async (subcompanyId) => {
	const response = await jsonPlaceholder.get(`Organisation/region/${subcompanyId}`);
	return response.data
}

export const getCompany = async (companyId) => {
	const response = await jsonPlaceholder.get(`Organisation/company/${companyId}`);
	return response.data
}

export const getNewCompany = async (operatorId) => {
	const response = await jsonPlaceholder.get(`/Organisation/newcompany/${operatorId}`)
	return response.data
}

export const getNewRegion = async (operatorId, companyId) => {
	const response = await jsonPlaceholder.get(`/Organisation/newregion/${operatorId},${companyId}`)
	return response.data
}

export const getNewSite = async (operatorId, companyId, regionId) => {
	const response = await jsonPlaceholder.get(`/Organisation/newsite/${operatorId},${companyId},${regionId}`)
	return response.data
}

export const fetchOpeningHoursProfiles = async (operatorId) => {
	const profiles = await jsonPlaceholder.get(`OpeningHours/profiles/${operatorId}`);

    return profiles.data.map(profile => {
		return { key: profile.id, value: profile.id, text: profile.name}
	})
}

export const fetchOpeningHoursProfile = async (operatorId, profileId) => {
	const response = await jsonPlaceholder.get(`OpeningHours/profile/${operatorId},${profileId}`);
	return response.data
}

export const updateOpeningHours = async (OperatorId, id, name, details) => {
	const response = await jsonPlaceholder.put(`/OpeningHours/update`, {id, OperatorId, name, details})
	return response.data
}

export const getOpeningHoursNew = async (operatorId) => {
	const response = await jsonPlaceholder.get(`/OpeningHours/new/${operatorId}`);
	return response.data
}

export const prepareDetails = (details) => {
	const parts = [];

	for (const [key, value] of Object.entries(details)) {
		parts.push(`${key}=${value}`);
	}

	return parts.join(";")
	
}

export const updateSettingsProfile = async (operatorId, profileId, baseProfileId, details) => {
	const payload = {id: profileId, operatorId, profileId: baseProfileId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/SettingsProfile/update/`, payload)
	return response.data
}

export const updateSite = async (OperatorId, CompanyId, RegionId, siteId, details) => {
	const payload = {id: siteId, OperatorId, CompanyId, RegionId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/organisation/updatesite/`, payload)
	return response.data
}

export const updateRegion = async (OperatorId, CompanyId, regionId, details) => {
	const payload = {id : regionId, OperatorId, CompanyId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/organisation/updateregion/`, payload)
	return response.data
}

export const updateCompany = async (OperatorId, companyId, details) => {
	const payload = {id : companyId, OperatorId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/organisation/updatecompany/`, payload)
	return response.data
}

export const updateOperator = async (OperatorId, details) => {
	const payload = {id : OperatorId,  details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/organisation/updateoperator/`, payload)
	return response.data
}

export const getOperatorOptions = async (operatorId) => {
	const response = await jsonPlaceholder.get(`/organisation/operator/${operatorId}`);
	return response.data
}

export const getNewOperator = async () => {
	const response = await jsonPlaceholder.get(`/organisation/newoperator`);
	return response.data
}