import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Menu, Message, Tab } from 'semantic-ui-react';
import { emptyCollectionsSection, emptyCommsViewerSection, emptyInstallationsSection, emptySessionsSection, emptySettingsSection, emptyTransactionsSection, fetchLogin, fetchOperators, setLogout } from '../../actions';
import { toggleJira } from '../helpers/jira';
import ConfirmModal from '../modals/ConfirmModal';
import EnterPasswordModal from '../modals/EnterPasswordModal';
import SuccessModal from '../modals/SuccessModal';
import OrganisationDirectoryMenu from '../shared/OrganisationDirectoryMenu';
import CollectionsView from './CollectionsView';
import CommsViewerView from './CommsViewerView';
import CurrentInstallationsView from './CurrentInstallationsView';
import DeviceManagementView from './DeviceManagementView';
import EGMManagementView from './EGMManagementView';
import MyMenu from './Menu';
import ReportsView from './ReportsView';
import SessionsView from './SessionsView';
import SystemAdminView from './SystemAdminView';
import SystemTray from './SystemTray';
import TransactionsView from './TransactionsView';
import UserAdminView from './UserAdminView';
import TimeoutCheck from '../shared/TimeoutCheck';

class Panes extends React.Component {

  state = {
    section: ""
  }

  handleOnIdle = event => {
    alert('Timeout');
  }

  constructor(props) {
    super(props);
    
    if (props.logIn === "") {
      props.history.push('/')
    }

    this.state = {
      orgTreeCollapsed: false,
      collapsed: true,
      activeIndex: 0,
      activeFilter: null,
      activeData: [],
      panes: [],
      availablePanes: [
        {
          menuItem: (
            <Menu.Item key='GENERAL_INSTALL'>
              Site Installations<Button name="GENERAL_INSTALL" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, this.props.emptyInstallationsSection)}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><CurrentInstallationsView className='flex-stretch' showOrgTree={this.showOrgTree} /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_COMMS'>
              Live Floor View<Button name="GENERAL_COMMS" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, this.props.emptyCommsViewerSection)}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><CommsViewerView className=".flex-stretch" showOrgTree={this.showOrgTree} /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_TRANS'>
              Transactions<Button name="GENERAL_TRANS" icon className="close"  onClick={(event, { name }) => { this.rmTab(event, name, this.props.emptyTransactionsSection)}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><TransactionsView className=".flex-stretch" showOrgTree={this.showOrgTree} /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_SESSIONS'>
              Sessions<Button name="GENERAL_SESSIONS" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, this.props.emptySessionsSection)}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><SessionsView className=".flex-stretch"  showOrgTree={this.showOrgTree} /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_COLLECTIONS'>
              Collections<Button name="GENERAL_COLLECTIONS" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, this.props.emptyCollectionsSection)}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><CollectionsView className=".flex-stretch" showOrgTree={this.showOrgTree} /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_REPORTS'>
              Reports<Button name="GENERAL_REPORTS" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, () => {} )}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><ReportsView className=".flex-stretch" /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_USERADMIN'>
              User Admin<Button name="GENERAL_USERADMIN" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, () => {} )}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><UserAdminView section={this.state.section} showOrgTree={this.showOrgTree} className=".flex-stretch" /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_SYSADMIN'>
              System Admin<Button name="GENERAL_SYSADMIN" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, this.props.emptySettingsSection)}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><SystemAdminView className=".flex-stretch" section={this.state.section} showOrgTree={this.showOrgTree} /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_ASSETADMIN'>
              EGM Management<Button name="GENERAL_ASSETADMIN" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, () => {} )}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><EGMManagementView className=".flex-stretch" section={this.state.section} /></Tab.Pane>
        },
        {
          menuItem: (
            <Menu.Item key='GENERAL_DEVICEADMIN'>
              System Hardware Admin<Button name="GENERAL_DEVICEADMIN" icon className="close" onClick={(event, { name }) => { this.rmTab(event, name, () => {} )}} ><Icon name='window close' /></Button>
            </Menu.Item>
          ),
          pane: <Tab.Pane><DeviceManagementView className=".flex-stretch" section={this.state.section} /></Tab.Pane>
        }               
      ]
    };
    

  }

  

  showOrgTree = (value=!this.state.orgTreeCollapsed) => {
    this.setState({ orgTreeCollapsed: value })
  }

  logout = () => {
    this.setState({panes: [] }, () => {
      this.props.setLogout().then(() => {
        this.props.history.push('/')
      })
    });
  }

  rmTab = (event, name, callback) => {
    event.stopPropagation();

    const panes = this.state.panes.filter(pane=>pane.menuItem.key !== name);

    let activeIndex = null;
    let orgTreeCollapsed = false;
    let section = "";

    if (panes.length) {
      activeIndex = panes.length - 1
      orgTreeCollapsed = this.state.orgTreeCollapsed
      section = panes[activeIndex].menuItem.key
    }

    this.setState({ panes, activeIndex, orgTreeCollapsed, section}, callback);
  }

  createPane(menuItem) {
    let newPane = this.state.availablePanes.find(pane=>pane.menuItem.key === menuItem);
    if(newPane){
      this.setState((state, props) => {
        const newPanes = [...state.panes, newPane];
        return{
          activeIndex: state.panes.length,
          panes: newPanes,
          orgTreeCollapsed: true,
          section: menuItem
        }
      });
    }
  }

  updateTab = menuItem => {
    
    if(this.state.panes.length>0){
      let pane = this.state.panes.findIndex(pane=>pane.menuItem.key === menuItem);
      if(pane>-1){
        this.setState({
          activeIndex: pane,
          section: menuItem
        });
      }else{
        this.createPane(menuItem);
      }
    }else{
      this.createPane(menuItem);
    }
  }

  setPane = () => {
    this.setState((state, props) => {
      var pane = 0;
      if (props.match.params.id === "current_installations"){
        pane = 1;
      }
      return { activeIndex: pane };
    });

  }

  handleTabChange = (e, { activeIndex }) => {
    
    this.setState({ activeIndex, section: this.state.panes[activeIndex].menuItem.key })
  }

  componentDidMount(){
    this.props.fetchOperators();
    this.setPane();
  }

  componentDidUpdate(prevProps){
    // Check for a change in the transaction list
    if(this.props.match.params.id !== prevProps.match.params.id){
      this.setPane();
    }
  }

  sectionUsesOrgTree = (section) => {
    return !["GENERAL_REPORTS", "GENERAL_USERADMIN", "GENERAL_ASSETADMIN", "GENERAL_DEVICEADMIN"].includes(section);
  }


  calculateLeftMargin = () => {
    let marginLeft = 0;
    marginLeft += this.state.collapsed ? 48 : 210

    if (this.sectionUsesOrgTree(this.state.section)) {
      marginLeft += !this.isOrgTreeCollapsed() ? 0 : 270
    }

    return marginLeft;
  }

  isOrgTreeCollapsed = () => {
    const mapping = {
      "GENERAL_INSTALL": this.props.installationsFilters.showOrgTree,
      "GENERAL_COMMS": this.props.commsViewerFilters.showOrgTree,
      "GENERAL_TRANS": this.props.transactionsFilters.showOrgTree,
      "GENERAL_SESSIONS": this.props.sessionsFilters.showOrgTree,
      "GENERAL_COLLECTIONS": this.props.collectionsFilters.showOrgTree,
      "GENERAL_REPORTS": false,
      "GENERAL_SYSADMIN": this.props.settingsFilters.showOrgTree,
      "GENERAL_USERADMIN": false,
      "GENERAL_ASSETADMIN": false,
      "GENERAL_DEVICEADMIN": false
    }
  
    return mapping[this.state.section]
  }

  render() {
    toggleJira('100')
    if (this.props.logIn === "") {
      return (
        <Message negative>Unauthorised</Message>
      )
    }


    const marginLeft = this.calculateLeftMargin()
    const orgTreeMarginLeft = this.state.collapsed ? 48 : 210;

    return (
      <div className="height-100">
        <TimeoutCheck />
        <EnterPasswordModal />
        <SuccessModal />
        <ConfirmModal />
        <MyMenu
          collapsed={this.state.collapsed}
          onCollapse={() => this.setState({collapsed: !this.state.collapsed }) }
          onSubmit={this.updateTab}
          onLogout={this.logout}
          menu={this.props.logIn.menu}
        />
          { this.state.panes.length > 0 && 

            <OrganisationDirectoryMenu
                setActiveFilter={this.setActiveFilter}
                section={this.state.section}
                marginLeft={orgTreeMarginLeft}
                showOrgTree={this.showOrgTree}
                sectionUsesOrgTree={this.sectionUsesOrgTree(this.state.section)}
            />
        }
        <div className="height-100 gs-flex-container-vertical" style={{ marginLeft: `${marginLeft}px`, minWidth: "720px" }}>
          {this.state.panes.length > 0 ?
            <div className="flex-stretch" style={{ alignItems: "center" }}>
              <Tab
                panes={this.state.panes}
                activeIndex={this.state.activeIndex}
                onTabChange={this.handleTabChange}
                renderActiveOnly={false}
                className="height-100 gs-flex-container-vertical p-1"
              />
            </div>
            :
            <div className="flex-stretch" style={{ display: "flex", alignItems: "center"}}>
              <img src="logo.png" alt="Logo" id="logo" style={{ maxWidth: "40%", margin: "0px auto" }} />
            </div>
          }
          <SystemTray />
        </div>
      </div>
    )

 }
}

const mapStateToProps = (state, ownProps) => {
	return {
    logIn: state.logIn,
    sessionsFilters: state.sessionsFilters,
    collectionsFilters: state.collectionsFilters,
    installationsFilters: state.installationsFilters,
    transactionsFilters: state.transactionsFilters,
    commsViewerFilters: state.commsViewerFilters,
    settingsFilters: state.settingsFilters
  };
};

export default connect(mapStateToProps, {
  fetchLogin,
  setLogout,
  emptyInstallationsSection,
  emptySettingsSection,
  emptyCollectionsSection,
  fetchOperators,
  emptyCommsViewerSection,
  emptySessionsSection,
  emptyTransactionsSection
})(Panes);
