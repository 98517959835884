export const initialState = {
    filterData: {
      operator: [],
      manufacturer: [],
      machinetypes: [],
      game: [],
	  regions: []
    },
	filterActive: {
	  directory: true,
      manufacturer: false,
      game: false,
      machinetypes: false,
	  regions: false,
      search: false
	},
	filterSelected: {
		company: {id : null },
		subcompany: {id : null },
		site: {id: null },
		searchText:'',
		operator: null,
		regions: [],
		manufacturer: [],
		machinetypes: [],
		game: []
	},
	organisations: [],
	allOrgsActive: true,
    activeOrgs: [],
	activeSubOrgs: [],
	hasSelection: false,
	showOrgTree: true,
	operatorOptions: {}
}

const defaultReducer =  (key) => {
	return (state = initialState, action) => {
		switch (action.type) {
			case key:
				return {...initialState, ...action.payload};
			default:
				return state;
		}
	}
}

export const transactionsFiltersReducer = defaultReducer('FETCH_TRANSACTIONS_FILTERS');
export const installationsFiltersReducer = defaultReducer('FETCH_INSTALLATIONS_FILTERS');
export const sessionsFiltersReducer = defaultReducer('FETCH_SESSIONS_FILTERS');
export const commsViewerFiltersReducer = defaultReducer('FETCH_COMMS_VIEWER_FILTERS');
export const collectionsFiltersReducer = defaultReducer('FETCH_COLLECTIONS_FILTERS');
export const settingsFiltersReducer = defaultReducer('FETCH_SETTINGS_FILTERS');
