import React from 'react';
import { Grid, Segment, Header, Message } from 'semantic-ui-react'
import FormBuilder from '../reports/FormBuilder';
import ReportsList from '../reports/ReportsList';
import { getReport } from '../../actions/reports';
import { fetchReports } from '../../actions/reports'

class ReportsView extends React.Component {

  state = {
    reports: [],
    category: {id: null, reports: [] },
    report: {id: null},
    error: null
  }

  onSelection = (selection) => {
    this.onParamsChange(selection);
  }

  onError = (error, callback = () => {}) => {
    this.setState({ error }, callback)
  }

  onParamsChange = (report, params=['#','#','#','#','#','#','#','#','#','#']) => {
    this.setState({ error: null }, () => {
      getReport(report.id, params)
      .then((response) => {
          this.setState({ report: {...report, data: response } });
      })
    })
  }

  componentDidMount() {
    fetchReports()
      .then(reports => {
        this.setState( { reports } )
      })
 }

  render() {
    return (
      <Grid className="mt-2 mb-2" columns="2">
        { this.state.error &&
          <Grid.Row>
            <Grid.Column width="16"><Message negative onDismiss={() => this.setState({ error: null })}>{this.state.error}</Message></Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column width="3">
            <ReportsList reports={this.state.reports} selected={this.state.category} onSelection={(item) => this.setState({ category: item, report: { id: null } })} />            
          </Grid.Column>
          { this.state.category.id &&
          <Grid.Column width="3">
            <ReportsList reports={this.state.category.reports} title={`${this.state.category.name} Reports`} selected={this.state.report} onSelection={this.onSelection} />            
          </Grid.Column>
          }
          <Grid.Column width="10">
              { !this.state.report.id ?
                  <Segment.Group>
                      <Segment>
                          <Header>Reports Viewer</Header>
                      </Segment>
                      <Segment>
                          Please choose a report on the left menu to begin.
                      </Segment>
                  </Segment.Group>
                :
                  <FormBuilder report={this.state.report} onError={this.onError} onChange={this.onParamsChange} />
              } 
          </Grid.Column>          
        </Grid.Row>
      </Grid>
    );
  }
};

export default ReportsView;
