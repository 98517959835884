import { detect } from 'detect-browser';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Input, Message } from 'semantic-ui-react';
import { fetchLogin } from '../../actions';
import { toggleJira } from '../helpers/jira';


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logIn: {},
      loading: false,
      loggedIn: false,
      userName: '',
      password: '',
      activeIndex: 0,
      panes: [],
      passwordMatch: true,
      newPasswordRepeat: '',
      newPassword: '',
      userEmail: '',
      forgotPassword: false,
      logInFail: false,
      expired: false,
      browser: detect()
   };
  }

  login = () => {
    this.setState({ loading: true, expired: false}, () => {
      this.props.fetchLogin(this.state.userName, this.state.password).then((result) => {
        if (result) {
          if (this.props.logIn.resetPassword) {
            this.props.history.push("/reset")
          } else {
            sessionStorage.setItem('login', JSON.stringify(this.props.logIn));
            this.props.history.push("/dashboard");
          }
        } else {
          this.setState({loginFail: true})
        }
        this.setState({ loading: false})
      })
    });

  }

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const expired = query.get('expired')
    this.setState({ expired: expired !== null })
    
  }

  render() {
    toggleJira('0')
    if (this.state.browser.name === 'ie') {
      return (
        <Grid centered>
          <Grid.Row>
            <Grid.Column width="6" textAlign='center'>
                <img src='logo.png' alt="Rimini Logo" id="logo" />
              <Header as='h2' color='blue'>Browser Not Supported</Header>
              <p>Unfortunately, Internet Explorer is not compatible with Rimini Portal.</p>
              <p>Please try another browser such as <b>Chrome</b>, <b>Firefox</b> or <b>Edge</b>.</p>              
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )
    }


    return (
      <Grid centered style={{ height: "100vh"}}>
        <Grid.Row style={{ height: "40vh"}}>
          <Grid.Column widescreen="6" computer="8" largeScreen="7" tablet="9" textAlign='center' verticalAlign='middle'>
            <img src='logo.png' alt="Rimini Logo" id="logo" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ height: "40vh"}}>
          <Grid.Column widescreen="3" computer="5" largeScreen="4" tablet="6" verticalAlign='middle'>
            <Form id="login">
              {this.state.loginFail ? <Message negative><Message.Header>Login Failed</Message.Header>Please check your username and password.</Message> : null}
              {this.state.expired && <Message negative><Message.Header>Session Expired</Message.Header> Your login session has expired, please login again.</Message>}
              <Form.Field>
                <Input icon="user" iconPosition="left" placeholder="Username" name="userName" onChange={this.handleChange} value={this.state.userName} />
              </Form.Field>
              <Form.Field>
                <Input icon="lock" iconPosition="left" placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} type='password' />
              </Form.Field>
              <Button disabled={this.state.loading} loading={this.state.loading} primary onClick={this.login} size='large'>Log In</Button> <Link to='/forgotten' style={{ fontWeight: "bold" }} color='blue' size='large'>Forgot Password?</Link>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column widescreen="2" computer="4" largeScreen="3" tablet="5" textAlign='center' verticalAlign='middle'>
            <img src='rimini-partnership.png' className="mt-1" alt="OKTO Logo" id="OKTO" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    logIn: state.logIn
  };
};

export default connect(mapStateToProps, {
  fetchLogin
})(Login);
