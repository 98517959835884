const defaultReducer =  (key, initialState) => {
	return (state = initialState, action) => {
		switch (action.type) {
			case key:
				return action.payload;
			default:
				return state;
		}
	}
}

export const logInReducer = defaultReducer('FETCH_LOGIN', "");
export const logInSetReducer = defaultReducer('SET_PASSWORD', "OK");
export const logInResetReducer = defaultReducer('RESET_PASSWORD', "OK");
export const logOutReducer = defaultReducer('SET_LOGOUT', "");
