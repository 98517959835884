export const newFile = (data, fileName, contentType) => {
	const blob = new Blob([data], {type: contentType})
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
}