import React from 'react';
import _ from 'lodash';
import { Dimmer, Loader, Table, Icon  } from 'semantic-ui-react'
import Option from '../systemAdmin/Option';
import { getTextColour, getBackgroundColour } from '../helpers/colours'

class DataGridRequest extends React.Component {
  state = {
    activeRow: null,
    sortBy: null,
    data: this.props.data,
    direction: 'ascending',
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.data !== this.props.data) {
      this.setState({
        activeRow: null, 
        sortBy: null, 
        direction: 'ascending', 
        data: this.props.data
      }) 
    }
  }

  onRowClick = row =>{
    if (!this.props.disableActiveRow) {
      this.setState({activeRow: row}, () => {
        if (typeof this.props.onRowClick === "function") {
          this.props.onRowClick(row);
        }
      })
    }
  }

  onCellClick = cell =>{
    if (typeof this.props.onCellClick === "function" && this.props.disableActiveRow) {
      this.props.onCellClick(cell);
    }
  }

  renderData(){
    return this.state.data.map((data, index) => {
      const activeRow = this.props.activeRow ? this.props.activeRow : this.state.activeRow
      return (
        <Table.Row className={ !this.props.disableActiveRow && "clickable"} active={ data === activeRow} key={`row_${index}`} onClick={() => { this.onRowClick(data) }}>
            {this.dataToRow(data, index)}
        </Table.Row>
      );
    });
  }

  handleSort = (sortBy) => {
    let isString = false;

    this.state.data.forEach(o => {
      if (!isString && isNaN(o.cellData[sortBy].data.actualValue)) {
        isString = true;
      }
    })

    const sorted =_.orderBy(
      this.state.data, 
      function (o) {
        return !isString ? Number(o.cellData[sortBy].data.actualValue) : o.cellData[sortBy].data.actualValue
      },
      this.state.direction === "ascending" ? 'desc' : 'asc'
    )

    this.setState({ direction: this.state.direction === "ascending" ? "descending" : "ascending", sortBy, data: sorted })
  }

  getCellAlignment = (index) => {
    const textAlign = ["left","center","right"];

    if (!this.props.rowDef[index]) {
      return textAlign[0];
    } else {
      const alignment = textAlign[this.props.rowDef[index].alignment];
      return alignment || textAlign[0];
    }
  }

  getHeaderAlignment = (index) => {
    const textAlign = ["left","center","right"];
    return textAlign[index];
  }


  dataToRow(data, rowIndex) {
    return data.cellData.filter(item => !item.hide).map((item,index) => {
      switch (item.type) {
        case "cell":
          return (
            <Table.Cell
              textAlign={this.getCellAlignment(index)}
              key={`cell_${rowIndex}_${index}`}
              singleLine={true} 
              style={{background: getBackgroundColour(item.data), color: getTextColour(item.data)}}
              onClick={() => this.onCellClick(item.data)}
            >
              { item.data.displayValue !== "" ? item.data.displayValue : " "}
            </Table.Cell>
          )
        default:
          return (
            <Table.Cell
            key={item.name}
            singleLine={true} 
            >
              <Option option={item.data} onChange={this.props.onOptionChange}/>
            </Table.Cell>
          )

      }
    });
  }

  renderHeadings() {
    return this.props.headings.map((data, index) => {
      return <Table.HeaderCell key={`header_${index}`} textAlign={this.getHeaderAlignment(data.alignment)} sorted={ index === this.state.sortBy ? this.state.direction : null } onClick={() => !this.props.disableSort && this.handleSort(index)}>{data.text}</Table.HeaderCell>
    })
  }

  render() {

    let classes = 'table-overflow max-height'

    if (this.props.hideOverflow) {
      classes = ''
    }

    if (this.props.stretched) {
     classes = 'table-overflow flex-stretch'
    }

    if (this.props.fullHeight) {
     classes = 'table-overflow flex-stretch-vertical'
    }

    if (this.props.loading) {
      return (
        <div style={this.props.styleInf} className={classes}>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </div>
      )
    }
    
    if(!this.state.data || !this.state.data.length){
      return(
        <div style={this.props.styleInf} className={classes}>
            <div style={{marginTop: "20px", textAlign: "center"}}> <Icon name='dont' size="large" disabled /> { this.props.noResults ? this.props.noResults : "There are no results for the current set of filters." }</div>
        </div>
      );
    }

    return (
      <div style={this.props.styleInf} className={classes}>
        <Table 
          sortable={!this.props.disableSort} 
          size="small"
          { ...!this.props.hideOverflow ? {basic:'very'} : {} } structured celled>
          <Table.Header>
            <Table.Row>
                {this.renderHeadings()}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderData()}
          </Table.Body>
        </Table>
      </div>
    );
  }
}


export default DataGridRequest;
