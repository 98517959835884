import React from 'react';
import { List } from 'semantic-ui-react';
import { getColour } from '../helpers/colours';
import BaseSettings from '../systemAdmin/BaseSettings';

class EGMTree extends BaseSettings {

  renderTree = (item) => {
    const clickable = ["MU", "GT", "MA", "MT"].includes(item.type);

    let css = clickable ? "clickable" : ""

    if (item.child !== item.parent) {
      css += " text-normal";
    }

    if (item.child === this.props.selectedNode.child) {
      css += " active"
    }
    
    const onClick = clickable ? () => this.props.onClick(item) : null;

    return (
      <List.Item key={item.name}>
        <List.Header className={css} onClick={onClick} style={{ color: getColour(item, "colour")}}>{item.name}</List.Header>
        { item.children.length > 0 && <List.List>{item.children.map(x => this.renderTree(x))}</List.List> }
      </List.Item>
    )
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.props.assets !== nextProps.assets || this.props.selectedNode !== nextProps.selectedNode
  }

  render() {
    return (
      <List className="m-1" id="tree">
        { this.props.assets.map(x => this.renderTree(x))}
      </List>
    );
  }
};

export default EGMTree;



