import React from 'react';
import { Grid, Header, Input, Table, Message, Modal, Button, Checkbox } from 'semantic-ui-react';
import {connect} from 'react-redux';
import DataGridRequest from '../shared/DataGridRequest'
import { showSuccessAlert, fetchAsset, fetchOrganisationByPosition, fetchInstallationsFilters, checkForUpdates, fetchInstallations, setEft, setAutorise, removeAsset, addPositions, setActiveInstallation, setAsset} from '../../actions';
import OptionsModal from '../modals/OptionsModal';
import { prepareFilter } from '../../actions/organisationDirectory';

class CurrentInstallations extends React.Component {

  initialValues = {
    activeOrg: null,
    activeSubOrg: null,
    editableMachine: false,
    installMachine: false,
    removeMachine: false,
    authInstall: false,
    organisation: null,
    modalInstallOpen: false,
    modalEditOpen: false,
    modalDeleteOpen: false,
    modalAddPosition: false,
    installations: {date:[],headerDef:[]},
    eftLabel: "",
    eftActive: null,
    AllowSuspendEnableEFT: false,
    loading: false,
    numPositions: "",
    addPositionError: false,
    modalErrorOpen: false,
    errorModalTitle: 'An Error Occured',
    errorModalContent: 'An unknown error occured.',
    removeLoading: false,
    assetNo: '',
    modalOpen: false,
    modalOptions: [],
    modalTitle: "Install Machine",
    modalSave: () => {},
    modalReload: () => {},
  }

  state = {
    showHistory: false,
    ...this.initialValues
  }

  resetState(){
    this.setState({...this.initialValues})
  }

  onRowClick = installation =>{
    //get the index from id
    const allowSuspendEnableEFT = (installation.optionAllowDisableEFT || installation.optionAllowEnableEFT)?true:false;
    var eftLabel;
    var eftActive;
    if(installation.optionAllowDisableEFT){
      eftLabel = "Disable";
      eftActive = true;
    }
    if(installation.optionAllowEnableEFT){
      eftLabel = "Enable";
      eftActive = false;
    }

    this.setState({
      activeInstallation: installation,
      activeSiteRef: "",
      editableMachine: installation.optionModify,
      installMachine: installation.optionInstall,
      authInstall: installation.optionAllowAuthorise,
      AllowSuspendEnableEFT: allowSuspendEnableEFT,
      removeMachine: installation.optionAllowRemove,
      eftLabel: eftLabel,
      eftActive: eftActive,
    }, () => {
      this.props.setActiveInstallation(installation)
    });
  }

  handleModalChange = ({ target }) => {
    this.setState(state => {
      if(state[target.name]){
        this.handleInstallationRefresh();
      }
      return {[target.name]: !state[target.name]}
    });
  };

  handleChange = ({ target }) => {
     const name = target.name;
     const value = target.value;
     if (this.state.hasOwnProperty(name)) {
       this.setState({ [name]: value });
     }
  }

  eftClick = () => {
    this.props.setEft(this.state.activeInstallation.installationId, this.state.eftActive).then(()=>{
      if(this.props.eftUpdate === 'OK'){
        this.handleInstallationRefresh();
      }else{
        this.setState({
          modalDeleteOpen: false,
          modalErrorOpen: true,
          errorModalTitle: 'EFT Update failed',
          errorModalContent: 'There was an error while while trying to update the EFT state.',
        })
      }
    });
  }

  autoriseClick = () => {
    this.props.setAutorise(this.state.activeInstallation.installationId).then(()=>{
      if(this.props.authorise === 'OK'){
        this.handleInstallationRefresh();
      }else{
        this.setState({
          modalDeleteOpen: false,
          modalErrorOpen: true,
          errorModalTitle: 'Unable Authorise',
          errorModalContent: 'There was an error while trying to authorise.',
        })
      }
    });
  }

  removeClick = () => {
    this.setState({ removeLoading: true }, () => {
      removeAsset(this.state.activeInstallation.installationId).then(
        () => this.setState({ removeMachine: false, removeLoading: false }, () => this.handleInstallationRefresh()),
        () => this.setState({ removeMachine: false, removeLoading: false })
      );
    });
  }

  refreshClick = () => {
    this.resetState();
    this.props.setActiveInstallation().then(() => {
      this.handleInstallationRefresh();
    })
  }

  handleInstallationRefresh = () => {
    this.setState({loading: true }, () => {
      const { filterSelected, filterData } = this.props.filters

      this.props.fetchInstallations(
        this.props.filters.filterSelected.operator,
        this.props.organisationId,
        this.state.showHistory,
        prepareFilter(filterSelected.regions, filterData.regions),
        prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        prepareFilter(filterSelected.game, filterData.game),
        prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
        
        ).then(() => {
        this.setState({loading: false });
      })
      .catch(() => {
        this.setState({loading: false});
      });
    });
  }

  addPositions = () => {
    this.props.addPositions(this.props.organisationId, this.state.numPositions).then(()=>{
      if(this.props.positionsAdd === 'OK'){
        this.handleInstallationRefresh();
        this.setState({modalAddPosition: false, addPositionError: false });
      }else {
        this.setState({addPositionError: true });
      }
    });
  }

  handleHistoryChange = () => {

    this.setState({ showHistory: !this.state.showHistory }, () => {
      this.handleInstallationRefresh()
    });
  }

  componentDidUpdate(prevProps){
    // Check for a change in the transaction list
    if(this.props.installations !== prevProps.installations){
      this.resetState();
    }
    if (checkForUpdates(prevProps.filters, this.props.filters)) {
      this.props.setActiveInstallation({}).then(() => this.handleInstallationRefresh());
    }
  }

  selectMachine = () => {
    this.fetchAsset(this.state.assetNo, this.state.activeInstallation.positionId)
  }

  fetchAsset = (assetNo, positionId) => {
    this.setState({ modalOptions: [], modalLoading: true }, () => {
      fetchAsset(assetNo, positionId).then(result => {
        this.setState( { modalOptions: result.options, modalLoading: false })
      },
      () => {
        this.setState({ modalLoading: false })
      })
    });
  }


  handleSaveInstall = async (changes, another, complete) => {
    await setAsset(this.props.activeInstallation.positionId, this.state.assetNo, changes, complete).then(result => {
      if (!complete) {
        this.setState( { modalOptions: result.options })
      } else {
        this.setState({ assetNo: "" }, () => {
          this.props.showSuccessAlert(result).then(() => {
            this.handleInstallationRefresh();
          })
        })
      }
    })
  }

  handleEditMachine = () => {
    fetchOrganisationByPosition(this.props.activeInstallation.positionId).then(result => {
      this.setState({
        modalOptions: result.options,
        modalOpen: true,
        modalSave: this.handleSaveInstall,
        modalReload: this.handleSaveInstall,
        assetNo: result.assetNo,
        modalTitle: `Edit Machine  -  ${result.companySite}`
      })
    })
  }
  handleInstallMachine = () => {
    fetchOrganisationByPosition(this.props.activeInstallation.positionId).then(result => {
      this.setState({
        modalOptions: [],
        modalOpen: true,
        modalSave: this.handleSaveInstall,
        modalReload: this.handleSaveInstall,
        modalTitle: `Install Machine  -  ${result.companySite}`
      })
    })
  }

  render() {
    var headings = [];
    var data = [];
    var rowDef = [];
    if(this.props.installations.grid){
      headings = this.props.installations.grid.headerDef;
      rowDef = this.props.installations.grid.rowDef;
      data = this.props.installations.grid.data;
    }

    const logIn = JSON.parse(sessionStorage.getItem('login')) || {};
    const prefix = this.state.installMachine ? <Table.Row key={`EGM Asset No`}>
      <Table.Cell width="9">EGM Asset No: <span style={{ color: "#db2828"}}>*</span></Table.Cell>
      <Table.Cell width="7">
        <Input value={this.state.assetNo} onChange={(event, { value }) => this.setState({ assetNo: value })} />
        <Button disabled={this.state.modalLoading} loading={this.state.modalLoading} onClick={this.selectMachine}>Search</Button>
      </Table.Cell>
    </Table.Row> : <span></span>

    return (
      <div className={ this.props.expanded ? "flex-vertical height-100" : "flex-vertical" }>
        <div className="flex-shrink mr-1">
        {this.state.modalErrorOpen && <Modal
            open={this.state.modalErrorOpen}
            size='small' className='width-auto'>
            <Header content={this.state.errorModalTitle} />
            <Modal.Content>
            <p>{this.state.errorModalContent}</p>
            <Button name="modalErrorOpen" disabled={this.state.loading} onClick={this.handleModalChange}>OK</Button>
            </Modal.Content>
          </Modal>
        }
        { this.props.filters.hasSelection && <Button loading={this.state.loading} disabled={this.state.loading} fluid onClick={this.refreshClick} positive>Refresh</Button> }
        {/* Remove Machine button */}
        {this.state.removeMachine && <Modal
            trigger={(
              <Button
              name="modalDeleteOpen"
              fluid
              loading={this.state.loading}
              disabled={this.state.loading}
              onClick={this.handleModalChange}
              >
              Remove Machine
              </Button>
            )}
            open={this.state.modalDeleteOpen}
            size='small'>
            <Header content={`Remove Machine Asset`} />
            <Modal.Content>
              Are you sure you want to remove this machine?
            </Modal.Content>
            <Modal.Actions>
              <Button positive onClick={this.removeClick} disabled={this.state.removeLoading || this.state.loading} loading={this.state.removeLoading || this.state.loading}>Remove</Button>
              <Button negative name="modalDeleteOpen" disabled={this.state.loading} loading={this.state.loading} onClick={this.handleModalChange}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        }

        {this.state.AllowSuspendEnableEFT && <Button fluid disabled={this.state.loading} loading={this.state.loading} onClick={this.eftClick}>{this.state.eftLabel}</Button>}

        {this.state.authInstall && <Button fluid disabled={this.state.loading} loading={this.state.loading} onClick={this.autoriseClick}>Authorise Install</Button>}

        {/* Edit Machine button */}
        {this.state.editableMachine && <Button
            name='modalEditOpen'
            onClick={this.handleEditMachine}
            disabled={this.state.loading}
            loading={this.state.loading}
            fluid>
            Edit Machine
            </Button>
          }

        { /* Install Machine */ }
        {this.state.installMachine && 
            <Button
            fluid
            disabled={this.state.loading}
            loading={this.state.loading}
            onClick={this.handleInstallMachine}>
            Install Machine
            </Button>
        }

        {/* Add Position button */}
        {this.props.organisationId && logIn.optionAllowAddPosition && <Modal
            trigger={(
              <Button
              name="modalAddPosition"
              disabled={this.state.loading}
              loading={this.state.loading}
              onClick={this.handleModalChange}
              fluid>
              Add Position
              </Button>
            )}
            open={this.state.modalAddPosition}
            size='small'>
            <Header content={`Add Position`} />
            <Modal.Content>
            {this.state.addPositionError && <Message warning>There was an error check your input.</Message>}
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <label>Num Positions to add</label>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Input name="numPositions" value={this.state.numPositions} onChange={this.handleChange}/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Button fluid onClick={this.addPositions}>Add Positions</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button fluid name="modalAddPosition" onClick={this.handleModalChange}>Cancel</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </Modal.Content>
          </Modal>}

          { this.props.filters.hasSelection &&
          <Checkbox toggle label="Show History" onChange={this.handleHistoryChange} checked={this.state.showHistory} /> }
        </div>
          <div className='flex-stretch gs-flex-container-vertical' style={{width: '150px', minHeight: "100px", height: this.props.expanded ? "100%" : "170px"}}>
          <OptionsModal prefix={prefix} open={this.state.modalOpen} options={this.state.modalOptions} title={this.state.modalTitle} onClose={() => this.setState({ modalOpen: false })} handleReload={this.state.modalReload} onSave={this.state.modalSave} />
          <DataGridRequest
            activeRow={this.props.activeInstallation}
            headings={headings}
            data={data}
            rowDef={rowDef}
            onRowClick={this.onRowClick}
            loading={this.state.loading}
            fullHeight
          />
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
	return {
    installations: state.installations,
    filters: state.installationsFilters,
    eftUpdate: state.eftUpdate,
  	authorise: state.authorise,
    assetRemove: state.assetRemove,
    positionsAdd: state.positionsAdd,
    activeInstallation: state.activeInstallation
  };
};

export default connect(mapStateToProps, {
  fetchInstallations,
  fetchAsset,
  showSuccessAlert,
  setEft,
  setAutorise,
  removeAsset,
  addPositions,
  setActiveInstallation,
  fetchInstallationsFilters,
})(CurrentInstallations);
