import jsonPlaceholder from '../api/jsonPlaceholder';
import FileSaver from 'file-saver';
import mime from 'mime-types';
import contentDisposition from 'content-disposition';

export const fetchReports = async () => {
	const response = await jsonPlaceholder.get(`Report/ListGroups`);
	return response.data.map((x, index) => {
		return {...x, id: index + 1}
	})
};

export const prepareParams = (params) => {
	const missing = 10 - params.length;
	let parsedParams = params.map(x => x === null ? '#' : x);

	for (let i = 0; i < missing; i++) {
		parsedParams.push("#")
	}
	
	return parsedParams.map((item) => encodeURIComponent(item)).join(",");
	
}

export const getReport = async (reportId, params) => {
	const queryString = prepareParams(params);
	const response = await jsonPlaceholder.get(`Report/Parameters2/${reportId},${queryString}`);
	return response.data
};

export const createReport = async (reportName, reportId, params) => {

	const queryString = prepareParams(Object.values(params));

	await jsonPlaceholder.get(
		`Report/Create2/${reportId},${queryString}`,
		{ responseType: 'arraybuffer' }
	).then((response) => {
		let blob = new Blob([response.data], { type: response.headers['content-type'] } )
		let extension = mime.extension(response.headers['content-type']).trim();
		let filename =reportName+"."+extension
		
		if (params["Summary/Detailed"]) {
			const type = params["Summary/Detailed"] === 1 ? "Summary" : "Detailed"
			filename = reportName+" "+type+"."+extension
		}

		if (response.headers['Content-Disposition']) {
			let disposition = contentDisposition.parse(response.headers['Content-Disposition']);
			if (disposition.parameters.filename) {
				filename = disposition.parameters.filename
			}
		}
		
		FileSaver.saveAs(blob, filename);
	});

}