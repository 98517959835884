import React from 'react';
import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SystemSettings from '../systemAdmin/SystemSettings';
import Organisation from '../systemAdmin/Organisation';
import OpeningHours from '../systemAdmin/OpeningHours';
import { fetchSettingsFilters } from '../../actions/index';
import Operators from '../systemAdmin/Operators';

class SystemAdminView extends React.Component {
  
  state = {
    activeIndex: 0
  }

  componentDidMount = () => {
    this.props.fetchSettingsFilters({ showOrgTree: false});
  }

  componentDidUpdate() {
    const panes = this.getPanes();
    if (panes[this.state.activeIndex].key === "Organisation") {
      this.props.fetchSettingsFilters({ showOrgTree: true});
    } else {
      this.props.fetchSettingsFilters({ showOrgTree: false});
    }
  }

  handleTabChange = (e, { activeIndex }) => {
      const panes = this.getPanes();
      if (panes[activeIndex].key === "Organisation") {
        this.props.fetchSettingsFilters({ showOrgTree: true});
      } else {
        this.props.fetchSettingsFilters({ showOrgTree: false});
      }
      this.setState({ activeIndex })
  }

  getPanes = () => {
    return [
      {
        key: "Operators",
        menuItem: 'Operators',
        pane: <Tab.Pane><Operators /></Tab.Pane>
      }, 
      {
        key: "Settings",
        menuItem: 'System Settings Profile',
        pane: <Tab.Pane><SystemSettings /></Tab.Pane>
      },
      {
        key: "Opening Hours",
        menuItem: 'Site Opening Hours Profiles',
        pane: <Tab.Pane><OpeningHours /></Tab.Pane>
      },
      {
        key: "Organisation",
        menuItem: 'Organisation',
        pane: <Tab.Pane><Organisation /></Tab.Pane>
      },
    ];
  }

  render() {
    const panes = this.getPanes()
    return (
      <div className="flex-stretch gs-flex-container-vertical mt-1 height-100 pb-1" >
        <Tab renderActiveOnly={false} activeIndex={this.state.activeIndex} panes={panes} onTabChange={this.handleTabChange} className="flex-stretch gs-flex-container-vertical mt-1 height-100 pb-1" />
      </div>
    );
  }
};

export default connect(null, { fetchSettingsFilters })(SystemAdminView);
