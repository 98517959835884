import React from 'react';
import { connect } from 'react-redux';
import { Slider } from "react-semantic-ui-range";
import { Button, Dropdown } from 'semantic-ui-react';

class RefreshSlider extends React.Component {
  state = {
    timerId: 0,
    refresh: 0,
    open: false
  };

  slider = {
      min: 0,
      max: 10,
      step: 1,
      onChange: value => {
        this.setState({ refresh: value }, () => {
          this.handleRefreshMinutes()
        })
      }
  }

  handleRefreshMinutes = () => {
    const minutes = this.state.refresh
    clearInterval(this.state.timerId);
    if(minutes>0){
      const timerId = setInterval(()=>{
        if (!this.props.disabled) this.props.refresh()
      }, minutes * 60000);
      this.setState({timerId});
    }
  }

  componentDidMount() {
    this.handleRefreshMinutes();
  }

  render() {
    const refreshTxt = this.state.refresh>0?`${this.state.refresh} min(s) refresh`: `No auto refresh`
    return (
      <Button.Group size="small" style={this.props.style}>
        <Button onClick={this.props.refresh} positive size="small" disabled={this.props.disabled || this.props.loading} loading={this.props.loading}>Refresh</Button>
        <Dropdown open={this.state.open} size="small" color={ this.state.refresh > 0 ? "yellow" : "green" } as={Button} disabled={this.props.disabled} className="icon active" icon='setting' direction="left" onClick={() => this.setState({ open: !this.state.open})}>
          <Dropdown.Menu fluid>
            <Dropdown.Item>
            {refreshTxt}
                <Slider label='this.state.refresh' name='refresh' disabled={this.props.disabled} value={this.state.refresh} color="red" settings={this.slider} />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
    );
  }
}

const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(RefreshSlider);
