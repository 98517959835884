import React from 'react'
import { connect } from 'react-redux'
import { setLogout, showSuccessAlert } from '../../actions'
import { useIdleTimer } from 'react-idle-timer'

const TimeoutCheck = (props) => {
  const handleOnIdle = event => {
    props.showSuccessAlert(
      "You have been logged out due to inactivity, please log in again to continue using Rimini Enterprise.",
      "Session Timeout", 
      () => { props.setLogout(); window.location = '/'; }
    );
  }

  useIdleTimer({
    timeout: 1000 * props.logIn.logOutSeconds || 300,
    onIdle: handleOnIdle,
    debounce: 500
  })

  return (
    <span />
  )
}



const mapStateToProps = (state, ownProps) => {
  return {
    logIn: state.logIn,
  };
};

export default connect(mapStateToProps, { setLogout, showSuccessAlert })(TimeoutCheck);
