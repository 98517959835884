import React from 'react';
import {connect} from 'react-redux';
import { Button, Modal, Header } from 'semantic-ui-react'
import { hideSuccessAlert } from '../../actions/modals';

class SuccessModal extends React.Component {

  onClose = () => {
    this.props.successAlert.onConfirm().then(() => this.props.hideSuccessAlert());
  }

  render() {
    const { visible, message, header } = this.props.successAlert;
    return (
        <Modal
          open={visible}
          onClose={this.onClose}
          closeIcon
          size="tiny"
        >
          <Header>{header || "Success"}</Header>
          <Modal.Content>
            {message}
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Ok"
              onClick={this.onClose}
              positive
            />
          </Modal.Actions>  
        </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    successAlert: state.successAlert
  };
};

export default connect(mapStateToProps, { hideSuccessAlert })(SuccessModal);