import React from 'react';
import { connect } from 'react-redux';
import { Accordion, Button, Checkbox, Form, Icon, Input, List, Select } from 'semantic-ui-react';
import { showSuccessAlert } from '../../actions/modals';
import SelectOperator from '../shared/SelectOperator';

class AssetTree extends React.Component {

  state = {
    devices: this.props.devices
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.devices !== prevProps.devices) {
      this.setState({ devices: this.props.devices })
    }
  }

  toggleStatus = (device) => {
    const devices = [...this.state.devices];
    const index = devices.findIndex(x => x.id === device.id)
    devices[index] = {...device, active: !device.active}
    this.setState({ devices })
  }

  render() {
    return (
      <Form className="gs-flex-container-vertical flex-stretch">
        <Form.Field inline>
          <SelectOperator fluid placeholder='Select an Operator' operators={this.props.operators} value={this.props.selectedOperator} name="selectedOperator" onChange={this.props.onOperatorChange} />
        </Form.Field>
 
        <Form.Group inline>
          <Input name='searchText' value={this.props.searchText} onChange={this.props.onSearchTextChange} className="w-100" icon='search' style={{ margin: "0 .2em 0 0" }} fluid placeholder='Search...' />
          <Button primary onClick={this.props.onSearchClick} disabled={!this.props.selectedOperator || this.props.loading} style={{ margin: "0", padding: "0.372em 0.9em" }}>Search</Button>
        </Form.Group>       
 
        <Form.Field inline>
          <label>Group By:</label>
          <Select fluid options={this.props.groupings} value={this.props.selectedGrouping} className="w-100" style={{ marginTop: "0.3em" }} />
        </Form.Field>
        <div id="devices" className="table-overflow flex-stretch-vertical border-radius">
          <Accordion className="p-1 noselect">
          {this.state.devices.map(device => {
            const hasChildren = device.children.length > 0 && device.active
            const icon = device.children.length < 1 || device.active ? "minus square outline" : "plus square outline";
            return (
              <div>
                <Accordion.Title onClick={() => this.toggleStatus(device)}>
                  <Icon name={icon} />
                  {device.name} 
                </Accordion.Title>
                { hasChildren &&
                <Accordion.Content active={hasChildren}>
                  <List className="ml-1 deviceStatus">
                  {device.children.map(child => {
                    return <List.Item className="ml-1 clickable" active={this.props.selectedDevice === child.id} onClick={() => this.props.onDeviceClick(child.id)}>{ child.name }</List.Item>
                  })}
                  </List>

                </Accordion.Content>
                }
              </div>
            )
          })}
          </Accordion>
        </div>

        <Accordion>
          <Accordion.Title onClick={null} active={true}>
             Device Status
          </Accordion.Title>
          <Accordion.Content active={true} className="table-overflow p-1 border-radius">
          { this.props.statuses.map(item => {
              return <div><Checkbox label={item.name} inverted checked={this.props.selectedStatuses.filter(x => x.id === item.id).length > 0} onClick={() => this.props.onStatusClick(item)} key={item.id} /></div>
          }) }
          </Accordion.Content>
        </Accordion>
      </Form>
    )
  }
};

export default connect(null, { showSuccessAlert })(AssetTree);
