const initialState = { message: "", visible: false, onConfirm: () => {}}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'CONFIRM_MODAL':
			return action.payload;
		default:
			return state;
	}
};
