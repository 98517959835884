import jsonPlaceholder from '../api/jsonPlaceholder';
import { prepareDetails } from './settings';
import { buildTree } from './egm';

class Device {

	fetchSelect = async (url) => {
		const response = await jsonPlaceholder.get(url);
		return this.mapToSelect(response.data)
	}

	getStatuses = async () => {
		const response = await jsonPlaceholder.get(`/Device/statuses`);
		return response.data
	}
	
	getGroupBy = async () => {
		return await this.fetchSelect(`/Device/groupby`);
	}

	getDevices = async (operatorId, statuses, groupBy, searchText) => {
		const search = searchText.length > 0 ? encodeURIComponent(searchText) : encodeURIComponent('#');
		const response = await jsonPlaceholder.get(`Device/devices/${operatorId},${statuses},${groupBy},${search}`);
		return buildTree(response.data);
	}

	getDevice = async (deviceId) => {
		const response = await jsonPlaceholder.get(`device/device/${deviceId}`)
		return response.data
	}

	getOperators = async () => {
		const response = await jsonPlaceholder.get(`device/operators`)
		return this.mapToSelect(response.data)
	}

	/**
	 * Get list of controls for new device modal. If previousDeviceId is passed, API sets the value of the order number device control to the supplied device id's order number (For create another).
	 * @param {int} operatorId 
	 * @param {int} previousDeviceId 
	 * @returns 
	 */
	newDevice = async (operatorId, previousDeviceId = 0) => {
		const response = await jsonPlaceholder.get(`device/new/${operatorId},${previousDeviceId}`)
		return response.data
	}

	updateDevice = async (id, operatorId, details) => {
		const response = await jsonPlaceholder.put(`device/update`, { id, operatorId, details: prepareDetails(details) } )
		return response.data
	}

	decommission = async (id) => {
		const response = await jsonPlaceholder.put(`device/decommission/${id}`)
		return response.data
	}

	unassign = async (id) => {
		const response = await jsonPlaceholder.put(`device/unassign/${id}`)
		return response.data
	}

	mapToSelect = (data) => {
		return data.map(row => {
			return { key: row.id, value: row.id, text: row.name}
		})
	}

}

export default new Device(); 