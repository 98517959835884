import React from 'react';
import { Input, Select } from 'semantic-ui-react'


class SelectOperator extends React.Component {

  render = () => {
    
    const { operators, value, onChange } = this.props;
    const fluid = this.props.fluid != null;

    if (operators.length === 1) {
      return <Input readOnly className={this.props.className} fluid={fluid} value={operators[0].text}/>
    } else {
      return <Select search className={this.props.className} fluid={fluid} name="selectedOperator" options={operators} value={value} placeholder={this.props.placeholder} onChange={onChange} />
    }
  }
}

export default SelectOperator;
