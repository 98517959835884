import React from 'react'
import { Dropdown } from 'semantic-ui-react'

class SelectProfile extends React.Component {


    render() {
        return (
            <Dropdown
                placeholder='Select A Profile'
                search
                selection
                options={this.props.profiles}
                onChange={this.props.onChange}
                value={this.props.value}
                disabled={this.props.disabled}
                className={this.props.className}
            />
        )
    }

}

export default SelectProfile