import React from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { fetchOperators } from '../../actions/index.js';
import Filters from './Filters';
import OperatorSettings from './OperatorSettings';
import OperatorRegions from './OperatorRegions';

class Operators extends React.Component {
  
  state = {
    activeIndex: 0,
    selectedOperator: null 
  }

  handleTabChange = (e, { activeIndex }) => {
      this.setState({ activeIndex })
  }

  componentDidMount() {
    if (this.props.operators.length === 1) {
      this.setState({ selectedOperator: this.props.operators[0].key })
    }
  }

  getPanes = () => {
    return [
      {
        key: "Settings",
        menuItem: 'Settings',
        pane: <Tab.Pane><OperatorSettings selectedOperator={this.state.selectedOperator} onOperatorChange={this.handleOperatorSelect} /></Tab.Pane>
      },
      {
        key: "Regions",
        menuItem: 'Regions',
        pane: <Tab.Pane><OperatorRegions selectedOperator={this.state.selectedOperator} /></Tab.Pane>
      }
    ];
  }

  handleOperatorSelect = (event, { value }) => this.setState({ selectedOperator: value })

  render() {
    const panes = this.getPanes()
    return (
      <div className="flex-stretch gs-flex-container-vertical height-100 pb-1" >
        <Filters
          onOperatorSelect={this.handleOperatorSelect}
          operators={this.props.operators}
          selectedOperator={this.state.selectedOperator}
        />
        <Tab renderActiveOnly={false} activeIndex={this.state.activeIndex} panes={panes} onTabChange={this.handleTabChange} className="flex-stretch gs-flex-container-vertical mt-1 height-100" />
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
	return {
    operators: state.operators.filter(x => x.value !== -1),
    logIn: state.logIn
  }
}

export default connect(mapStateToProps, { fetchOperators })(Operators);

