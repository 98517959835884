const initialState = {
	metersGrid: {},
	eventsGrid: {},
	summarysGrid: {}
}
export default (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_COLLECTION_BY_REF':
			return {...initialState, ...action.payload};
		default:
			return state;
	}
};
