import React from 'react';
import { Tab } from 'semantic-ui-react';
import HardwareGeneral from '../deviceManagement/HardwareGeneral';

class DeviceManagementView extends React.Component {

  state = {
    activeIndex: 0
  }

  handleTabChange = (e, { activeIndex }) => {
      this.setState({ activeIndex })
  }

  getPanes = () => {
    return [
      {
        key: "System Hardware - General",
        menuItem: 'System Hardware - General',
        pane: <Tab.Pane><HardwareGeneral /></Tab.Pane>
      },
    ];
  }

  render() {
    const panes = this.getPanes()
    return (
      <div className="flex-stretch gs-flex-container-vertical mt-1 height-100" >
        <Tab renderActiveOnly={false} activeIndex={this.state.activeIndex} panes={panes} onTabChange={this.handleTabChange} className="flex-stretch gs-flex-container-vertical mt-1 height-100 pb-1" />
      </div>
    );
  }
}

export default DeviceManagementView;
