import React from 'react';
import { Icon, Menu } from 'semantic-ui-react'

class MainMenu extends React.Component {

  onItemClick = (event, {name, value}) => {
    this.props.onSubmit(name);
  }

  render() {
      const menuOptions = this.props.menu.map(item=>{
        return (
          <Menu.Item color='black' key={item.code} name={item.code} inverted="true" onClick={this.onItemClick}>
              { !this.props.collapsed && item.name}
              <Icon name={ item.icon ? item.icon : 'area graph' } />
          </Menu.Item>
        );
      });
    return (
      <Menu vertical inverted color='blue' icon={this.props.collapsed} fixed='left' style={{ backgroundColor: "#f7a711"}}>
        <Menu.Item onClick={this.props.onCollapse}>
            { this.props.collapsed ?
              <Icon name="bars" />
            :
              <span>
        
                <img src="logo-horizontal.png" alt="Logo" id="logo-horizontal" />
                <Icon name="chevron left" className="float-right" style={{ marginTop: "27px"}} />
              </span>
            }
        </Menu.Item>
        
        { menuOptions }

        <Menu.Item
          name='spam'
          onClick={this.props.onLogout}>
            { !this.props.collapsed && <span>Log Out</span> }
            <Icon name='sign-out' />
        </Menu.Item>

      </Menu>
    );
  }
};

export default MainMenu;
