
export const toggleJira = (opacity) => {
    const interval = setInterval(
        () => {
            let widget = document.getElementById("jsd-widget");
            if (widget) {
                widget.style.opacity = opacity;
                clearInterval(interval)
            }
        },
        400
    );
}