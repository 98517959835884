import React from 'react';
import { Tab } from 'semantic-ui-react';
import AccessProfiles from '../useradmin/AccessProfiles';
import UserAccounts from '../useradmin/UserAccounts';

class UserAdminView extends React.Component {
  
  state = {
    activeIndex: 0
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex })
  }

  getPanes = () => {
    return [
      {
        key: "User Accounts",
        menuItem: 'User Accounts',
        pane: <Tab.Pane><UserAccounts /></Tab.Pane>
      },
      {
        key: "User System Access Profiles",
        menuItem: 'User System Access Profiles',
        pane: <Tab.Pane><AccessProfiles /></Tab.Pane>
      }  
    ];
  }


  render() {
    const panes = this.getPanes()
    return (
      <div className="flex-stretch gs-flex-container-vertical mt-1 height-100 pb-1" >
        <Tab renderActiveOnly={false} activeIndex={this.state.activeIndex} panes={panes} onTabChange={this.handleTabChange} className="flex-stretch gs-flex-container-vertical mt-1 height-100 pb-1" />
      </div>
    );
  }
};

export default UserAdminView;
