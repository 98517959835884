import jsonPlaceholder from '../api/jsonPlaceholder';
import { prepareDetails } from './settings';

export const fetchUserAccessProfiles = async (operatorId, search) => {

	const searchTerm = search === "" ? "#" : search;

	const profiles = await jsonPlaceholder.get(`/UserAccessProfile/profiles/${operatorId},${encodeURIComponent(searchTerm)}`);
	return profiles.data.map(x => {
		return {key: x.id, value: x.id, text: x.name }
	})
}

export const fetchSecurityUsers = async (operatorId, search) => {
	const searchTerm = search === "" ? "#" : search;
	const response = await jsonPlaceholder.get(`/Security/users/${operatorId},${encodeURIComponent(searchTerm)}`);
	const profiles = response.data.filter(x => x.type === "P");

	return profiles.map(parent => {
		return {...parent, children: response.data.filter(child => child.parent === parent.parent && child.type === "U")}
	})
}

export const fetchSecurityUser = async (userId) => {
	const response = await jsonPlaceholder.get(`/Security/user/${userId}`);
	return response.data;
}

export const fetchUserAccessProfile = async (profileId, fromAdmin = true) => {
	const profiles = await jsonPlaceholder.get(`UserAccessProfile/profile/${profileId},${fromAdmin}`);
	return profiles.data
}

export const updateAccessProfile = async (profileId, OperatorId, details) => {
	const payload = {Id : profileId, OperatorId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/UserAccessProfile/update`, payload)
	return response.data
}

export const getAccessProfile = async (operatorId) => {
	const response = await jsonPlaceholder.get(`/UserAccessProfile/new/${operatorId}`)
	return response.data
}

export const getSecurityUser = async (operatorId, profileId) => {
	const response = await jsonPlaceholder.get(`/Security/newuser/${operatorId},${profileId}`)
	return response.data
}

export const resetUserPassword = async (email) => {
	const response = await jsonPlaceholder.put(`/Security/ResetPassword/${email}`)
	return response.data
}

export const updateSecurityUser = async (userId, OperatorId, ProfileId, details) => {
	const payload = {id : userId, OperatorId, ProfileId, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/Security/update/`, payload)
	return response.data
}

export const getUserDataAccess = async (OperatorId, UserId, AccessType) => {
	const response = await jsonPlaceholder.get(`/Security/dataaccess/${OperatorId},${UserId},${AccessType}`)
	return response.data
}

export const updateUserDataAccess = async (userId, AccessTypeDetails, details) => {
	const payload = {id : userId, AccessTypeDetails, details: prepareDetails(details)}
	const response = await jsonPlaceholder.put(`/Security/update/`, payload)
	return response.data
}