import React from 'react';

const InfoLabel = (props) => {
    let classes = props.collapsing ? "ui basic label summary-label" : "ui basic label summary-label w-100";
    if (props.right) classes += " text-right"

    return (
        <input readonly disabled className={classes} basic value={props.children ? props.children : " "} />
    )
}

export default InfoLabel;