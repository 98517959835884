import React from 'react';
import {connect} from 'react-redux';
import { Button, Header, Input, Modal, Grid } from 'semantic-ui-react'
import InfoLabel from '../shared/InfoLabel';
import { fetchLogin } from '../../actions';

class TransactionTransferAdjustModal extends React.Component {

  state = {
    loading: false,
    newVal: this.props.transactionDetails.origEFTCash.replace(this.props.logIn.currencySymbol,''),
  };

  handleAuthorise = () => {
    const callback = () => this.setState({ loading: false })
    this.setState({ loading: true }, () => this.props.updateValue(this.state.newVal).then(callback, callback));
  }

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  render() {
    return (
        <Modal
            open={this.props.modalOpen}
            onClose={this.props.handleClose}
            size='mini'
            closeIcon
        >
            <Header content={this.props.modalTitle} />
            <Modal.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={9}>
                  <label>Transaction Id</label>
                  </Grid.Column>
                  <Grid.Column width={7}>
                  <InfoLabel right>{this.props.transactionDetails.transactionId}</InfoLabel>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={9}>
                  <label>Current Value</label>
                  </Grid.Column>
                  <Grid.Column width={7}>
                  <InfoLabel right>{this.props.transactionDetails.origEFTCash}</InfoLabel>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={9}>
                  <label>Adjusted Value</label>
                  </Grid.Column>
                  <Grid.Column width={7} id="adjustAmountContainer"><Input name="newVal" id="adjustAmount" fluid value={this.state.newVal} onChange={this.handleChange}/></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                  <Button positive loading={this.state.loading} disabled={this.state.loading} fluid onClick={this.handleAuthorise}>Authorise</Button>
                  </Grid.Column>
                  <Grid.Column width={8}><Button negative fluid onClick={this.props.handleClose}>Cancel</Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
        </Modal>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
	return {
    logIn: state.logIn,
  };
};

export default connect(mapStateToProps, {
  fetchLogin,
})(TransactionTransferAdjustModal);
