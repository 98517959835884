import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';
import { prepareFilter } from '../../actions';
import Device from '../../actions/devicemanagement';
import { showSuccessAlert, showPasswordModal, confirmModal } from '../../actions/modals';
import DataGridRequest from '../shared/DataGridRequest';
import OptionsList from '../systemAdmin/OptionsList';
import AssetTree from './AssetTree';
import OptionsModal from '../modals/OptionsModal';
import BaseSettings from '../systemAdmin/BaseSettings';

class HardwareGeneral extends BaseSettings {

  constructor() {
    super();
    this.state = {
      ...this.state,
      selectedOperator: null,
      selectedStatuses: [],
      selectedGrouping: { key: 0 },
      searchText: "",
      selectedDevice: { id: 0, options: [], grid: { data: [], rowDef: [], headerDef: [] } },
      groupings: [],
      operators: [],
      statuses: [],
      devices: [],
      modalOptions: [],
      modalOpen: false,
      loading: false
    };
  }

  componentDidMount() {
    Device.getStatuses().then(statuses => this.setState({statuses, selectedStatuses: statuses}))
    Device.getGroupBy().then(groupings => this.setState({ groupings, selectedGrouping: groupings[0] }))
    Device.getOperators().then(operators => {
      this.setState({ operators }, () => {
        if (this.state.operators.length === 1) {
          this.handleOperatorChange(null, { value: this.state.operators[0].value })
        }
      })
    })


  }

  handleOperatorChange = (event, { name, value }) => {
    this.setState({ selectedOperator: value, devices: [], selectedDevice: { id: 0, options: [], grid: { data: [], rowDef: [], headerDef: [] } } })
  }

  handleStatusClick = (status) => {
    const { selectedStatuses } = this.state;
    const statuses = selectedStatuses.find(x => x.id === status.id) ? [...selectedStatuses.filter(x => x.id !== status.id )] : [...selectedStatuses,status];
    this.setState({ selectedStatuses: statuses })
  }

  handleDeviceClick = (id) => {
    Device.getDevice(id).then(result => {
      const selectedDevice = {...result,id}
      this.setState({ selectedDevice, changed: [] })
    })
  }

  handleSearch = async () => {
    let { selectedOperator, selectedStatuses, statuses, selectedGrouping, searchText } = this.state;
    await this.setState({ loading: true })
    const devices = await Device.getDevices(
      selectedOperator,
      prepareFilter(selectedStatuses, statuses.map(x => x.id)),
      selectedGrouping.key,
      searchText
    ).catch(() => this.setState({ loading: false }))
    
    await this.setState({ devices, loading: false });
  }

  handleNewDeviceClick = () => {
    Device.newDevice(this.state.selectedOperator).then(modalOptions => this.setState({ modalOptions, modalOpen: true }))
  }

  saveNewDevice = async (changed, another) => {
    await Device.updateDevice(0, this.state.selectedOperator, changed).then(id => {
      if (another) {
        Device.newDevice(this.state.selectedOperator, id).then(modalOptions => {
          this.setState({ modalOptions }, () => this.props.showSuccessAlert('New Device Successfully Created!'));
        })
      } else {
        this.setState({ modalOpen: false, searchText: "", modalOptions: [] }, () => {
          this.handleSearch().then(() => this.handleDeviceClick(id));
          this.props.showSuccessAlert('New Device Successfully Created!')
        })
      }
    })
  }

  handleUnassign = () => {
    const callback = () => {
      Device.unassign(this.state.selectedDevice.id).then(() => {
        this.setState({ searchText: "" }, () => {
          this.handleSearch().then(() => {
            this.handleDeviceClick(this.state.selectedDevice.id);
            this.props.showSuccessAlert("Device Successfully Unassigned!")
          })
        })
      })
    }

    this.props.confirmModal("Are you sure you want to unassign this device?", true, callback)
  }

  handleDecommission = () => {
    const callback = () => {
      Device.decommission(this.state.selectedDevice.id).then(() => {
          this.setState({ searchText: "" }, () => {
            this.handleSearch().then(() => {
              this.handleDeviceClick(this.state.selectedDevice.id);
              this.props.showSuccessAlert("Device Successfully Decommissioned!")
            })
          })
      })
    }
    
    this.props.showPasswordModal("Decommission Device", "Are you sure you want to decommission this device?", callback);
  }

  handleSave = () => {
    Device.updateDevice(this.state.selectedDevice.id, this.state.selectedOperator, this.getChanged()).then(result => {
      this.setState( { searchText: "", changed: [] }, () => {
        this.handleSearch()
        this.props.showSuccessAlert('Device Successfully Updated!');
        this.handleDeviceClick(this.state.selectedDevice.id);
      });
    })
  }

  render() {
    const widescreen = 2;
    const large = 3;
    const computer = 4;
    const tablet = 5;
    const { devices, loading, operators, statuses, searchText, selectedDevice, selectedStatuses, selectedOperator, selectedGrouping, groupings } = this.state;

    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row className="flex-stretch">
          <Grid.Column tablet={tablet} widescreen={widescreen} largeScreen={large} computer={computer} className="gs-flex-container-vertical">
            <OptionsModal open={this.state.modalOpen} another={true} options={this.state.modalOptions} title="New Device" onClose={() => this.setState({ modalOpen: false })} onSave={this.saveNewDevice} />
            <AssetTree 
              loading={loading} 
              onSearchClick={this.handleSearch}
              onStatusClick={this.handleStatusClick}
              onOperatorChange={this.handleOperatorChange}
              onDeviceClick={this.handleDeviceClick}
              onSearchTextChange={(event, { value }) => this.setState({ searchText: value })}
              selectedOperator={selectedOperator}
              selectedGrouping={selectedGrouping.key}
              selectedDevice={selectedDevice.id}
              selectedStatuses={selectedStatuses}
              searchText={searchText}
              devices={devices.map(x => { return {...x, active: true } })}
              statuses={statuses}
              groupings={groupings}
              operators={operators}
            />
          </Grid.Column>
          <Grid.Column tablet={16 - tablet} widescreen={16 - widescreen} largeScreen={16 - large} computer={16 - computer} className="gs-flex-container-vertical">
            { this.state.selectedDevice.options.length > 0 &&
              <div className="table-overflow p-1 flex-stretch-vertical">
                <Header>Device Settings</Header>
                <OptionsList options={this.mapChanged(this.state.selectedDevice.options)} onChange={this.handleChangeValue} />     
              </div>
            }
            { this.state.selectedDevice.grid.data.length > 0 && 
              <div className="mt-1 p-1 gs-flex-container-vertical grey-border" style={{ height: "40%"}}>
                <Header as='h3'>Device History</Header> 
              <DataGridRequest
                headings={this.state.selectedDevice.grid.headerDef}
                data={this.state.selectedDevice.grid.data}
                rowDef={this.state.selectedDevice.grid.rowDef}
                onRowClick={this.onRowClick}
                loading={this.state.loading}
				        fullHeight
              />
              </div>          
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <Button icon color='blue' labelPosition='right' disabled={this.state.selectedOperator === null || this.state.loading} onClick={this.handleNewDeviceClick}>
              New Device
              <Icon name='add' />
            </Button>

            { this.state.selectedDevice.optionAllowSave &&
            <Button icon positive labelPosition='right' disabled={!this.isValid() || this.state.loading} onClick={this.handleSave}>
              Save Changes
              <Icon name='save' />
            </Button>
            }
          
            { this.state.selectedDevice.optionAllowDecommission && 
            <Button icon color='red' labelPosition='right' disabled={this.state.selectedDevice.id < 1 || this.state.loading} onClick={this.handleDecommission}>
              Decommission Device
              <Icon name='delete' />
            </Button>
            }

            { this.state.selectedDevice.optionAllowUnassign && 
            <Button icon color='red' labelPosition='right' disabled={this.state.selectedDevice.id < 1 || this.state.loading} onClick={this.handleUnassign}>
              Unassign Device
              <Icon name='minus' />
            </Button>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

export default connect(null, { showSuccessAlert, showPasswordModal, confirmModal })(HardwareGeneral);

