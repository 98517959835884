import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Header, Message, Modal, Label } from 'semantic-ui-react';
import { closeSession, closeSessionDetails } from '../../actions';
import { confirmModal, showSuccessAlert } from '../../actions/modals';
import DataGridRequest from '../shared/DataGridRequest';

class SessionDetailsModal extends React.Component {

  state = { 
    closeLoading: false
  }

  handleCloseSessionClick = () => {
    const fn = () => {
      const { sessionId } = this.props.session
      this.setState({ closeLoading: true }, () => {
        closeSession(sessionId).then(
          response => this.props.showSuccessAlert(response, "Success", async () => this.props.handleRefresh().then(() => this.setState({ closeLoading: false }))),
          () => this.setState({ closeLoading: false })
        )
      })
    }

    this.props.confirmModal("Are you sure you wish to close this session?", true, fn)
  }

  handleClose = () => this.props.closeSessionDetails();

  render() {
    if (!this.props.session.assetNo) return <></>
    return (
        <Modal
            open={this.props.session.modalOpen}
            onClose={this.handleClose}
            size='fullscreen'
            closeIcon
            className="h-80"
        >
            <Header content={`Session Details ${this.props.session.sessionId}`} />
            <Modal.Content className="h-100">
              <Grid className="height-100 flex-container">
                {(this.props.session.isSuspicious && this.props.session.suspiciousReason) &&
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Message negative>{this.props.session.suspiciousReason}</Message>
                    </Grid.Column>
                  </Grid.Row>
                }
                <Grid.Row>
                  <Grid.Column width="12">
                    <b>{this.props.session.site}</b> - {this.props.session.gameName}
                    {this.props.logIn.optionShowPlayerID && <span className="ml-2"><b>CUSTOMER ID:</b> {this.props.session.playerID || "N/A"}</span>}
                  </Grid.Column>
                  <Grid.Column textAlign="right" width="4">
                   { this.props.session.optionAllowClose && <Button negative icon="close" content="Close Session" loading={this.state.closeLoading} onClick={this.handleCloseSessionClick} /> }
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="16">
                    <b>SESSION START:</b> {this.props.session.startDate}
                    <span className="ml-2"><b>SESSION END:</b> {this.props.session.endDate}</span>
                    <span className="ml-2"><b>DURATION (HH:MM)</b> {this.props.session.duration || "N/A"}</span>
                    { this.props.session.manualCloseDetail && <Label className="ml-2">{this.props.session.manualCloseDetail}</Label> }
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="flex-stretch-vertical">
                  <Grid.Column width={5} className="flex-container">
                    <DataGridRequest
                      stretched
                      headings={this.props.session.summarysGrid.headerDef}
                      rowDef={this.props.session.summarysGrid.rowDef}
                      data={this.props.session.summarysGrid.data} onRowClick={function () { }}
                      fullHeight
                    />
                  </Grid.Column>
                  <Grid.Column width={11} className="flex-container">
                    <DataGridRequest styleInf={{ height: 200 }}
                      headings={this.props.session.eventsGrid.headerDef}
                      rowDef={this.props.session.eventsGrid.rowDef}
                      data={this.props.session.eventsGrid.data} onRowClick={function () { }}
                    />
                    <br />
                    <DataGridRequest
                      headings={this.props.session.metersGrid.headerDef}
                      rowDef={this.props.session.metersGrid.rowDef}
                      noResults="Session open, meter data not available."
                      data={this.props.session.metersGrid.data} onRowClick={function () { }}
                      fullHeight
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
        </Modal>
    )
  }
}

const mapStateToProps = state => {
	return {
    logIn: state.logIn,
    session: state.session
  };
};
export default connect(mapStateToProps, { confirmModal, showSuccessAlert, closeSessionDetails })(SessionDetailsModal);