import React from 'react';
import { Menu, Icon, Loader } from 'semantic-ui-react'

class ReportsList extends React.Component {

  render() {
    return (
      <Menu vertical fluid pointing compact>
        <Menu.Item>
          <Menu.Header>{ this.props.title || <span><Icon name='chart area' color='blue' /> My Reports</span> }</Menu.Header>
        </Menu.Item>
        
      { this.props.reports.length ?
        <React.Fragment>
        { this.props.reports.map((item, index) => {
          return (
            <Menu.Item
            name={item.name}
            active={this.props.selected.id === item.id}
            onClick={() => this.props.onSelection(item)}
          >
            {item.name}

            { (this.props.selected.id === item.id && this.props.loading) &&
              <Loader active inline size="tiny" style={{ float: "right" }} />
            }
            
          </Menu.Item>
          )
        }) }          
        </React.Fragment>
      :
       <Menu.Item>No reports available.</Menu.Item> 
      }
      </Menu>
    );
  }
};



export default ReportsList;
