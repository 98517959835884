import jsonPlaceholder from '../api/jsonPlaceholder';
import { emptyGrid } from './datagrid';

export const fetchCommsViewer = (StartDate, EndDate, OperatorId, CompanyId, SubCompanyId, SiteId, Manufacturers, GameNames, Categories, OperatorRegions, OnlineEFTEnabled, OfflineSiteOpen, Pending, AuthReq, OfflineSiteClosed, OnlineEFTDisabled, OnlineNoFunds) => async dispatch => {
	const params = { 
		StartDate, 
		EndDate, 
		OperatorId, 
		CompanyId: CompanyId || -1, 
		SubCompanyId: SubCompanyId || -1,
		SiteId: SiteId || -1, 
		OperatorRegions,
		Manufacturers,
		GameNames,
		Categories,
		OnlineEFTEnabled,
		OfflineSiteOpen,
		Pending,
		AuthReq,
		OfflineSiteClosed,
		OnlineEFTDisabled,
		OnlineNoFunds,
		PageNumber: 1
	}

	const response = await jsonPlaceholder.put(`comms/filterbymachine2`, params)
	dispatch({type: 'FETCH_COMMS_VIEWER', payload: response.data});
};

export const fetchOperationalCommsViewer = (OperatorId, CompanyId, SubCompanyId, SiteId, Manufacturers, GameNames, Categories, PeriodId, FilterId) => async dispatch => {

	const data = {
		OperatorId, 
		CompanyId: CompanyId || -1, 
		SubCompanyId: SubCompanyId || -1,
		SiteId: SiteId || -1, 
		Manufacturers,
		GameNames,
		Categories,
		PeriodId,
		FilterId
	}

	const response = await jsonPlaceholder.put(`comms/filterbyoperation/`, data);
	dispatch({type: 'FETCH_COMMS_VIEWER', payload: response.data});
}


export const emptyCommsViewerSection = (clearFilters = true) => async dispatch => {
	dispatch({type: 'FETCH_COMMS_VIEWER', payload: emptyGrid});
	if (clearFilters) dispatch({type: 'FETCH_COMMS_VIEWER_FILTERS', payload: {}});
}

export const updateCommsViewer = (data) => async dispatch => {
	dispatch({type: 'FETCH_COMMS_VIEWER', payload: data});
}

export const getCommsViewerEvents = async (installationId, periodId) => {
	const response = await jsonPlaceholder.get(`Installation/EventsByPeriod/${installationId},${periodId},false`)
	return response.data
}

export const getPeriodFilterOptions = async () => {
	const response = await jsonPlaceholder.get(`comms/periodfilter`)
	return response.data.map(period => {
		return { key: period.id, value: period.id, text: period.name}
	})
}

export const getFilterReports = async (operatorId) => {
	const response = await jsonPlaceholder.get(`comms/filter3/${operatorId}`)
	return response.data.map(report => {
		return { key: report.id, value: report.id, text: report.name}
	})
}

export const getCommsViewerSessions = async (installationId, periodId, showCashSessions) => {
	const response = await jsonPlaceholder.get(`sessions/commsui/sessionsbyperiod2/${installationId},${periodId},${showCashSessions}`)
	return response.data
}

export const getCommsViewerSession = async (sessionid) => {
	const response = await jsonPlaceholder.get(`sessions/commsui/${sessionid}`)
	return response.data
}