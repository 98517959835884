import React from 'react';
import { Form } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';
import { UIDateFormat, APIDateFormat } from '../../config/dates';
import moment from 'moment';

const ReportsDatepicker = (props) => {

    const handleChange = (event, { name, value }) => {
        const formatDate = moment(value, UIDateFormat).format(APIDateFormat)
        props.onChange(name, formatDate)
    }

    return (
        <Form.Field inline>
            <label>{props.text}</label>
            <DateInput
            className="ui input"
            name={props.text}
            placeholder="Choose a date.."
            iconPosition="left"
            animation="fade"
            popupPosition="bottom center"
            closable
            autoComplete="off"
            dateFormat={UIDateFormat}
            value={ props.value && moment(props.value)}
            onChange={handleChange}
            />
        </Form.Field>
    )
    
}

export default ReportsDatepicker