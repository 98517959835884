const initialState = {
	grid: {
		data: [],
		headerDef: [],
		rowDef: []
	},
	summary: {
		data: [],
		headerDef: [],
		rowDef: []
	}
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_COLLECTIONS':
			return {...state, ...action.payload};
		default:
			return state;
	}
};
