import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Grid, Icon, Input, Select } from 'semantic-ui-react';
import {
  checkForUpdates, emptyCollectionsSection,
  fetchBlankCollectionsSummary, fetchCollectionByRef,
  fetchCollectionsFilters, setCollectionsInstallations
} from '../../actions';
import { UIDateFormat } from '../../config/dates';
import BaseCollections from '../collections/BaseCollections';
import period from '../helpers/collections';
import CollectionDetailsModal from '../modals/CollectionDetailsModal';
import DataGridRequest from '../shared/DataGridRequest';
import DownloadButton from '../shared/DownloadButton';

class Collections extends BaseCollections {


  componentDidUpdate(prevProps, prevState) {
    if (this.props.filters.filterSelected.operator !== prevProps.filters.filterSelected.operator) {
      fetchBlankCollectionsSummary(this.props.filters.filterSelected.operator).then(
        summary => this.props.setCollections({ summary }).then(this.setState({ loading: false })),
        () => this.setState({ loading: false })
      )
    }

    if (checkForUpdates(prevProps.filters, this.props.filters) || this.props.installation !== prevProps.installation) {
      if (this.props.installation || this.state.showAllCollections) {
        this.updateCollections();
      }
    }
  }

  handleAllCollectionsChange = () => {
    this.setState({ showAllCollections: !this.state.showAllCollections }, () => {
      if (!this.state.showAllCollections && !this.props.installation) this.props.setCollections({ grid: { data: [], rowDef: [], headerDef: [] }})
    })
  }

  searchSegment = () => {
    const periodType = period.find(({value}) => value === this.state.searchParams.periodType)
    return (
      <form onSubmit={(e)=>{e.preventDefault()}} className="ui form">
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
            <Button color='blue' icon size='mini' disabled={!this.props.filters.filterSelected.operator} onClick={() => this.setState( {showSummary: !this.state.showSummary })}>
              <Icon name="pin" rotated={ !this.state.showSummary ? "clockwise" : null } />
            </Button>
            <span className="mr-1"><DownloadButton disabled={!this.props.installation && !this.state.showAllCollections} onClick={this.loadExport} /></span>
            <label style={{ marginRight: "6px"}}>Period:</label>
            <Select
              value={this.state.searchParams.periodType}
              options={period}
              onChange={(event, { name, value }) => this.handleChange(name, value)}
              style={{ width: "110px", minWidth: "auto" }}
              name='periodType'
            />

            { this.state.searchParams.periodType === 2 ?
              <span className="ml-1">
                From:
                <DateInput
                  name="startDate"
                  placeholder="Date"
                  value={moment(this.state.searchParams.startDate)}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                  animation="fade"
                  popupPosition="bottom center"
                  closable
                  className="ui input"
                  style={{width: '9em'}}
                  autoComplete="off"
                  dateFormat= {UIDateFormat}
                />
              </span>
              :
              <span className="ml-1">
                <label>Last</label>
                <Input style={{width:'3em'}} value={this.state.searchParams.lastxPeriod} type="number" name='lastxPeriod' onChange={(event, { name, value }) => this.handleChange(name, value)} />
                {periodType.text}{this.state.searchParams.lastxPeriod > 1 && "s"}
              </span>
            }

            { this.state.searchParams.periodType === 2 &&
              <span className="ml-1">
                      <label className="mr-1">To:</label>
                      {!this.state.useCurrentDateTime &&
                        <DateInput
                          name="endDate"
                          className="ui input"
                          style={{width: '9em'}}
                          placeholder="Date"
                          value={moment(this.state.searchParams.endDate)}
                          iconPosition="left"
                          onChange={this.handleDateChange}
                          animation="fade"
                          popupPosition="bottom center"
                          closable
                          autoComplete="off"
                          dateFormat= {UIDateFormat}
                        />
                      }
                      { this.state.searchParams.periodType === 2 &&

                          <Checkbox checked={this.state.useCurrentDateTime} label='Current Date' onChange={this.handleUseCurrentDateTime} />
                        
                      }
                    </span>
              }

              <Checkbox className="ml-1" checked={this.state.showAllCollections} label='All Collections' onChange={this.handleAllCollectionsChange} />

              { this.props.filters.filterSelected.site.id > 0 && <Button className="ml-1" size='small' onClick={this.updateCollections} disabled={(!this.props.installation && !this.state.showAllCollections) || this.state.loading} positive>Refresh</Button> }

            </Grid.Column>
          </Grid.Row>
        </Grid>
        </form>
    )
  }

  render() {
    const showSummary = this.props.filters.filterSelected.operator && this.state.showSummary;

    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row columns='equal'>
          <Grid.Column width="16">
            { this.searchSegment() }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch">
            { showSummary && 
              <Grid.Column computer="6" largeScreen="6" widescreen="4" className="gs-flex-container-vertical">
                  <DataGridRequest
                    loading={this.state.loading}
                    headings={this.props.collections.summary.headerDef}
                    data={this.props.collections.summary.data}
                    rowDef={this.props.collections.summary.rowDef}
                    hideOverflow
                    disableSort={true}
                    disableActiveRow={true}
                    onOptionChange={this.handleOptionChange}
                  />
              </Grid.Column>
            }
            <Grid.Column computer={ showSummary ? "9" : "16" } largeScreen={ showSummary ? "10" : "16" } widescreen={ showSummary ? "12" : "16" } className="gs-flex-container-vertical">
            <CollectionDetailsModal
              modalOpen={this.state.modalOpen}
              modalData={this.state.selectedCollection}
              handleClose={() => this.setState({modalOpen: false})}
              onChange={this.handleShowEventsChange}
              showEvents={this.state.showEvents}
              loading={this.state.loading}
            />
            <DataGridRequest
              loading={this.state.loading}
              headings={this.props.collections.grid.headerDef}
              rowDef={this.props.collections.grid.rowDef}
              data={this.props.collections.grid.data}
              onRowClick={this.handleRowClick}
              stretched
              fullHeight
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = state => {
	return {
    filters: state.installationsFilters,
    installation: state.activeInstallation.installationId,
    collections: state.collectionsInstallations
  };
};

export default connect(mapStateToProps, {
  fetchCollectionByRef,
  fetchCollectionsFilters,
  setCollections: setCollectionsInstallations,
  emptyCollectionsSection
})(Collections);

