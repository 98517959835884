import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';
import {
  checkForUpdates, emptyCollectionsSection,
  fetchBlankCollectionsSummary,
  fetchCollectionsFilters, setCollections
} from '../../actions';
import BaseCollections from '../collections/BaseCollections';
import CollectionsFilters from '../collections/CollectionsFilters';
import CollectionsFiltersOther from '../collections/CollectionsFiltersOther';
import CollectionsDetailsModal from '../modals/CollectionDetailsModal';
import DataGridRequest from '../shared/DataGridRequest';
import DownloadButton from '../shared/DownloadButton';

class CollectionView extends BaseCollections {

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filters.filterSelected.operator !== prevProps.filters.filterSelected.operator) {
      this.setState({ loading: true }, () => {
        fetchBlankCollectionsSummary(this.props.filters.filterSelected.operator).then(
          summary => this.props.setCollections({ summary }).then(() => this.setState({ loading: false })),
          () => this.setState({ loading: false}) 
        );
      })
    }

    if (checkForUpdates(prevProps.filters, this.props.filters)) {
      this.updateCollections();
    }
  }

  render() {
    const showSummary = this.props.filters.filterSelected.operator && this.state.showSummary;

    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row columns='equal'>
          <Grid.Column width="8">
              <div className='flex-vertical'>
                <div className='flex-shrink'>
                  <div>
                    <Button color='blue' icon size='mini' disabled={!this.props.filters.filterSelected.operator} onClick={() => this.setState( {showSummary: !this.state.showSummary })}>
                      <Icon name="pin" rotated={ !this.state.showSummary ? "clockwise" : null } />
                    </Button>
                  </div>
                  <DownloadButton disabled={!this.props.filters.hasSelection} onClick={this.loadExport} />
                </div>
                <div className='flex-stretch ml-1'>
                <CollectionsFilters
                  {...this.state.searchParams}
                  useCurrentDateTime={this.state.useCurrentDateTime}
                  onChangeUseCurrentDateTime={this.handleUseCurrentDateTime}
                  onChange={this.handleChange}
                />
              </div>
              <div className='flex-stretch ml-1'>
                <CollectionsFiltersOther
                  {...this.state.searchParams}
                  useCurrentDateTime={this.state.useCurrentDateTime}
                  onChangeUseCurrentDateTime={this.handleUseCurrentDateTime}
                  onChange={this.handleChange}
                />
                <Button size='small' onClick={this.updateCollections} loading={this.state.loading} disabled={!this.props.filters.hasSelection || this.state.loading} positive>Refresh</Button>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch">
            { showSummary && 
              <Grid.Column computer="6" largeScreen="6" widescreen="4" className="gs-flex-container-vertical">
                  <DataGridRequest
                    loading={this.state.loading}
                    headings={this.props.collections.summary.headerDef}
                    data={this.props.collections.summary.data}
                    rowDef={this.props.collections.summary.rowDef}
                    hideOverflow
                    disableSort={true}
                    disableActiveRow={true}
                    onOptionChange={this.handleOptionChange}
                  />
              </Grid.Column>
            }
            <Grid.Column computer={ showSummary ? "9" : "16" } largeScreen={ showSummary ? "10" : "16" } widescreen={ showSummary ? "12" : "16" } className="gs-flex-container-vertical">
            <CollectionsDetailsModal
              modalOpen={this.state.modalOpen}
              modalData={this.state.selectedCollection}
              modalTitle={this.state.modalTitle}
              handleClose={() => this.setState({modalOpen: false})}
              onChange={this.handleShowEventsChange}
              showEvents={this.state.showEvents}
              loading={this.state.loading}
              onTransactionClose={() => this.handleRowClick(this.state.activeRow)}
            />
            <DataGridRequest
              loading={this.state.loading}
              headings={this.props.collections.grid.headerDef}
              rowDef={this.props.collections.grid.rowDef}
              data={this.props.collections.grid.data}
              onRowClick={this.handleRowClick}
              stretched
              fullHeight
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = state => {
	return {
    filters: state.collectionsFilters,
    collections: state.collections
  };
};

export default connect(mapStateToProps, {
  setCollections,
  fetchBlankCollectionsSummary,
  fetchCollectionsFilters,
  emptyCollectionsSection
})(CollectionView);