import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Icon, List } from 'semantic-ui-react';
import OperatorRegion from '../..//actions/operatorRegion';
import { fetchOperators } from '../../actions/index.js';
import { showSuccessAlert } from '../../actions/modals';
import OptionsModal from '../modals/OptionsModal';

class OperatorRegions extends React.Component {
  
  state = {
    selectedRegion: { id: 0, name: "" },
    regions: [],
    selectedOperator: null,
    hasChanges: false,
    modalOpen: false,
    modalTitle: "New Region",
    modalOptions: [],
    loading: false

  }
  

  componentDidUpdate = (prevProps) => {
    if (this.props.selectedOperator !== null && prevProps.selectedOperator !== this.props.selectedOperator) {
      this.handleRefresh();
    }
  }

  handleRefresh = async () => {
      await this.setState({ loading: true })
      await OperatorRegion.getRegions(this.props.selectedOperator).then(
        regions => this.setState( { regions, loading: false }),
        () => this.setState({ loading: false })
      )
  }

  onNewRegionClick = () => {
    OperatorRegion.newRegion(this.props.selectedOperator).then(modalOptions => {
      this.setState({ modalOptions, modalTitle: "New Region", selectedRegion: {id: 0, name: "" }, modalOpen: true })
    })
  }

  saveRegion = async (changed) => {
    await OperatorRegion.updateRegion(this.state.selectedRegion.id, this.props.selectedOperator, changed).then(id => {
      this.handleRefresh().then(() => {
        const selectedRegion = this.state.regions.find(x => x.id === id)
        this.setState({ modalOpen: false, selectedRegion }, () => this.props.showSuccessAlert(this.state.selectedRegion.id > 0 ? "Region Successfully Updated!" : "New Region Successfully Created!"))
      })
    })
  }

  onRegionClick = (item) => {
    this.setState({ selectedRegion: item }, () => {
      OperatorRegion.getRegion(item.id).then(modalOptions => {
        this.setState({ modalOptions, modalTitle: "Edit Region", modalOpen: true })
      })
    })
  }

  render() {
    return (
      <div className="settings height-100 gs-flex-container-vertical">
        <Grid className="height-100 gs-flex-container-vertical">
          <Grid.Row className="flex-stretch">
            <Grid.Column className="gs-flex-container-vertical">
              <div className="table-overflow flex-stretch-vertical p-1 mb-1">
                <List className="clickable" id="regions">
                  { this.state.regions.map(item => <List.Item active={this.state.selectedRegion.id === item.id} onClick={() => this.onRegionClick(item)}>{item.name}</List.Item>) }
                </List>
              </div>
              <OptionsModal open={this.state.modalOpen} options={this.state.modalOptions} title={this.state.modalTitle} onClose={() => this.setState({ modalOpen: false })} onSave={this.saveRegion} />
              <div className="mt-1">
                <Button icon positive labelPosition='right' disabled={this.state.selectedOperator === 0} onClick={this.onNewRegionClick }>
                  New Region
                  <Icon name='add' />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
	return {
    operators: state.operators.filter(x => x.value !== -1),
    logIn: state.logIn
  }
}

export default connect(mapStateToProps, { fetchOperators, showSuccessAlert })(OperatorRegions);
