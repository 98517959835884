

import React from 'react';
import { Header, Modal } from 'semantic-ui-react'
import TransactionDetails from '../shared/TransactionDetails'

class TransactionDetailsModal extends React.Component {

  render() {
    return (
        <Modal
            open={this.props.modalOpen}
            onClose={() => this.props.handleClose(false)}
            size='fullscreen'
            closeIcon
            className="h-80"
        >
            <Header content={this.props.modalTitle} />
            <Modal.Content className="h-100">
                <TransactionDetails key="details" transactionDetails={this.props.transaction} closeModal={this.props.handleClose} />
            </Modal.Content>
        </Modal>
    )
  }
}

export default TransactionDetailsModal;
