import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Header, Icon, List } from 'semantic-ui-react';
import { fetchUserAccessProfile, fetchUserAccessProfiles, getAccessProfile, updateAccessProfile } from '../../actions/index';
import { showSuccessAlert } from '../../actions/modals';
import OptionsModal from '../modals/OptionsModal';
import BaseSettings from '../systemAdmin/BaseSettings';
import Filters from '../systemAdmin/Filters';
import OptionsList from '../systemAdmin/OptionsList';

class AccessProfiles extends BaseSettings {

  state = {
    changed: [],
    search: "",
    activeProfile: { value: null, text: "", key: "", options: [] },
    selectedOperator: null,
    profiles: [],
    profileModalOpen: false,
    modalOpen: false,
    modalTitle: "",
    modalOptions: [],
    loading: false
  }

  componentDidMount() {
    const { operators } = this.props;
    const selectedOperator = operators.length === 1 ? operators[0].key : null;
    this.setState({selectedOperator});
  }

  handleOperatorSelect = (event, data) => {
     this.setState({selectedOperator: data.value})
  }

  handleChangeValue = (option, value) => {
    const changed = [...this.state.changed].filter(x => x.code !== option.code)
    changed.push({...option, value})
    const options = [...this.state.activeProfile.options]
    const index = options.findIndex(item => item.code === option.code)
    options[index] = option
    this.setState({activeProfile: {...this.state.activeProfile, options}, changed})
  }

  handleModalClose = () => {
    this.setState({successModalOpen: false})
  }

  handleSearch = async () => {
    const { selectedOperator, search } = this.state
    await this.setState({ loading: true })
    const profiles = await fetchUserAccessProfiles(selectedOperator, search)
    this.setState({profiles, loading: false });
    return true;
  }

  handleSearchChange = (event, { value }) => {
    this.setState({ search: value })
  }
  
  handleClick = (activeProfile) => {
    this.fetchAccessProfile(activeProfile)
  }

  fetchAccessProfile = async (activeProfile) => {
    await this.setState({ loading: true })
    fetchUserAccessProfile(activeProfile.value, true).then(
      result => this.setState({changes: {}, loading: false, activeProfile: {...activeProfile, ...{options: result.profileData}}}),
      () => this.setState({ loading: false })
    );
  }

  handleSave = () => {
    updateAccessProfile(this.state.activeProfile.value, this.state.selectedOperator, this.getChanged()).then(response => {
        this.setState({changed: [] }, () => {
          this.handleSearch().then(() => {
            this.fetchAccessProfile(this.state.activeProfile)
            this.props.showSuccessAlert(`Your changes were saved successfully`) 
          })
        })
      })
  }

  handleNewProfile = () => {
    getAccessProfile(this.state.selectedOperator).then(response => {
      this.setState({ modalOptions: response, modalTitle: "New Profile", modalOpen: true })
    });
  }

  saveNewProfile = async (options) => {
    await updateAccessProfile(0, this.state.selectedOperator, options).then(profileId => {
      this.handleSearch().then(() => {
        const activeProfile = this.state.profiles.find(x => x.value === profileId);
        this.fetchAccessProfile(activeProfile)
        this.setState( { modalOpen: false }, () => this.props.showSuccessAlert(`New profile successfully created!`));
      })
    })
  }

  render() {
    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row>
          <Grid.Column width="16">
          <OptionsModal open={this.state.modalOpen} options={this.state.modalOptions} title={this.state.modalTitle} onClose={() => this.setState({ modalOpen: false })} onSave={this.saveNewProfile} />
            <Filters
              onOperatorSelect={this.handleOperatorSelect}
              operators={this.props.operators}
              profiles={[]}
              onSearchChange={this.handleSearchChange}
              onSearchClick={this.handleSearch}
              loading={this.state.loading}
              searchDisabled={!this.state.selectedOperator}
            />       
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch">
          <Grid.Column width="3" className="gs-flex-container-vertical">
            <div className="table-overflow flex-stretch-vertical" id="usersList">
              <List className="m-1">
                { this.state.profiles.map(profile => {
                return (<List.Item className="p-half clickable" key={profile.key} active={this.state.activeProfile.value === profile.value} onClick={() => this.handleClick(profile)}><b>{profile.text}</b></List.Item>)
                })}
              </List>
            </div>
          </Grid.Column>
          <Grid.Column width="13" className="gs-flex-container-vertical">
            <div className="table-overflow flex-stretch-vertical p-1" id="usersList">
              <Header>{this.state.activeProfile.text}</Header>
              <OptionsList labelWidth="9" options={this.state.activeProfile.options} onChange={this.handleChangeValue} />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column width="3">
           { this.props.logIn.optionAllowNewUserAccessProfile &&
            <Button icon positive onClick={this.handleNewProfile} disabled={!this.state.selectedOperator || this.state.loading} loading={this.state.loading} labelPosition='right'>
              New Profile
              <Icon name='add' />
            </Button> }
          </Grid.Column>
          <Grid.Column textAlign="right" width="6">
            { this.props.logIn.optionAllowSaveUserAccessProfile && <Button icon positive disabled={!this.isValid() || this.state.loading} loading={this.state.loading} onClick={this.handleSave} labelPosition='right'>
              Save Changes
              <Icon name='save' />
            </Button> }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
	return {
    operators: state.operators.filter(x => x.value !== -1),
    logIn: state.logIn
  }
}

export default connect(mapStateToProps, { showSuccessAlert })(AccessProfiles);
