import moment from 'moment';
import jsonPlaceholder from '../api/jsonPlaceholder';
import { newFile } from '../components/helpers/downloads';
import { UIFileFormat } from '../config/dates';
import { emptyGrid } from './datagrid';

export const fetchCollections = async (OperatorId, CompanyId, SubCompanyId, SiteId, InstallationId, StartDate, EndDate, CollectionId, AssetNo, PeriodType, LastxPeriod, OperatorRegions, Manufacturers, GameNames, Categories) => {
	const params = { 
		StartDate: moment(StartDate).startOf('day'), 
		EndDate: moment(EndDate).endOf('day'), 
		OperatorId, 
		CompanyId: CompanyId || -1, 
		SubCompanyId: SubCompanyId || -1,
		SiteId: SiteId || -1, 
		InstallationId: parseInt(InstallationId) || -1,
		AssetNo: AssetNo || "#", 
		OperatorRegions,
		Manufacturers,
		GameNames,
		Categories,
		CollectionId: parseInt(CollectionId) || -1,
		PeriodType: parseInt(PeriodType) || 0,
		LastxPeriod: parseInt(LastxPeriod) || 0,
	}
	
	const response = await jsonPlaceholder.put(`collections/filterbymachine2/`, params)
	return response.data;
}

export const setCollections = (data) => async dispatch => {
	dispatch({type: 'FETCH_COLLECTIONS', payload: data});
}

export const setCollectionsInstallations = (data) => async dispatch => {
	dispatch({type: 'FETCH_COLLECTIONS_INSTALLATIONS', payload: data});
}

export const fetchCollectionsExport = async (OperatorId, CompanyId, SubCompanyId, SiteId, InstallationId, StartDate, EndDate, CollectionId, AssetNo, PeriodType, LastxPeriod, OperatorRegions, Manufacturers, GameNames, Categories) => {
	const params = { 
		StartDate: moment(StartDate).startOf('day'), 
		EndDate: moment(EndDate).endOf('day'), 
		OperatorId, 
		CompanyId: CompanyId || -1, 
		SubCompanyId: SubCompanyId || -1,
		SiteId: SiteId || -1, 
		InstallationId: parseInt(InstallationId),
 		AssetNo: AssetNo || "#", 
		OperatorRegions,
		Manufacturers,
		GameNames,
		Categories,
		CollectionId: parseInt(CollectionId) || -1,
		PeriodType: parseInt(PeriodType) || 0,
		LastxPeriod: parseInt(LastxPeriod) || 0,
	}
	
	const fileName = `collections-${moment().format(UIFileFormat)}.csv`;
	const response = await jsonPlaceholder.put(`collections/export/`, params)
	const contentType = response.headers["content-type"] || "text/csv";
	newFile(response.data, fileName, contentType)
}

export const fetchBlankCollectionsSummary = async (operatorId) => {
	const response = await jsonPlaceholder.get(`collections/blanksummary/${operatorId}`)
	return response.data
}

export const fetchCollectionByRef = async (detailRef, showEvents) => {
	const response = await jsonPlaceholder.get(`Collections/Details/${encodeURIComponent(detailRef)},${encodeURIComponent(showEvents)}`)
	return response.data;
}

export const emptyCollectionsSection = (clearFilters = true) => async dispatch => {
	dispatch({type: 'FETCH_COLLECTION_BY_REF', payload: {} });
	dispatch({type: 'FETCH_COLLECTIONS', payload: emptyGrid });
	dispatch({type: 'FETCH_TRANSACTION', payload: []});
	if (clearFilters) dispatch({type: 'FETCH_COLLECTIONS_FILTERS', payload: {}});
}