import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';
import {
  checkForUpdates, emptyTransactionsSection, fetchTransaction,
  fetchTransactionsFilters, fetchTransactionsWithSummary,
  fetchTransBlankSummaryTransactions, fetchTransactionsExport, updateTransactionsWithSummary,
} from '../../actions';
import { prepareFilter } from '../../actions/organisationDirectory';
import { defaultFromDate } from '../../config/dates';
import TransactionDetailsModal from '../modals/TransactionDetailsModal';
import CompanyFilters from '../shared/CompanyFilters';
import DataGridRequest from '../shared/DataGridRequest';
import Option from '../systemAdmin/Option';
import DownloadButton from '../shared/DownloadButton';

class TransactionsView extends React.Component {

  state = {
    searchParams: {
      startDate: moment(defaultFromDate).toISOString(),
      endDate: moment().toISOString(),
      companyId: null,
      subCompanyId: null,
      siteId: null,
      includeEvents: false,
      sessionId: '',
      transactionRef: '',
      assetNo: '',
      playerId: null,
    },
    companyInfo: {
      company: 'ALL',
      subCompany: 'ALL',
      site: 'ALL'
    },
    loading: false,
    useCurrentDateTime: true,
    modalOpen: false,
    modalTitle: "",
    showSummary: true
  };

  handleClose = (refresh) => {
    this.setState({ modalOpen: false },()=>{
      if(refresh===true){
        this.handleRefresh();
      }
    })
  }

  handleTransactionSelection = term => {
    if (term.optionShowDetails) {
      this.setState({loading: true}, () => {
        this.props.fetchTransaction(term.transactionId).then(() => {
          this.setState({
            modalOpen: true,
            modalTitle: `Transaction Details: ${this.props.transaction.transactionRef}`,
            loading: false
          });
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filters.filterSelected.operator !== prevProps.filters.filterSelected.operator) {
      this.props.fetchTransBlankSummaryTransactions(this.props.filters.filterSelected.operator)
    }

    if (checkForUpdates(prevProps.filters, this.props.filters)) {
      this.handleRefresh();
    }
  }

  setUseCurrentDateTime = () => {
    this.setState({useCurrentDateTime: !this.state.useCurrentDateTime})
  }

  handleChange = (key, value, callback = () => {}) => {
    let params = {...this.state.searchParams, [key]: value }
    this.setState({searchParams: params}, callback);
  }

  handleRefresh = () => {
    if (this.state.useCurrentDateTime) {
      this.handleChange('endDate', moment().toISOString(), () => this.getTransactions())
    } else {
      this.getTransactions();
    }
  }

  loadExport = () => {
    if (this.state.useCurrentDateTime) {
      this.handleChange('endDate', moment().toISOString(), () => this.getExport())
    } else {
      this.getExport();
    }
  }

  getExport = () => {
  
    const searchParams = this.state.searchParams
    const { operator, company, subcompany, site } = this.props.filters.filterSelected
    const { filterSelected, filterData } = this.props.filters

      fetchTransactionsExport(
        searchParams.startDate,
        searchParams.endDate,
        operator,
        company.id,
        subcompany.id,
        site.id,
        searchParams.includeEvents,
        searchParams.sessionId,
        searchParams.transactionRef,
        searchParams.assetNo,
        searchParams.playerId,
        prepareFilter(filterSelected.regions, filterData.regions),
        prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        prepareFilter(filterSelected.game, filterData.game),
        prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
        this.getDetails()
      )
  }

  getTransactions = () => {
  
    const searchParams = this.state.searchParams
    const { operator, company, subcompany, site } = this.props.filters.filterSelected
    const { filterSelected, filterData } = this.props.filters
    const details = this.getDetails();
    this.setState({loading: true }, () => {
      this.props.fetchTransactionsWithSummary(
        searchParams.startDate,
        searchParams.endDate,
        operator,
        company.id,
        subcompany.id,
        site.id,
        searchParams.includeEvents,
        searchParams.sessionId,
        searchParams.transactionRef,
        searchParams.assetNo,
        searchParams.playerId,
        prepareFilter(filterSelected.regions, filterData.regions),
        prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        prepareFilter(filterSelected.game, filterData.game),
        prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
        details 
      ).then(
        () => this.setState({loading: false}),
        () => this.setState({loading: false})
      )
    });
  }

  getDetails = () => {
    const details = {};
    const data = this.props.transactionsByCompany.summary.data;

    data.forEach((row, rowIndex) => {
      row.cellData.forEach((cell, cellIndex) => {
        if (cell.type === "control") {
          details[cell.data.code] = Option.getValue(cell.data);
        }
      })
    })

    return details;
  }

  handleOptionChange = (option, value) => {    
    const { summary } = this.props.transactionsByCompany;
    const data = [...summary.data];

    data.forEach((row, rowIndex) => {
      row.cellData.forEach((cell, cellIndex) => {
        if (cell.data.code === option.code) {
          data[rowIndex].cellData[cellIndex].data = option
        }
      })
    })

    this.props.updateTransactionsWithSummary({ summary: {...summary, data } })
  }

  render() {
    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row>
          <Grid.Column>
            <div className='flex-vertical' style={{ marginTop: "4px"}}>
              <div className='flex-shrink'>
                <div>
                <Button color='blue' icon size='mini' disabled={!this.props.filters.filterSelected.operator} onClick={() => this.setState( {showSummary: !this.state.showSummary })}>
                  <Icon name="pin" rotated={ !this.state.showSummary ? "clockwise" : null } />
                </Button>
                </div>
                <DownloadButton disabled={!this.props.filters.hasSelection} onClick={this.loadExport} />
              </div>
              <div className='flex-stretch ml-1'>
              <CompanyFilters
                {...this.state.searchParams}
                hasSelection={this.props.filters.hasSelection}
                hasShowEvents={false}
                company={this.props.filters.filterSelected.company}
                subcompany={this.props.filters.filterSelected.subcompany}
                site={this.props.filters.filterSelected.site}
                useCurrentDateTime={this.state.useCurrentDateTime}
                setUseCurrentDateTime={this.setUseCurrentDateTime}
                onChange={this.handleChange}
                refresh={this.handleRefresh}
                loading={this.state.loading}
                refreshDisabled={!this.props.filters.hasSelection}
              />

              </div>
         </div>
          </Grid.Column>
        </Grid.Row>        
        <Grid.Row className="flex-stretch">
          { (this.props.filters.filterSelected.operator && this.state.showSummary) && 
              <Grid.Column computer="6" largeScreen="4" widescreen="3" className="gs-flex-container-vertical">
                <DataGridRequest
                  loading={this.state.loading}
                  headings={this.props.transactionsByCompany.summary.headerDef}
                  data={this.props.transactionsByCompany.summary.data}
                  rowDef={this.props.transactionsByCompany.summary.rowDef}
                  hideOverflow
                  disableSort={true}
                  disableActiveRow={true}
                  onOptionChange={this.handleOptionChange}
                />
              </Grid.Column>
            }
            <Grid.Column computer={ this.state.showSummary ? "10" : "16" } largeScreen={ this.state.showSummary ? "12" : "16" } widescreen={ this.state.showSummary ? "13" : "16" } className="gs-flex-container-vertical">
              <DataGridRequest
                loading={this.state.loading}
                headings={this.props.transactionsByCompany.grid.headerDef}
                data={this.props.transactionsByCompany.grid.data}
                onRowClick={this.handleTransactionSelection}
                rowDef={this.props.transactionsByCompany.grid.rowDef}
                fullHeight
              />
              <TransactionDetailsModal modalOpen={this.state.modalOpen} handleClose={this.handleClose} modalTitle={this.state.modalTitle} transaction={this.props.transaction} />
            </Grid.Column>
        </Grid.Row>

 
      </Grid>
    );
  }
};


const mapStateToProps = state => {
	return {
    filters: state.transactionsFilters,
    transactionsByCompany: state.transactionsByCompany,
    transaction: state.transaction,
  };
};

export default connect(mapStateToProps, {
  fetchTransactionsWithSummary,
  fetchTransaction,
  updateTransactionsWithSummary,
  fetchTransBlankSummaryTransactions,
  fetchTransactionsFilters,
  emptyTransactionsSection
})(TransactionsView);
