import moment from 'moment';
import React from 'react';
import { fetchCollectionByRef, fetchCollections, fetchCollectionsExport } from '../../actions';
import { prepareFilter } from '../../actions/organisationDirectory';
import { UIDateFormat } from '../../config/dates';

class BaseCollections extends React.Component {

  state = {
    searchParams: {
      startDate: moment().subtract(7, 'day').startOf('day'),
      endDate: moment(),
      companyId: null,
      subCompanyId: null,
      siteId: null,
      assetNo: '',
      collectionId: '',
      periodType: 2,
      lastxPeriod: 1
    },
    companyInfo: {
      company: '',
      subCompany: '',
      site: ''
    },
    loading: false,
    modalOpen: false,
    selectedCollection: null,
    showEvents: false,
    useCurrentDateTime: true,
    showAllCollections: false,
    showSummary: false,
  };

  handleRefresh = () => {
    const callback = () => this.setState({ loading: false });

    this.setState({ loading: true }, () => {
        const searchParams = this.state.searchParams
        const filterSelected = this.props.filters.filterSelected;
        const filterData = this.props.filters.filterData;
        const startDate = moment(searchParams.startDate).toISOString()
        const endDate = moment(searchParams.endDate).toISOString()
  
          fetchCollections(
            filterSelected.operator, 
            filterSelected.company.id,
            filterSelected.subcompany.id,
            filterSelected.site.id, 
            (this.state.showAllCollections || !this.props.installation) ? -1 : this.props.installation,
            startDate, 
            endDate, 
            searchParams.collectionId,
            searchParams.assetNo,
            searchParams.periodType,
            searchParams.lastxPeriod,
            prepareFilter(filterSelected.regions, filterData.regions),
            prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
            prepareFilter(filterSelected.game, filterData.game),
            prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
          ).then(
            response => this.props.setCollections(response).then(callback),
            callback
          )
      }
    );
  }

  handleClose = () => this.setState({ modalOpen: false })

  handleChange = (name, value) => {
    var changeValues = {[name] : value};
    let params = {...this.state.searchParams, ...{[name] : value}}
    if(name==='periodType'){
      switch (value) {
        case 0:
          //Month default
          changeValues = {[name] : value, lastxPeriod: 1};
          break;
        default:
          //Week default
          changeValues = {[name] : value, lastxPeriod: 20};
          break;
      }
      params = {...this.state.searchParams, ...changeValues}
    }

    this.setState({searchParams: params});
  }

  updateCollections = () => {
    const endDate = this.state.useCurrentDateTime ? moment() : this.state.searchParams.endDate;
    this.setState( { searchParams: {...this.state.searchParams, endDate }}, () => this.handleRefresh());
  }

  handleRowClick = (row) => {
    this.setState({loading: true, activeRow: row}, () => {
      fetchCollectionByRef(row.detailRef, this.state.showEvents).then(selectedCollection => {
        this.setState({modalOpen: true, selectedCollection, loading: false})
      });
    });
  }

  handleShowEventsChange = async () => {
    this.setState({showEvents: !this.state.showEvents }, () => {
      this.handleRowClick(this.state.activeRow)
    });
  }

  handleUseCurrentDateTime = () => {

    let newState = {useCurrentDateTime: !this.state.useCurrentDateTime};

      if (this.state.useCurrentDateTime) {
        newState = {...newState, searchParams: {...this.state.searchParams, ...{endDate: moment()} } }
      }

    this.setState(newState)
  }

  handleDateChange = (event, {name, value}) => {
    this.handleChange(name, moment(value, UIDateFormat))
  } 

  loadExport = () => {
    const callback = () => this.setState({ loading: false })

    this.setState({ loading: true }, () => {
      const searchParams = this.state.searchParams
      const filterSelected = this.props.filters.filterSelected;
      const filterData = this.props.filters.filterData;
      const startDate = moment(searchParams.startDate).toISOString()
      const endDate = moment(searchParams.endDate).toISOString()
      
      fetchCollectionsExport(
        filterSelected.operator, 
        filterSelected.company.id,
        filterSelected.subcompany.id,
        filterSelected.site.id, 
        (this.state.showAllCollections || !this.props.installation) ? -1 : this.props.installation,
        startDate, 
        endDate, 
        searchParams.collectionId,
        searchParams.assetNo,
        searchParams.periodType,
        searchParams.lastxPeriod,
        prepareFilter(filterSelected.regions, filterData.regions),
        prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        prepareFilter(filterSelected.game, filterData.game),
        prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
      ).then(callback, callback) 
    })
  }

  render() {
    return <></>
  }
};

export default BaseCollections;