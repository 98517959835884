import jsonPlaceholder from '../api/jsonPlaceholder';
import md5 from 'md5';

export const fetchLogin = (username, password) => async dispatch => {
	try{
		const response = await jsonPlaceholder.put(`Security/Login2/`, { username, password });
		const session = {...response.data, ...{email: username}, hash: md5(password) }
		dispatch({type: 'FETCH_LOGIN', payload: session });
		return true;
	}catch(error){
		return false;
	}
};

/**
 * Reset a user's password
 * @param {string} username 
 * @param {md5 hash} prevPassword
 * @param {string} password 
 * @param {string} password2 
 */
export const setPassword = (username, prevPassword, password, password2) => async dispatch => {
	return await jsonPlaceholder.put(`Security/SetPassword4/`, { username, prevPassword, password, password2 });
};

export const resetPassword = (email) => async dispatch => {
	try{
		await jsonPlaceholder.put(`Security/ResetPassword/${email}`);
		return true
	}catch(error){
		return false
	}
};

export const setLogout = () => async dispatch => {
	try{
		await jsonPlaceholder.put(`Security/Logout`);
		sessionStorage.removeItem('login')
		dispatch({type: 'CLEAR_REDUX', payload: {logOut: true}});
	}catch(error){
		sessionStorage.removeItem('login')
		dispatch({type: 'CLEAR_REDUX', payload: {logOut: true}});
	}
};
