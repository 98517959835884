import moment from 'moment';
import jsonPlaceholder from '../api/jsonPlaceholder';
import { APIDateTimeFormat, UIFileFormat } from '../config/dates';
import { emptyGrid } from './datagrid';
import { prepareDetails } from './settings';
import { newFile } from '../components/helpers/downloads';

export const closeSession = async (sessionId) => {
	const response = await jsonPlaceholder.put(`Sessions/Close/${sessionId}`);
	return response.data;
}

export const fetchSessionsByCompany = (filters) => async dispatch => {

	let params = new Map();

	params.set("startDate", moment().subtract(14, 'days').format(APIDateTimeFormat));
	params.set("endDate", moment().format(APIDateTimeFormat));
	params.set("operator", -1);
	params.set("companyId", -1);
	params.set("subCompanyId", -1);
	params.set("siteId", -1);
	params.set("sessionId", -1);
	params.set("assetNo", encodeURIComponent('#'));
	params.set("playerId", encodeURIComponent('#'))
	params.set("eftSessions", true);
	params.set("cashSessions", true);
	params.set("manufacturer", '-1');
	params.set("gamenames", '-1');
	params.set("machinetypes", '-1');	

	for (const [key, item] of Object.entries(filters)) {
		if (typeof item !== "object" && item !== '' && item !== null) {
			params.set(key, encodeURIComponent(item));
		}
	}

	const query = Array.from(params.values()).join(",");

	const transactions = await jsonPlaceholder.get(`Sessions/filterbymachine/${query}`).catch(error => {
		dispatch({type: 'FETCH_SESSIONS_BY_COMPANY', payload: {grid: []}});
	})

	dispatch({type: 'FETCH_SESSIONS_BY_COMPANY', payload: transactions.data});

}

export const fetchSessionsWithSummary =
(
	StartDate,
	EndDate,
	OperatorId,
	CompanyId,
	SubCompanyId,
	SiteId,
	SessionId,
	AssetNo,
	PlayerId,
	TransactionRef,
	ZeroBetSessions,
	OpenSessions,
	OperatorRegions,
	Manufacturers,
	GameNames,
	Categories,
	Details
) => async dispatch => {
	const params = { 
		StartDate, 
		EndDate, 
		OperatorId, 
		CompanyId: CompanyId || -1, 
		SubCompanyId: SubCompanyId || -1,
		SiteId: SiteId || -1, 
		SessionId: parseInt(SessionId) || -1,
		AssetNo: AssetNo || "#", 
		PlayerId: PlayerId || "#", 
		TransactionRef: TransactionRef || "#",
		ZeroBetSessions,
		OpenSessions,
		OperatorRegions,
		Manufacturers,
		GameNames,
		Categories,
		Details: prepareDetails(Details)
	}

	const response = await jsonPlaceholder.put(`Sessions/filterbymachine2/`, params)
	dispatch({type: 'FETCH_SESSIONS_BY_COMPANY', payload: response.data});
}

export const updateSessionsWithSummary = (data) => async dispatch => {
	dispatch({type: 'FETCH_SESSIONS_BY_COMPANY', payload: data});
}

export const fetchSessionsExport =
async (
	StartDate,
	EndDate,
	OperatorId,
	CompanyId,
	SubCompanyId,
	SiteId,
	SessionId,
	AssetNo,
	PlayerId,
	TransactionRef,
	ZeroBetSessions,
	OperatorRegions,
	Manufacturers,
	GameNames,
	Categories,
	Details
) => {
	const params = { 
		StartDate, 
		EndDate, 
		OperatorId, 
		CompanyId: CompanyId || -1, 
		SubCompanyId: SubCompanyId || -1,
		SiteId: SiteId || -1, 
		SessionId: parseInt(SessionId) || -1,
		AssetNo: AssetNo || "#", 
		PlayerId: PlayerId || "#", 
		TransactionRef: TransactionRef || "#",
		ZeroBetSessions,
		OperatorRegions,
		Manufacturers,
		GameNames,
		Categories,
		Details: prepareDetails(Details)
	}

	const fileName = `sessions-${moment().format(UIFileFormat)}.csv`;
	const response = await jsonPlaceholder.put(`Sessions/export/`, params)
	const contentType = response.headers["content-type"] || "text/csv";
	newFile(response.data, fileName, contentType)
}

export const emptySessionsSection = (clearFilters = true) => async dispatch => {
	dispatch({type: 'FETCH_SESSIONS_BY_COMPANY', payload: emptyGrid });
	if (clearFilters) dispatch({type: 'FETCH_SESSIONS_FILTERS', payload: {}});
}

export const fetchBlankSummarySessions = (operatorId) => async dispatch => {
	const data = await fetchBlankSummary(operatorId);
	dispatch({type: 'FETCH_SESSIONS_BY_COMPANY', payload: { summary: data } });
}

export const fetchBlankSummary = async (operatorId) => {
	const response = await jsonPlaceholder.get(`/Sessions/blanksummary/${operatorId}`);
	return response.data
}