

import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Grid, Label, Modal } from 'semantic-ui-react';
import { fetchTransaction } from '../../actions';
import TransactionDetailsModal from '../modals/TransactionDetailsModal';
import DataGridRequest from '../shared/DataGridRequest';

class CollectionDetailsModal extends React.Component {
  
  state = {
    modalOpen: false,
    modalTitle: "",
    loading: false
  }

  handleRowClick = term => {
    if (term.optionShowDetails) {
      this.setState({ loading: true }, () => {
        this.props.fetchTransaction(term.transactionId).then(() => {
          this.setState({
            loading: false,
            modalOpen: true,
            modalTitle: `Transaction Details: ${this.props.transaction.transactionRef}`
          });
        });
      });
    }
  }

  handleClose = (refresh) => {
    this.setState({ modalOpen: false }, () => {
      if (refresh) this.props.onTransactionClose();
    })
  }

  render() {
    if (typeof this.props.modalData === "undefined" || this.props.modalData === null) return <div></div>
    return (
        <Modal
            open={this.props.modalOpen}
            onClose={this.props.handleClose}
            size='fullscreen'
            closeIcon
        >
            <Modal.Header>
              Collection Details - {this.props.modalData.collectionId}  
              {this.props.modalData.isFinal && <Label color="red" className="ml-1" size="small">FINAL</Label> }
            </Modal.Header>
            <Modal.Content>
              <TransactionDetailsModal modalOpen={this.state.modalOpen} handleClose={this.handleClose} modalTitle={this.state.modalTitle} transaction={this.props.transaction} />
              <Grid columns='6' centered>
                <Grid.Row>
                  <Grid.Column width="16">
                    <b>{this.props.modalData.site}</b> - {this.props.modalData.gameName} - {this.props.modalData.assetNo}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="16">
                    <span className="mr-1"><b>START:</b> {this.props.modalData.startDate}</span>
                    <span className="ml-1 mr-1"><b>END:</b> {this.props.modalData.endDate}</span>
                    <span className="mr-1 ml-1"><b>PERIOD (days):</b> {this.props.modalData.period || "N/A"}</span>
                    <Checkbox className="ml-1" label='Show Events' checked={this.props.showEvents} onChange={this.props.onChange} name="includeEvents"/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
               <Grid className="height-100">
                 <Grid.Row className="flex-stretch">
                  <Grid.Column width="5" className="gs-flex-container-vertical">
                    <DataGridRequest
                      headings={this.props.modalData.summarysGrid.headerDef}
                      rowDef={this.props.modalData.summarysGrid.rowDef}
                      data={this.props.modalData.summarysGrid.data}
                      fullHeight
                      loading={this.props.loading}
                    />
                  </Grid.Column>
                  <Grid.Column width="11" className="flex-container">
                    <DataGridRequest
                      headings={this.props.modalData.eventsGrid.headerDef}
                      rowDef={this.props.modalData.eventsGrid.rowDef}
                      data={this.props.modalData.eventsGrid.data}
                      fullHeight
                      loading={this.props.loading || this.state.loading}
                      onRowClick={this.handleRowClick}
                    /> 
                    <br />
                    <DataGridRequest
                      headings={this.props.modalData.metersGrid.headerDef}
                      rowDef={this.props.modalData.metersGrid.rowDef}
                      data={this.props.modalData.metersGrid.data}
                      fullHeight
                      loading={this.props.loading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
        </Modal>
    )
  }
}

const mapStateToProps = state => {
	return {
    transaction: state.transaction,
  };
};

export default connect(mapStateToProps, {
  fetchTransaction
})(CollectionDetailsModal);

